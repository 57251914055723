import React,
{
  useState,
} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';

import {
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';

import {
  TableNextStyled,
  TableControlContainer,
  TableButtonControl,
  TableSpanControl,
  TableFooterControlContainer,
  TableTotalSpan,
} from './styled';



interface IProps {
  data: any;
  columns: any;

  noQuantity?: boolean;

  baseProps?: any;
  paginationProps: any;
  paginationTableProps: any;
}



const TableCuston: React.FC<IProps> = (props) => {
  const [selected, setSelected] = useState(props.paginationProps.sizePerPage);


  const customTotal = (from, to, size) => (
    <TableTotalSpan className="react-bootstrap-table-pagination-total">
      Mostrando as linhas {from} a {to} de {size} resultados
    </TableTotalSpan>
  );


  const handleSizePerPage = (pgPropos, newSizePerPage) => {
    pgPropos.onSizePerPageChange(newSizePerPage, pgPropos.page);
  };


  const options = {
    ...props.paginationProps,
    paginationTotalRenderer: customTotal,
  };



  return (

    <TableNextStyled>

      {!props.noQuantity && (
        <TableControlContainer>

          {props.paginationProps.sizePerPageList.map((quantity) => {
            const isSelect = quantity === selected;

            return (
              <TableButtonControl
                key={quantity}
                type={'button'}
                selected={isSelect}
                onClick={() => {
                  setSelected(quantity);
                  handleSizePerPage(props.paginationProps, quantity);
                }}>

                <TableSpanControl>
                  {quantity}
                </TableSpanControl>

              </TableButtonControl>
            );
          })}


          <TableFooterControlContainer>
            <PaginationTotalStandalone
              {...options}
            />
          </TableFooterControlContainer>


          <PaginationListStandalone
            {...props.paginationProps}
          />

        </TableControlContainer>
      )}



      <BootstrapTable
        bootstrap4
        hover
        bordered={false}
        keyField={'id'}
        data={props.data}
        columns={props.columns}
        {...props.baseProps}
        {...props.paginationTableProps}
      />



      <TableControlContainer>
        <PaginationListStandalone
          {...props.paginationProps}
        />
      </TableControlContainer>
    </TableNextStyled>

  );
};



TableCuston.defaultProps = {
  noQuantity: false,
};



export default TableCuston;
