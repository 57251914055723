import React,
{
  useState,
} from 'react';

import {
  Col,
  OverlayTrigger,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Formik,
} from 'formik';

import {
  PcbContratosArray,
} from '../../../../../common/arrays';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';

import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../common/excel';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  SeparatorButton,
  TableRotateNextCss,
  TableTdButtonNextCss,
  TableToltip,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';


import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import SubtitleComponent from '../../../../../components/Contents/Subtitles';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';

import {
  IconAttachment,
  IconFolder,
  IconHistory,
  IconMoreOptions,
  IconWriteDown,
} from '../../../../../components/Icons';

import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';


import NameRoutes from '../../../../../navigation/name';
import {
  PBC_NavRoutes,
} from '../../../../../navigation/navs/Supplies';

import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';

import {
  convertCurrencyBRL,
} from '../../../../../utils/CurrencyUtils';

import {
  formataData,
} from '../../../../../utils/DateUtils';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';

import {
  convertCNPJ,
} from '../../../../../utils/ListUtils';


import AnexosPcbModal from '../Modals/AnexosPcbModal';
import AnotacaoModal from '../Modals/AnotacaoPcbModal';
import CotacaoPcbModal from '../Modals/CotacaoPcbModal';
import ProdutoDadosComplementaresModal from '../Modals/DadosComplementaresModal';
import HistoricoFornecedorModal from '../Modals/HistoricoFornecedorModal';
import HistoricoItemModal from '../Modals/HistoricoItemModal';


import ItensPedidoModal from '../Modals/ItensPedidoModal';
import PcbContratosModel from './PBC-contratos.model';
import {
  RowContratosLegends,
  StatusContratosView,
} from './PBC-contratos.styled';
import PcbContratosValidationSchema from './PBC-contratos.validation';
import { IToast } from '../../../../../components/IToast/Toast';



const PBC_ContratosScreen: React.FC = () => {
  // ----------- AREA STATES ---------------------------

  const pasta = localStorage.getItem('@intranet/pasta');


  const [showListaCont, setShowListaCont] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [listContracts, setlistContracts] = useState([]);

  const emptyObject = {
    image: Images.empty,
    title: 'Sem contratos aqui!',
    description: 'Não há nenhum contrato para você',
  };

  const [valueSupplier, setValueSupplier] = useState(null);
  const [showModalSupplier, setShowModalSupplier] = useState(false);

  const [screenAttached, setScreenAttached] = useState(false);
  const [valueAttached, setValueAttached] = useState(null);

  const [valueHistory, setValueHistory] = useState(null);
  const [modalHistory, setModalHistory] = useState(false);

  const [valueItems, setValueItemsAE] = useState(null);
  const [itemsModalShow, setItemsModalShow] = useState(false);

  const [valueQuotation, setValueQuotation] = useState(null);
  const [showQuotationModal, setShowQuotationModal] = useState(false);

  const [valueComplementaryData, setValueDadosComplemetares] = useState(null);
  const [complementaryDataModalShow, setComplementaryDataModalShow] = useState(false);

  const [writeDownModalShow, setWriteDownModalShow] = useState(false);
  const [writeDownValues, setWriteDownValues] = useState([]);
  const [typeWriteDown, setTypeWriteDown] = useState(null);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);

  // ----------- AREA FUNCTION'S FETH DATA ---------------------------

  async function fetchContracts(values: any, page_iteration: any) {
    setShowListaCont(false);
    setListLoading(true);
    setlistContracts([]);
    setPage(1);

    const objToBack = {
      'SITUAC': values.input_situacao,
      'FILIAL': values.input_filial,
      'CC': values.input_centro_custo.toUpperCase(),
      'FORNECEDOR': values.input_fornecedor,
      'PLANILHA': values.input_planilha,
      'REVISAO': values.input_revisao,
      'CNPJ': values.input_cnpj,
      'NUM': values.input_numero,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_PBCContracts,
    };

    try {
      const url = '/suprimento/consulta/contrato';
      const response = await ApiWS().post(url, objToBack);

      setlistContracts(response.data[0]);
      setTotalResults(response.data[1][0].TOTAL);
      setTotalPage(Math.ceil(response.data[1][0].TOTAL / 10));
    }
    catch (error) {
      console.error('fetchAll', JSON.stringify(error.response, null, 2));

      IToast({
        type: 'error',
        message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
      });
    }
    finally {
      setListLoading(false);
      setShowListaCont(true);
    }
  };


  function postExport(values: any) {
    setListLoading(true);

    const url = 'suprimentos/contratos/exporta';


    const payload = {
      'SITUAC': values.input_situacao,
      'FILIAL': values.input_filial,
      'CC': values.input_centro_custo.toUpperCase(),
      'FORNECEDOR': values.input_fornecedor,
      'PLANILHA': values.input_planilha,
      'REVISAO': values.input_revisao,
      'CNPJ': values.input_cnpj,
      'NUM': values.input_numero,
    };


    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));


        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setListLoading(false);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    fetchContracts(valuesPayload, page_var);
    setPage(page_var);
  };



  function postPrinter(values: any) {
    setListLoading(true);

    const url = `suprimentos/contrato/impressao/${values.FILIAL}/${values.CONTRATO}/${values.REVISAO}/${values.PLANILHA}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setListLoading(false);
      });
  };

  // ----------- AREA FUNCTION'S RENDER ICONS TABLE ---------------

  function renderIconStatus(cell: any) {
    const valuePcbSit = PcbContratosArray.map((pcb) => {
      if (cell && pcb.key === cell) {
        return (
          <SubtitleComponent
            noSubtitle
            backgroundColor={pcb.color}
          />);
      }
      else {
        return null;
      }
    });

    return (
      <StatusContratosView>
        {valuePcbSit}
      </StatusContratosView>
    );
  };

  // ----------- AREA FUNCTION'S RENDER BUTTONS TABLE ---------------

  function renderBtnModalSupplier(cell: any, row: any) {
    return (
      <TableTdButtonNextCss
        onClick={() => {
          setValueSupplier({
            FILIAL: row.FILIAL,
            FORNEC: row.CODFORNEC,
            LOJA: row.LOJA,
            CC: row.OS.substring(0, 7),
          });
          setShowModalSupplier(true);
        }}>
        {cell}
      </TableTdButtonNextCss>
    );
  };


  function renderBtnPrintContract(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.ITEMS}
        onClick={() => {
          postPrinter(row);
        }}
        icon={
          <IconFolder
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalItems(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.ITEMS}
        onClick={() => {
          setValueItemsAE({
            'FILIAL': row.FILIAL,
            'PEDIDO': row.NUM,
            'TIPO': row.TIPO,
          });
          setItemsModalShow(true);
        }}
        icon={
          <IconFolder
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function returnSwitchItems(cell: any, row: any): JSX.Element {
    return (
      {
        'CT': renderBtnPrintContract(cell, row),
        'CP': renderBtnModalItems(cell, row),
      }[row.TIPO]
    );
  };


  function renderBtnModalHistory(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.HISTORIC}
        onClick={() => {
          setValueHistory({
            'FILIAL': row.FILIAL,
            'CC': row.OS.substring(0, 7),
            'NUM': row.NUM,
            'TIPO': row.TIPO,
          });
          setModalHistory(true);
        }}
        icon={
          <IconHistory
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalComplementary(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.COMPLEMENTARY_DATA}
        onClick={() => {
          setValueDadosComplemetares({
            'CONDPAG': row.CONDPAG,
            'FILIALENT': row.FILIALENT,
            'ENDENTR': row.ENDENTR,
            'DTENTR': row.DTENTR,
            'FRETE': row.FRETE,
            'CLORC': row.CLORC,
            'OP': row.OP,
            'OBS': row.OBS,
          });
          setComplementaryDataModalShow(true);
        }}
        icon={
          <IconMoreOptions
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalAttachedPcb(_cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={
          row.ANEXO === 'S'
            ? TextTooltip.ATTACHMENT
            : TextTooltip.LESS_ATTACHMENT
        }
        onClick={() => {
          if (row.ANEXO === 'S') {
            setValueAttached({
              'TIPO': row.TIPO,
              'NUM': row.NUM,
              'FILIAL': row.FILIAL,
            });
            setScreenAttached(true);
          }
        }}
        icon={
          <IconAttachment
            color={
              row.ANEXO === 'S'
                ? Colors?.black
                : Colors?.gray
            }
            size={16}
          />
        }
      />
    );
  };


  function renderBtnModalWrite(cell: any, row: any) {
    return (
      <ButtonTableAction
        tooltip={TextTooltip.WRITE_DOWN}
        onClick={() => {
          setWriteDownValues([{
            'FILIAL': row.FILIAL,
            'NUM': row.NUM,
            'TIPO': row.TIPO,
          }]);
          setTypeWriteDown('read');
          setWriteDownModalShow(true);
        }}
        icon={
          <IconWriteDown
            color={Colors?.black}
            size={16}
          />
        }
      />
    );
  };

  // ----------- AREA FUNCTION'S RENDER MODAL ----------------------------

  function renderModalHistorySupplier() {
    return (
      <HistoricoFornecedorModal
        show={showModalSupplier}
        supplier={valueSupplier}
        onHide={() => {
          setShowModalSupplier(false);
          setTimeout(() => {
            setValueSupplier(null);
          }, 300);
        }}
      />
    );
  };


  function renderModalRequestItems() {
    return (
      <ItensPedidoModal
        show={itemsModalShow}
        values={valueItems}
        onHide={() => {
          setItemsModalShow(false);
          setTimeout(() => {
            setValueItemsAE(null);
          }, 300);
        }}
        cotacaoClick={(item) => {
          setValueQuotation(item);
          setShowQuotationModal(true);
        }}
      />
    );
  }


  function renderModalQuotation() {
    return (
      <CotacaoPcbModal
        show={showQuotationModal}
        values={valueQuotation}
        onHide={() => {
          setShowQuotationModal(false);
          setTimeout(() => {
            setValueQuotation(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalHistoryItems() {
    return (
      <HistoricoItemModal
        show={modalHistory}
        values={valueHistory}
        onHide={() => {
          setModalHistory(false);
          setTimeout(() => {
            setValueHistory(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalComplementaryData() {
    return (
      <ProdutoDadosComplementaresModal
        show={complementaryDataModalShow}
        dadosComp={valueComplementaryData}
        isContrato
        onHide={() => {
          setComplementaryDataModalShow(false);
          setTimeout(() => {
            setValueDadosComplemetares(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalAttached() {
    return (
      <AnexosPcbModal
        show={screenAttached}
        values={valueAttached}
        onHide={() => {
          setScreenAttached(false);
          setTimeout(() => {
            setValueAttached(null);
          }, 300);
        }}
      />
    );
  }


  function renderModalWriteDownShow() {
    return (
      <AnotacaoModal
        show={writeDownModalShow}
        type={typeWriteDown}
        pedidos={writeDownValues}
        onHide={() => {
          setWriteDownModalShow(false);
          setTimeout(() => {
            setWriteDownValues(null);
          }, 300);
        }}
      />
    );
  };


  // ----------- AREA FUNCTION'S RENDER GRID ----------------------------

  function renderLoadingComponent() {
    return (
      <LoadingsComponent
        type={'SCREEN'}
        color={Colors?.tertiary}
      />
    );
  };


  function renderEmptyGrid() {
    return (
      <EmptyContent
        image={emptyObject.image}
        title={emptyObject.title}
        description={emptyObject.description}
      />
    );
  };


  function renderGridContent(propsTkProvider: any) {
    return (
      <PaginationProvider
        pagination={paginationFactory(options)}>
        {({
          paginationProps,
          paginationTableProps,
        }) => (
          <TableCuston
            noQuantity={totalPage === 1}
            data={listContracts}
            columns={columns}
            baseProps={propsTkProvider.baseProps}
            paginationProps={paginationProps}
            paginationTableProps={paginationTableProps}
            actionPrevious={() => handlePagination(0)}
            actionNext={() => handlePagination(1)}
            currentPage={page}
            totalPage={totalPage}
            totalResults={totalResults}
          />
        )}
      </PaginationProvider>
    );
  };



  const columns = [
    {
      dataField: 'FILIAL',
      text: 'Filial',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
    },
    {
      dataField: 'NUM',
      text: 'N Doc',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'DTEMISSAO',
      text: 'Data',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
      formatter: (cell) => {
        return formataData(cell);
      },
    },
    {
      dataField: 'COMPRADOR',
      text: 'Comprador/Analista',
      sort: true,
      headerStyle: () => {
        return { width: '5.5%' };
      },
    },
    {
      dataField: 'CNPJ',
      text: 'CNPJ',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCNPJ(cell);
      },
      csvFormatter: (cell): string => {
        return convertCNPJ(cell);
      },
    },
    {
      dataField: 'FORNECEDOR',
      text: 'Fornecedor',
      sort: true,
      formatter: (cell, row): JSX.Element => {
        return renderBtnModalSupplier(cell, row);
      },
      csvFormatter: (cell) => {
        return cell;
      },
    },
    {
      dataField: 'VALOR',
      text: 'Valor',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCurrencyBRL(cell);
      },
    },
    {
      dataField: 'VLATEND',
      text: 'Valor Atend.',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCurrencyBRL(cell);
      },
    },
    {
      dataField: 'SALDO',
      text: 'Saldo',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCurrencyBRL(cell);
      },
    },
    // {
    //   dataField: 'OS',
    //   text: 'CC',
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: '4.5%' };
    //   },
    //   formatter: (cell) => {
    //     return cell.replaceAll(' ', '');
    //   },
    // },
    {
      dataField: 'OS',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '4.5%' };
      },
      formatter: (cell, row) => {
        return (
          <OverlayTrigger
            key={'right'}
            placement={'right'}
            trigger={['hover', 'hover']}
            overlay={
              <TableToltip>
                {row.CCDESCR}
              </TableToltip>
            }>
            <span>{row.OS}</span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'SIT',
      text: 'Situação',
      sort: true,
      headerStyle: () => {
        return { width: '4%' };
      },
      formatter: (cell): JSX.Element => {
        return renderIconStatus(cell);
      },
      csvText: 'Situacao',
      csvFormatter: (cell): string => {
        const valuePcbStringSit = PcbContratosArray.find((pcb) => {
          return pcb.key.toString() === cell?.toString();
        });

        return valuePcbStringSit ? valuePcbStringSit.label.toString() : 'Indefinida';
      },
    },
    {
      dataField: '',
      text: 'Mapa',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return returnSwitchItems(cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Hist.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (_cell, row): JSX.Element => {
        return renderBtnModalHistory(_cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Comp.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (_cell, row): JSX.Element => {
        return renderBtnModalComplementary(_cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Anex.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (_cell, row): JSX.Element => {
        return renderBtnModalAttachedPcb(_cell, row);
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Anot.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row) => {
        return renderBtnModalWrite(cell, row);
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listContracts.length,
  };



  return (


    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={PBC_NavRoutes}
          title={'Suprimentos'}
        />
      </NavHeader>


      <Content>
        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>
              <TitleGroup>
                {'Contratos'.toUpperCase()}
              </TitleGroup>


              {listLoading &&
                renderLoadingComponent()}

              <SectionMargin>
                <Formik
                  enableReinitialize
                  validateOnMount
                  validationSchema={PcbContratosValidationSchema}
                  initialValues={PcbContratosModel}
                  onSubmit={(values) => {
                    fetchContracts(values, 1);
                    setValuesPayload(values);
                  }}>
                  {({
                    values,
                    resetForm,
                    handleSubmit,
                    handleChange,
                    isValid,
                    handleBlur,
                    setValues,
                  }) => (

                    <FilterContainerForm auto onSubmit={handleSubmit}>

                      <Row>
                        <Col>
                          <DropdownInput
                            disabled={listLoading}
                            name={'input_situacao'}
                            label={'Situação'}
                            defaultValue={'Todos'.toLocaleUpperCase()}
                            dropArray={[
                              {
                                key: 'All',
                                label: 'Todos',
                                value: 'Todos'.toLocaleUpperCase(),
                              },
                              ...PcbContratosArray,
                            ]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.input_situacao || ''}
                          />


                          <TextInput
                            disabled={listLoading}
                            type={'text'}
                            name={'input_fornecedor'}
                            label={'Fornecedor'}
                            placeholder={'Fornecedor'}
                            min={0}
                            onChange={handleChange}
                            value={values.input_fornecedor.toUpperCase() || ''}
                            onBlur={handleBlur}
                          />


                          <TextInput
                            disabled={listLoading}
                            type={'text'}
                            name={'input_planilha'}
                            label={'Planilha'}
                            placeholder={'Planilha'}
                            min={0}
                            value={values.input_planilha || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>



                        <Col>
                          <TextInput
                            disabled={listLoading}
                            type={'text'}
                            name={'input_filial'}
                            label={'Filial'}
                            placeholder={'Filial'}
                            onChange={handleChange}
                            value={values.input_filial || ''}
                            onBlur={handleBlur}
                          />


                          <TextInput
                            disabled={listLoading}
                            type={'text'}
                            name={'input_cnpj'}
                            label={'CNPJ'}
                            placeholder={'CNPJ'}
                            onChange={handleChange}
                            value={values.input_cnpj || ''}
                            onBlur={handleBlur}
                          />


                          <TextInput
                            disabled={listLoading}
                            type={'text'}
                            name={'input_revisao'}
                            label={'Revisão'}
                            placeholder={'Revisão'}
                            onChange={handleChange}
                            value={values.input_revisao || ''}
                            onBlur={handleBlur}
                          />
                        </Col>



                        <Col>
                          <TextInput
                            disabled={listLoading}
                            type={'text'}
                            name={'input_centro_custo'}
                            label={'Centro de Custo'}
                            placeholder={'Número'}
                            onChange={handleChange}
                            value={values.input_centro_custo.toUpperCase() || ''}
                            onBlur={handleBlur}
                          />


                          <TextInput
                            disabled={listLoading}
                            type={'text'}
                            name={'input_numero'}
                            label={'Número'}
                            placeholder={'Número'}
                            onChange={handleChange}
                            value={values.input_numero || ''}
                            onBlur={handleBlur}
                          />
                        </Col>



                        <RowContratosLegends>
                          <SubtitleComponent
                            array={PcbContratosArray}
                          />
                        </RowContratosLegends>
                      </Row>



                      <RowButtonFiltersView>
                        {listContracts.length > 0 && (
                          <IButton
                            disabled={!isValid || listLoading}
                            backgroundColor={Colors?.excel}
                            type={'button'}
                            text={'Exportar'}
                            onClick={() => {
                              postExport(values);
                            }}
                          />
                        )}


                        {listContracts.length > 0 && <SeparatorButton />}


                        <IButton
                          disabled={listLoading}
                          text={'Limpar'}
                          backgroundColor={Colors?.gray}
                          type={'reset'}
                          onClick={() => {
                            resetForm();

                            setValues(PcbContratosModel);
                          }}
                        />


                        <IButton
                          disabled={listLoading}
                          text={'Filtrar'}
                          backgroundColor={Colors?.primary}
                          type={'submit'}
                        />
                      </RowButtonFiltersView>

                    </FilterContainerForm>

                  )}
                </Formik>
              </SectionMargin>



              <ContainerView>

                {!listLoading && showListaCont && listContracts.length === 0 &&
                  renderEmptyGrid()}



                {!listLoading && showListaCont && listContracts.length > 0 &&
                  renderGridContent(null)}
                {/* renderGridContent(propsTkProvider)} */}

              </ContainerView>


            </SectionMargin>

          </ContainerView>

        </ContentStyled>
      </Content>



      {valueSupplier &&
        renderModalHistorySupplier()}



      {valueItems &&
        renderModalRequestItems()}



      {valueQuotation &&
        renderModalQuotation()}


      {valueHistory &&
        renderModalHistoryItems()}



      {valueComplementaryData &&
        renderModalComplementaryData()}



      {valueAttached &&
        renderModalAttached()}



      {writeDownModalShow && writeDownValues && (
        renderModalWriteDownShow())}

    </Screen>

  );
};



export default PBC_ContratosScreen;
