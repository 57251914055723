import React,
{
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

// import {
//   useHistory,
// } from 'react-router-dom';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  SeparatorButton,
  // SeparatorButton,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import PStockCaValidationSchema from '../../../../../../common/validations/Supplies/PSTOCK/pstock-ca.validation';

import IButton from '../../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../../components/Empty';

import {
  IconRefresh,
} from '../../../../../../components/Icons';

import LoadingsComponent from '../../../../../../components/Loadings';
import ConfirmationModal from '../../../../../../components/Modals/Confirmation';
import TableCuston from '../../../../../../components/TableOld';
import TextInput from '../../../../../../components/TextFields/Input';

import PStockCaModel from '../../../../../../models/Supplies/PSTOCK/pstock-ca.model';

// import NameRoutes from '../../../../../../navigation/name';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';



const Tab_CAScreen: React.FC = () => {
  // const history = useHistory();


  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem CA!',
    description: 'Não há CA aqui',
  });

  const [showCaList, setShowCaList] = useState(false);
  const [listCa, setListCa] = useState([]);

  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [loadingCaList, setLoadingCaList] = useState(false);
  const [messageUpdateConfirmModal, setMessageUpdateConfirmModal] = useState(null);

  const [formValues, setFormValues] = useState(null);



  async function handleConsulta(values: typeof PStockCaModel) {
    const payload = {
      'COD': values.input_cod,
      'PRODUTO': values.input_produto,
      'COD_FORNEC': values.input_cod_fornec,
      'FORNECEDOR': values.input_fornecedor,
      'CA': values.input_ca,
      'LOTE': values.input_lote,
    };

    try {
      setLoadingCaList(true);
      setShowCaList(false);

      const url = '/pstock/adm/ca/consulta';
      const response = await ApiWS().post(url, payload);

      setListCa(response.data);
    }
    catch (error) {
      console.error('handleConsulta', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setLoadingCaList(false);
      setShowCaList(true);
    }
  };


  async function postSincronizar() {
    const payload = {
      'CC': 'MKS0116',
    };

    try {
      setLoadingCaList(true);

      const url = '/pstock/adm/ca/sincronizar';
      const response = await ApiWS().post(url, payload);

      if (response.status === 200) {
        setShowUpdateConfirmModal(true);
        setMessageUpdateConfirmModal('Atualização de CA concluída');
      }
      else {
        toast.error('Erro ao atualizar CA! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
    catch (error) {
      console.error('postSincronizar', JSON.stringify(error.response, null, 2));
      setShowUpdateConfirmModal(true);
      setMessageUpdateConfirmModal('Houve um erro ao atualizar CA, por favor entre em contato com o suporte.');
    }
    finally {
      setLoadingCaList(false);
      if (formValues) {
        handleConsulta(formValues);
      }
    }
  }



  const columns = [
    {
      dataField: 'COD',
      text: 'Código',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'PRODUTO',
      text: 'Produto',
      sort: true,
      headerStyle: () => {
        return { width: '24%' };
      },
    },
    {
      dataField: 'COD_FORNEC',
      text: 'Cód. Fornecedor',
      sort: true,
    },
    {
      dataField: 'FORNECEDOR',
      text: 'Fornecedor',
      sort: true,
    },
    {
      dataField: 'CA',
      text: 'CA',
      sort: true,
    },
    {
      dataField: 'LOTE',
      text: 'Lote',
      sort: true,
    },
    {
      dataField: 'DTVENC',
      text: 'Data de Venc.',
      sort: true,
    },
  ];


  const options = {
    custom: true,
    totalSize: listCa.length,
  };



  return (

    <ContainerView>

      <SectionMargin>
        <TitleGroup>
          {'Consultar CA'.toUpperCase()}
        </TitleGroup>


        <SectionMargin>
          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PStockCaValidationSchema}
            initialValues={PStockCaModel}
            onSubmit={(values) => {
              setFormValues(values);
              handleConsulta(values);
            }}>
            {({
              values,
              resetForm,
              handleSubmit,
              handleChange,
              handleBlur,
              setValues,
            }) => (

              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>
                  <Col>
                    <TextInput
                      disabled={loadingCaList}
                      type={'text'}
                      name={'input_cod'}
                      label={'Cód.'}
                      placeholder={'Cód.'}
                      value={values.input_cod || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingCaList}
                      type={'text'}
                      name={'input_produto'}
                      label={'Produto'}
                      placeholder={'Produto'}
                      value={values.input_produto || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingCaList}
                      type={'text'}
                      name={'input_cod_fornec'}
                      label={'Cód. Fornecedor'}
                      placeholder={'Cód. Fornecedor'}
                      value={values.input_cod_fornec || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingCaList}
                      type={'text'}
                      name={'input_fornecedor'}
                      label={'Fornecedor'}
                      placeholder={'Fornecedor'}
                      value={values.input_fornecedor || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingCaList}
                      type={'text'}
                      name={'input_ca'}
                      label={'CA'}
                      placeholder={'CA'}
                      value={values.input_ca || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingCaList}
                      type={'text'}
                      name={'input_lote'}
                      label={'Lote'}
                      placeholder={'Lote'}
                      value={values.input_lote || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>



                <RowButtonFiltersView>
                  <IButton
                    disabled={loadingCaList}
                    backgroundColor={Colors?.secondary}
                    text={'Sincronizar'}
                    iconLeft={
                      <IconRefresh
                        color={'#FFFFFF'}
                        size={20}
                      />
                    }
                    onClick={() => {
                      postSincronizar();
                    }}
                  />
                  <SeparatorButton />


                  {/* <IButton
                    disabled={loadingEmployeeList}
                    backgroundColor={Colors?.excel}
                    text={'Adicionar'}
                    iconLeft={
                      <IconAdd
                        color={'#FFFFFF'}
                        size={24}
                      />
                    }
                    onClick={() => {
                      history.push(`/${NameRoutes.NovoFuncionarioScreen}`);
                    }}
                  />
                  <SeparatorButton /> */}


                  <IButton
                    disabled={loadingCaList}
                    text={'Limpar'}
                    backgroundColor={Colors?.gray}
                    type={'reset'}
                    onClick={() => {
                      resetForm();

                      setValues(PStockCaModel);
                    }}
                  />


                  <IButton
                    disabled={loadingCaList}
                    text={'Consultar'}
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                  />
                </RowButtonFiltersView>

              </FilterContainerForm>

            )}
          </Formik>
        </SectionMargin>



        <ContainerView>

          {loadingCaList && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loadingCaList && showCaList && listCa.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}



          {!loadingCaList && showCaList && listCa.length > 0 && (
            <PaginationProvider
              pagination={paginationFactory(options)}>
              {({
                paginationProps,
                paginationTableProps,
              }) => (
                <TableCuston
                  noQuantity={listCa.length < 10}
                  data={listCa}
                  columns={columns}
                  paginationProps={paginationProps}
                  paginationTableProps={paginationTableProps}
                />
              )}
            </PaginationProvider>
          )}

        </ContainerView>


      </SectionMargin>



      {messageUpdateConfirmModal && (
        <ConfirmationModal
          show={showUpdateConfirmModal}
          head={'PStock - CA!'}
          orient={messageUpdateConfirmModal}
          negative={() => {
            setShowUpdateConfirmModal(false);
            setTimeout(() => {
              setMessageUpdateConfirmModal(null);
            }, 300);
          }}
        />
      )}

    </ContainerView>

  );
};



export default Tab_CAScreen;
