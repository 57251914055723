import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  toast,
} from 'react-toastify';

import {
  format,
} from 'date-fns';

import {
  Formik,
} from 'formik';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../common/constants';

import ExcelConfigs from '../../../../common/excel';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  SeparatorButton,
  SubTitleGroup,
  TitleGroup,
} from '../../../../common/styles/styled.layout';


import IButton from '../../../../components/Buttons/IButton';
import EmptyContent from '../../../../components/Empty';
import NavComp from '../../../../components/Headers/NavComp';
import NavHeader from '../../../../components/Headers/NavHeader';
import NavUser from '../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../components/Loadings';
import ConfirmationModal from '../../../../components/Modals/Confirmation';
import TableCuston from '../../../../components/Table';
import TextInput from '../../../../components/TextFields/Input';

import SelectMulti from '../../../../components/TextFields/SelectMulti';

import ApiWS, { defaultBaseURL } from '../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../utils/ErrorUtils';
import IGercCustoGerencialModel from './GERC.model';
import IGercCustoGerencialValidationSchema from './GERC.validation';
import { IToast } from '../../../../components/IToast/Toast';



const GERC_IndexScreen: React.FC = () => {
  const pasta = localStorage.getItem('@intranet/pasta');

  const [carregando, setCarregando] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum item aqui!',
    description: 'Não há nada aqui',
  });

  const errorObject = returnErrorObject(500);

  const unauthorizedObject = {
    image: Images.error401,
    title: 'Sem autorização de acesso!',
    description: 'Não há nada aqui',
  };

  const [inputUnNeg, setInputUnNeg] = useState([]);
  const [listUnNeg, setListUnNeg] = useState([]);

  const [inputOs, setInputOs] = useState([]);
  const [listOs, setListOs] = useState([]);
  const [osLoading, setOsLoading] = useState(false);

  const [listaCustoGerenc, setListaCustoGerenc] = useState([]);
  const [showListGERC, setShowListGERC] = useState(false);
  const [listaCarregando, setListaCarregando] = useState(false);

  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [loadingUpdateConfirmModal, setCarregandoUpdateConfirmModal] = useState(false);
  const [messageUpdateConfirmModal, setMessageUpdateConfirmModal] = useState(null);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);

  const [autorizadoAtualizarCG, setAutorizadoAtualizarCG] = useState(false);
  const [autorizadoUsoSistema, setAutorizadoUsoSistema] = useState(false);
  const [erro, setErro] = useState(false);



  async function getPermissionCG() {
    try {
      const url = '/controladoria/cg/atualiza/permissao/';
      const response = await ApiWS().get(url);

      const permissionStatus = response.data.trim().toUpperCase() === 'Negado'.trim().toUpperCase()
        ? false
        : true;

      return permissionStatus;
    }
    catch (error) {
      console.log('STATUS::::::::::: ', error.response.status);
      console.error('getPermissionCG', JSON.stringify(error.response, null, 2));

      if (error.response.status !== 403) {
        setErro(true);
      }
    }
  };

  async function handleUpdateCG() {
    try {
      setCarregandoUpdateConfirmModal(true);

      const url = '/controladoria/cg/atualiza';
      const response = await ApiWS().get(url);

      if (response.status === 200) {
        setShowUpdateConfirmModal(true);
        setMessageUpdateConfirmModal('Atualização CG concluída');
      }
      else {
        toast.error('Erro ao atualizar CG! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
    catch (error) {
      console.error('handleUpdateCG', JSON.stringify(error.response, null, 2));
      setShowUpdateConfirmModal(true);
      setMessageUpdateConfirmModal('Houve um erro ao atualizar CG, por favor entre em contato com o suporte.');

      if (error.response.status !== 403) {
        setErro(true);
      }
    }
    finally {
      setCarregandoUpdateConfirmModal(false);
    }
  };

  async function handleCustoGerecialFunction(values: typeof IGercCustoGerencialModel, page_iteration: any) {
    setValuesPayload(values);
    setListaCustoGerenc([]);
    setPage(1);
    setListaCarregando(true);
    setShowListGERC(false);

    const payload = {
      'OS': inputOs,
      'DTINI': values.input_data_inicial.replaceAll('-', ''),
      'DTFIM': values.input_data_final.replaceAll('-', ''),
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_custogerencial,
    };

    try {
      if (inputOs.length > 0) {
        const url = 'controladoria/cg/consulta';
        const response = await ApiWS().post(url, payload);

        setListaCustoGerenc(response.data[0]);
        setTotalResults(response.data[1][0].TOTAL);
        setTotalPage(Math.ceil(response.data[1][0].TOTAL / 10));

        setShowListGERC(true);
      }
      else {
        toast.error('Ocorreu um erro fazer a pesquisa. Tente novamente!', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
    catch (error) {
      console.error('CustoGerecialFunction', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      setErro(true);
    }
    finally {
      setListaCarregando(false);
      setShowListGERC(true);
    }
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    handleCustoGerecialFunction(valuesPayload, page_var);
    setPage(page_var);
  }

  function renderListOs(listOsResp: any) {
    const listOsMap = listOsResp.map((unit) => {
      const valueOs = unit.OS !== '' || unit.OS !== null || unit.OS !== undefined ? unit.OS : null;

      const OsMapped = {
        'key': unit.OS,
        'label': unit.OS,
        'value': `${valueOs}`,
      };
      return OsMapped;
    });

    setListOs([
      ...listOsMap,
    ]);
  };

  async function findOs(request: any) {
    const payload = {
      'NEGOC': request,
    };

    try {
      setOsLoading(true);

      const url = '/controladoria/cg/os';
      const response = await ApiWS().post(url, payload);

      renderListOs(response.data);
    }
    catch (error) {
      console.error('findOs', JSON.stringify(error.response, null, 2));
      if (error.response.status !== 403) {
        setErro(true);
      }
    }
    finally {
      setOsLoading(false);
    }
  };

  function renderListUnNeg(listOsResp: any) {
    const listOsMap = listOsResp.map((unit) => {
      const valueOs = unit.VALOR !== '' || unit.VALOR !== null || unit.VALOR !== undefined ? unit.VALOR : null;

      const OsMapped = {
        'key': unit.VALOR,
        'label': unit.NEGOC,
        'value': valueOs,
      };
      return OsMapped;
    });

    setListUnNeg([
      ...listOsMap,
    ]);
  };

  async function findUnNeg() {
    try {
      const url = '/controladoria/cg/negoc';
      const response = await ApiWS().get(url);

      return response.data;
    }
    catch (error) {
      console.error('findUnNeg', JSON.stringify(error.response, null, 2));
    }
    return [];
  };

  async function functionUnNegAndPermissionCG() {
    try {
      setCarregando(true);

      const [permissionReturn, unNegReturn] = await Promise.all([getPermissionCG(), findUnNeg()]);
      let permissaoParaUso;


      if (unNegReturn.length === 0) {
        permissaoParaUso = false;
      }
      else {
        permissaoParaUso = true;
      }


      renderListUnNeg(unNegReturn);

      /* Tem que ser dois tipos de autorização
        Tanto para tela quanto para o botão de atualizar CG
        agora falta colocar isso no JSX
      */

      setAutorizadoAtualizarCG(permissionReturn);
      setAutorizadoUsoSistema(permissaoParaUso);
    }
    catch (error) {
      console.error('findUnNeg', JSON.stringify(error.response, null, 2));
      // setErro(true);
    }
    finally {
      setCarregando(false);
    }
  };

  function postExport(values: any) {
    setCarregando(true);

    const url = 'controladoria/cg/exporta';

    const payload = {
      'OS': inputOs,
      'DTINI': values.input_data_inicial.replaceAll('-', ''),
      'DTFIM': values.input_data_final.replaceAll('-', ''),
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
        setShowListGERC(true);
      });
  };

  useEffect(() => {
    functionUnNegAndPermissionCG();
  }, []);


  useEffect(() => {
    if (inputUnNeg.length > 0) {
      findOs(inputUnNeg);
    }
  }, [inputUnNeg]);



  const columns = [
    {
      dataField: 'CLASSE',
      text: 'Classe',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CONTA',
      text: 'Conta',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'CONTA_ORC',
      text: 'Conta Orc',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'DATA$',
      text: 'Data',
      sort: true,
      formatter: (cell) => {
        return format(new Date(cell), 'dd/MM/yyyy');
      },
      headerStyle: () => {
        return { width: '6%' };
      },
    },
    {
      dataField: 'FORNECEDOR',
      text: 'Fornecedor',
      sort: true,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
    {
      dataField: 'HISTORICO',
      text: 'Histórico',
      sort: true,
    },
    {
      dataField: 'MES',
      text: 'Mes',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'OS',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'SALDO',
      text: 'Saldo',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listaCustoGerenc.length,
  };



  return (


    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          title={'Custo Gerencial'}
        />
      </NavHeader>


      <Content>
        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>
              <TitleGroup>
                {'Custo Gerencial'.toUpperCase()}
              </TitleGroup>


              {carregando && (
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              )}


              {!carregando && autorizadoUsoSistema && !erro && (
                <SectionMargin>
                  <Formik
                    enableReinitialize
                    validateOnMount
                    validationSchema={IGercCustoGerencialValidationSchema}
                    initialValues={IGercCustoGerencialModel}
                    onSubmit={(values) => {
                      handleCustoGerecialFunction(values, 1);
                    }}>
                    {({
                      values,
                      resetForm,
                      isValid,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      setValues,
                    }) => (

                      <FilterContainerForm auto onSubmit={handleSubmit}>

                        <Row>
                          <Col xs={7} md={5}>
                            <SubTitleGroup noMarginTop>
                              {'Período'.toUpperCase()}
                            </SubTitleGroup>


                            <Row>
                              <Col>
                                <TextInput
                                  disabled={loadingUpdateConfirmModal}
                                  type={'date'}
                                  name={'input_data_inicial'}
                                  label={'Data Inicial'}
                                  placeholder={'Data'}
                                  value={values.input_data_inicial || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>


                              <Col>
                                <TextInput
                                  disabled={loadingUpdateConfirmModal}
                                  type={'date'}
                                  name={'input_data_final'}
                                  label={'Data Final'}
                                  placeholder={'Data'}
                                  value={values.input_data_final || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                            </Row>
                          </Col>



                          <Col>
                            <SubTitleGroup noMarginTop>
                              {'Parâmetros'.toUpperCase()}
                            </SubTitleGroup>


                            <Row>
                              <Col>
                                <SelectMulti
                                  disabled={loadingUpdateConfirmModal}
                                  name={'input_un_neg'}
                                  label={'Un. de Negócio'}
                                  dropArray={listUnNeg}
                                  placeholder={'Selecione um ou mais'}
                                  value={inputUnNeg || []}
                                  onNextChange={(optionValue) => {
                                    setInputUnNeg(optionValue);

                                    if (optionValue.length <= 0) {
                                      setInputOs([]);
                                      setFieldValue('input_os', []);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              </Col>


                              <Col>
                                <SelectMulti
                                  disabled={((values.input_un_neg.length <= 0 || listOs.length <= 0) && !osLoading) || loadingUpdateConfirmModal}
                                  name={'input_os'}
                                  label={'CC'}
                                  dropArray={listOs}
                                  placeholder={'Selecione um ou mais'}
                                  value={inputOs || []}
                                  onNextChange={(optionValue) => {
                                    setInputOs(optionValue);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>



                        <RowButtonFiltersView>

                          {autorizadoAtualizarCG && (
                            <IButton
                              loading={loadingUpdateConfirmModal}
                              disabled={carregando || listaCarregando}
                              backgroundColor={Colors?.tertiaryDark}
                              text={'Atualizar CG'}
                              onClick={() => {
                                handleUpdateCG();
                              }}
                            />
                          )}
                          {autorizadoAtualizarCG && <SeparatorButton />}


                          {listaCustoGerenc.length > 0 && (
                            <IButton
                              disabled={!isValid || carregando || listaCarregando || loadingUpdateConfirmModal}
                              backgroundColor={Colors?.excel}
                              text={'Exportar'}
                              onClick={() => {
                                postExport(values);
                              }}
                            />
                          )}
                          {listaCustoGerenc.length > 0 && <SeparatorButton />}


                          <IButton
                            text={'Limpar'}
                            backgroundColor={Colors?.gray}
                            type={'reset'}
                            onClick={() => {
                              resetForm();
                              setFieldValue('input_un_neg', []);
                              setFieldValue('input_os', []);

                              setValues(IGercCustoGerencialModel);
                            }}
                          />


                          <IButton
                            disabled={
                              !isValid || carregando || listaCarregando || loadingUpdateConfirmModal
                              || (inputUnNeg.length <= 0 && inputOs.length <= 0)
                            }
                            text={'Filtrar'}
                            backgroundColor={Colors?.primary}
                            type={'submit'}
                          />
                        </RowButtonFiltersView>

                      </FilterContainerForm>

                    )}
                  </Formik>
                </SectionMargin>
              )}



              <ContainerView>

                {/* Carregando */}
                {listaCarregando && carregando && (
                  <LoadingsComponent
                    type={'SCREEN'}
                    color={Colors?.tertiary}
                  />
                )}


                {/* Erro */}
                {!carregando && !listaCarregando && erro && (
                  <EmptyContent
                    image={errorObject.image}
                    title={errorObject.title}
                    description={errorObject.description}
                  />
                )}


                {/* Nao Autorizado */}
                {!carregando && !listaCarregando && !autorizadoUsoSistema && !erro && (
                  <EmptyContent
                    image={unauthorizedObject.image}
                    title={unauthorizedObject.title}
                    description={unauthorizedObject.description}
                  />
                )}


                {/* Vazio */}
                {!carregando && !listaCarregando && autorizadoUsoSistema && showListGERC && listaCustoGerenc.length === 0 && !erro && (
                  <EmptyContent
                    image={emptyObject.image}
                    title={emptyObject.title}
                    description={emptyObject.description}
                  />
                )}


                {/* Grid */}
                {!carregando && !listaCarregando && showListGERC && listaCustoGerenc.length > 0 && !erro && autorizadoUsoSistema && (
                  <PaginationProvider
                    pagination={paginationFactory(options)}>
                    {({
                      paginationProps,
                      paginationTableProps,
                    }) => (
                      <TableCuston
                        noQuantity={totalPage === 1}
                        data={listaCustoGerenc}
                        columns={columns}
                        // baseProps={propsTkProvider.baseProps}
                        paginationProps={paginationProps}
                        paginationTableProps={paginationTableProps}
                        actionPrevious={() => handlePagination(0)}
                        actionNext={() => handlePagination(1)}
                        currentPage={page}
                        totalPage={totalPage}
                        totalResults={totalResults}
                      />
                    )}
                  </PaginationProvider>
                )}

              </ContainerView>


            </SectionMargin>

          </ContainerView>

        </ContentStyled>
      </Content>



      {/* {!updateCGButton &&
      <EmptyContent
        image={emptyObject.image}
        title={emptyObject.title}
        description={emptyObject.description}
      />
    } */}


      {messageUpdateConfirmModal && (
        <ConfirmationModal
          show={showUpdateConfirmModal}
          head={'Custo Gerencial!'}
          orient={messageUpdateConfirmModal}
          negative={() => {
            setShowUpdateConfirmModal(false);
            setTimeout(() => {
              setMessageUpdateConfirmModal(null);
            }, 300);
          }}
        />
      )}

    </Screen>

  );
};



export default GERC_IndexScreen;
