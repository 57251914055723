function SmeSolicitacaoAcoesModel(
  inputUtilizacao: string,
  inputOrientacao: string,
  inputArmazem: string,
  inputFilial: string,
  CP_JUST: string,
  CP_TPMANUT: string,
  CP_MOTM: string,
  CP_CLVL: string,
  inputObs: string,
  inputPoduto: string,
  inputUnidade: string,
  inputQuantidade: string,
  inputDescComp: string,
  inputLimite: string,
  inputPrazo: number,
  inputOp: string,
) {
  const SME_SOL_ACOES_MODEL = {
    input_utilizacao: inputUtilizacao,
    input_orientacao: inputOrientacao,
    input_filial: inputFilial,
    input_armazem: inputArmazem,
    CP_JUST: CP_JUST,
    CP_TPMANUT: CP_TPMANUT || '',
    CP_MOTM: CP_MOTM || '',
    CP_CLVL: CP_CLVL || '',
    input_obs: inputObs || '',
    input_poduto: inputPoduto || '',
    input_unidade: inputUnidade || '',
    input_quantidade: inputQuantidade || 0,
    input_desc_comp: inputDescComp || '',
    input_limite: inputLimite || '',
    input_prazo: inputPrazo || null,
    input_op: inputOp || '',
  };

  return SME_SOL_ACOES_MODEL;
};



const SmeSolicitacaoObjProduto = {
  B1_ASME: '',
  B1_COD: '',
  B1_CONTA: '',
  B1_DESC: '',
  B1_GRUPO: '',
  B1_LOCPAD: '',
  B1_PRSUG: 0,
  B1_PRV1: 0,
  B1_RASTRO: '',
  B1_SITEST: '',
  B1_TS: '',
  B1_TTSUG: '',
  B1_UM: '',
  B1_UPRC: 0,
  BM_DESC: '',
  BM_GRPENTR: '',
  BM_GRUPO: '',
  BM_GRUREL: '',
};



const SmeSolicitacaoOP = {
  C2_CC: '',
  C2_DATPRF: '',
  C2_EMISSAO: '',
  C2_ITEM: '',
  C2_LOCAL: '',
  C2_NUM: '',
  C2_PRODUTO: '',
  C2_SEQUEN: '',
  SERVICO: '',
};



export{
  SmeSolicitacaoAcoesModel,
  SmeSolicitacaoObjProduto,
  SmeSolicitacaoOP,
};
