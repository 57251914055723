import axios,
{
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import store from '../store';

import {
  USER_FETCH_INFO,
  USER_RESET,
  USER_TOKEN,
} from '../store/reducers/user.store';
import { intranet_config } from '../utils/Config';



// export const defaultBaseURL = 'http://3.131.160.89:3000';
export const defaultBaseURL = intranet_config?.Registration.url;

// export const ambiente = 'DEV';
export const ambiente = 'PRD';


function ApiWS(base = defaultBaseURL) {
  console.log(base);
  const axiosAppServer = axios.create({
    baseURL: base,
  });



  axiosAppServer.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = localStorage.getItem(USER_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });


  axiosAppServer.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error: any) => {
      const { status } = error.response;

      if (status === 401) {
        // localStorage.removeItem(USER_TOKEN);
        // localStorage.removeItem(USER_FETCH_INFO);

        // store.dispatch({ type: USER_RESET });
        // localStorage.clear();
        

        // Redirect para Login ...
        if (window.location.pathname !== '/') {
          window.location.href = '/logout';
        }
      }
    },
  );

  return axiosAppServer;
};



export default ApiWS;
