/* eslint-disable react/no-deprecated */
import React from 'react';
import ReactDOM from 'react-dom';

import {
  HelmetProvider,
} from 'react-helmet-async';

// import {
//   initializeApp,
// } from 'firebase/app';

import App from './App';

// import AppEnvironments from './shared/environments/application';


// initializeApp(AppEnvironments().firebaseWebConfig);
// getAnalytics(app);



ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
