/* eslint-disable react/display-name */
import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  format,
} from 'date-fns';

import PackageInfo from '../../../../package.json';

import {
  Images,
} from '../../../common/constants';

import {
  RootState,
} from '../../../store';

import { intranet_config } from '../../../utils/Config';

import {
  QrPageFragment,
  QrPageRefContainer,
  QrPageItemPrintContainer,
  QrPageHeader,
  QrPageLogoHeaderView,
  QrPageLogoImage,
  QrPageLogoTitleView,
  QrPageLogoTitle,

  QrAuthorContainer,
  QrItemsDataContainer,
  QrDataContainer,
  QrAuthorTitle,
  QrAuthorName,

  QrPageItemContainer,
  QrSubpageContent,
  QrViewContent,
  QrTextView,

  TIPOText,
  DATAText,
  REQText,
  DEPOSITOText,
  CODIGOText,
  QrPRODUTOText,
  QrUNText,
  QrQTDText,
  QrVALORText,
  QrTOTALText,

  QrPageFooter,
  QrPagerFooterLeft,
  QrPagerFooterCenter,
  QrPagerFooterRight,
  QrPagerFooterTitle,
  QrPagerFooterText,
} from './styled';



export interface IProps {
  data?: any;
  list?: Array<any>;
}



const RelatorioMovimentacoesPrintComponent = React.forwardRef((props: IProps, ref: any) => {
  const user = useSelector((state: RootState) => state.user.data);


  const [listSrc, setListSrc] = useState([]);


  function renderUserQr(element: any, index: number) {
    return (

      <QrViewContent key={index}>

        <QrTextView>
          <TIPOText>
            {element?.TIPO || '-'}
          </TIPOText>


          <DATAText>
            {element?.DATA || '-'}
          </DATAText>


          <REQText>
            {element?.REQ || '-'}
          </REQText>


          <DEPOSITOText>
            {element?.DEPOSITO || '-'}
          </DEPOSITOText>


          <CODIGOText>
            {element?.CODIGO || '-'}
          </CODIGOText>


          <QrPRODUTOText>
            {element?.PRODUTO || '-'}
          </QrPRODUTOText>


          <QrUNText>
            {element?.UN || '-'}
          </QrUNText>


          <QrQTDText>
            {element?.QTD || '-'}
          </QrQTDText>


          <QrVALORText>
            {element?.VALOR || '-'}
          </QrVALORText>


          <QrTOTALText>
            {element?.TOTAL || '-'}
          </QrTOTALText>
        </QrTextView>

      </QrViewContent>

    );
  }


  async function renderTypeItemQr() {
    const listaQR = [
      {
        TIPO: 'Tipo',
        DATA: 'Data',
        REQ: 'Requisição',
        DEPOSITO: 'Depósito',
        CODIGO: 'Código',
        PRODUTO: 'Produto',
        UN: 'Un',
        QTD: 'Qtd.',
        VALOR: 'Valor',
        TOTAL: 'Total',
      },
    ];

    if (props.list) {
      for (let i = 0; i < props.list.length; i++) {
        const elem = props.list[i];

        listaQR.push({
          TIPO: String(elem?.TIPO).trim(),
          DATA: String(elem?.DATA).trim(),
          REQ: String(elem?.REQ).trim(),
          DEPOSITO: String(elem?.DEPOSITO).trim(),
          CODIGO: String(elem?.CODIGO).trim(),
          PRODUTO: String(elem?.PRODUTO).trim(),
          UN: String(elem?.UN).trim(),
          QTD: String(elem?.QTD).trim(),
          VALOR: String(elem?.VALOR).trim(),
          TOTAL: String(elem?.TOTAL).trim(),
        });
      }

      setListSrc(listaQR);
    }
    else {
      setListSrc([]);
    }
  }


  // function renderTypeQr() {
  //   if (props.subtitle) {
  //     return props.subtitle;
  //   }

  //   return 'Tipo';
  // }



  useEffect(() => {
    renderTypeItemQr();
  }, []);


  useEffect(() => {
    renderTypeItemQr();
  }, [props.list]);



  function renderPageQr(index, sublist) {
    return (

      <QrPageItemPrintContainer>
        <QrPageHeader>
          <QrPageLogoHeaderView>
            <QrPageLogoImage
              // alt={'Logo'}
              // src={Images.logoFullApp}
              alt={`${intranet_config?.Registration?.name} Logo`}
              src={intranet_config?.Images?.logo}
            />
          </QrPageLogoHeaderView>



          <QrPageLogoTitleView>

            <QrPageLogoTitle>
              [PSTOCK] Relatório de Movimentações
            </QrPageLogoTitle>


            <QrAuthorContainer>
              <QrAuthorTitle>
                Relatório Gerado por:
              </QrAuthorTitle>
              <QrAuthorName>
                {user.name}
              </QrAuthorName>
            </QrAuthorContainer>



            <QrItemsDataContainer>

              <QrDataContainer>
                <QrAuthorTitle>
                  Funcionário:
                </QrAuthorTitle>
                <QrAuthorName>
                  {props.data?.NOME || '-'}
                </QrAuthorName>
              </QrDataContainer>


              <QrDataContainer>
                <QrAuthorTitle>
                  Qtd. Itens:
                </QrAuthorTitle>
                <QrAuthorName>
                  {props.data?.ITENS || '-'}
                </QrAuthorName>
              </QrDataContainer>

            </QrItemsDataContainer>



            <QrItemsDataContainer>

              <QrDataContainer>
                <QrAuthorTitle>
                  Qtd. Total Produtos:
                </QrAuthorTitle>
                <QrAuthorName>
                  {props.data?.TOTAL_PRODUTOS || '-'}
                </QrAuthorName>
              </QrDataContainer>


              <QrDataContainer>
                <QrAuthorTitle>
                  Valor Total:
                </QrAuthorTitle>
                <QrAuthorName>
                  {props.data?.VALOR_TOTAL || '-'}
                </QrAuthorName>
              </QrDataContainer>

            </QrItemsDataContainer>

          </QrPageLogoTitleView>
        </QrPageHeader>



        <QrPageItemContainer>
          <QrSubpageContent>

            {sublist.map((elem: any, index: number) => (
              renderUserQr(elem, index)
            ))}

          </QrSubpageContent>
        </QrPageItemContainer>



        <QrPageFooter>
          <QrPagerFooterLeft>
            <QrPagerFooterTitle>
              Versão da aplicação:
            </QrPagerFooterTitle>
            <QrPagerFooterText>
              {PackageInfo.version}
            </QrPagerFooterText>
          </QrPagerFooterLeft>


          <QrPagerFooterCenter>
            <QrPagerFooterTitle>
              Data de impressão:
            </QrPagerFooterTitle>
            <QrPagerFooterText>
              {format(new Date(Date.now()), 'dd/MM/yyyy HH:mm')}
            </QrPagerFooterText>
          </QrPagerFooterCenter>


          <QrPagerFooterRight>
            <QrPagerFooterTitle>
              Página:
            </QrPagerFooterTitle>
            <QrPagerFooterText>
              {index}
            </QrPagerFooterText>
          </QrPagerFooterRight>
        </QrPageFooter>

      </QrPageItemPrintContainer>

    );
  }


  function mapToPages(quantity: number) {
    const pages = [];
    for (let i = 0; i < listSrc.length / quantity; i++) {
      pages.push(renderPageQr((i + 1), listSrc.slice(i * quantity, (i + 1) * quantity)));
    }
    return pages;
  }



  return listSrc.length > 0 ?
    <QrPageFragment>

      <QrPageRefContainer ref={ref}>
        {mapToPages(23)}
      </QrPageRefContainer>

    </QrPageFragment>
    : null;
});



export default RelatorioMovimentacoesPrintComponent;
