import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Form,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Formik,
} from 'formik';


import {
  Colors,
  Images,
  Metrics,
} from '../../../../common/constants';

import ExcelConfigs from '../../../../common/excel';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  SeparatorButton,
  TitleGroup,
} from '../../../../common/styles/styled.layout';


import IButton from '../../../../components/Buttons/IButton';
import EmptyContent from '../../../../components/Empty';
import NavComp from '../../../../components/Headers/NavComp';
import NavHeader from '../../../../components/Headers/NavHeader';
import NavUser from '../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../components/Loadings';
import TableCuston from '../../../../components/Table';
import DropdownInput from '../../../../components/TextFields/Dropdown';
import TextInput from '../../../../components/TextFields/Input';


import ApiWS, { defaultBaseURL } from '../../../../services/api.service';


import {
  returnErrorObject,
} from '../../../../utils/ErrorUtils';

import FornecedoresModel from './fornecedor.model';
import FornecedoresValidationSchema from './fornecedor.validation';
import FORNECNovoFornecedorModal from './Modals/NovoFornecedorModal';
import { IToast } from '../../../../components/IToast/Toast';


const FORNECEDORES_IndexScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [carregando, setCarregando] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum Fornecedor aqui!',
    description: 'Não há nenhum fornecedor aqui',
  });


  const [listForncedor, setListFornecedor] = useState([]);
  const [showListForn, setShowListForn] = useState(false);
  const [listaCarregando, setListaCarregando] = useState(false);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);

  const [possueBCO, setPossueBCO] = useState(false);

  const [showModalNovoFornecedor, setShowModalNovoFornecedor] = useState(false);

  const fontSizeCell = '11px';

  const columns = [
    {
      dataField: 'RAZAO',
      text: 'Razao Social',
      sort: true,
      headerStyle: () => {
        return { width: '12%' };
      },
    },
    {
      dataField: 'NREDUZ',
      text: 'Nome',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'COD',
      text: 'Codigo',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'LOJA',
      text: 'Loja',
      sort: true,
      headerStyle: () => {
        return { width: '3%' };
      },
    },
    {
      dataField: 'ENDE',
      text: 'Endereco',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CEP',
      text: 'CEP',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'MUNICIPIO',
      text: 'Cidade',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'ESTADO',
      text: 'UF',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'CNPJ',
      text: 'CNPJ',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CONTATO',
      text: 'Contato',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'TEL',
      text: 'Telefone',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'EMAIL',
      text: 'Email',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'SIT',
      text: 'Situação',
      sort: true,
      style: (_, row) => {
        return row.SIT === 'Bloqueado'
          ? { color: 'red', fontSize: fontSizeCell }
          : { color: 'green', fontSize: fontSizeCell };
      },
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'BCO',
      text: 'BCO',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'AGENCIA',
      text: 'Agência',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'CONTA',
      text: 'Conta',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'FORMAPGTO',
      text: 'Forma Pgt.',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'TIPOPIX',
      text: 'Tipo PIX',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CHAVEPIX',
      text: 'Chave',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listForncedor.length,
  };



  async function handleFornecedores(values: typeof FornecedoresModel, page_iteration: number) {
    try {
      setListaCarregando(true);
      setShowListForn(false);
      setListFornecedor([]);
      setValuesPayload(values);
      setPage(1);

      values = {
        ...values,
        'PAGINA': page_iteration,
        'TAMANHO': Metrics.size_supplier,
      };

      const url = '/fornecedores/consulta';
      const response = await ApiWS().post(url, values);

      setListFornecedor(response.data[0]);
      setTotalResults(response.data[1][0].TOTAL);
      setTotalPage(Math.ceil(response.data[1][0].TOTAL / 10));
    }
    catch (error) {
      console.error('EfetivoFunction', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListaCarregando(false);
      setShowListForn(true);
    }
  };


  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    handleFornecedores(valuesPayload, page_var);
    setPage(page_var);
  };

  async function postSolicitarNovoFornecedor() {
    console.log('Novo');
  };


  function postExport(values: any) {
    setCarregando(true);

    const url = '/fonercedores/exporta';

    ApiWS()
      .post(url, [values])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  return (


    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          title={'Fornecedores'}
        />
      </NavHeader>


      <Content>
        <ContentStyled fluid>
          <ContainerView>

            <SectionMargin>
              <TitleGroup>
                {'Fornecedores'.toUpperCase()}
              </TitleGroup>



              {listaCarregando && (
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              )}


              <SectionMargin>
                <Formik
                  enableReinitialize
                  validateOnMount
                  validationSchema={FornecedoresValidationSchema}
                  initialValues={FornecedoresModel}
                  onSubmit={(values) => {
                    handleFornecedores(values, 1);
                  }}>
                  {({
                    values,
                    resetForm,
                    isValid,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => (

                    <FilterContainerForm auto onSubmit={handleSubmit}>

                      <Row>

                        <Row>

                          <Col>

                            <DropdownInput
                              disabled={carregando || listaCarregando}
                              name={'SIT'}
                              label={'Situação'}
                              defaultValue={'TODOS'}
                              dropArray={[
                                {
                                  key: '01',
                                  label: 'Todos',
                                  value: 'TODOS',
                                },
                                {
                                  key: '02',
                                  label: 'Bloqueados',
                                  value: 'Bloqueado',
                                },
                                {
                                  key: '03',
                                  label: 'Desbloqueados',
                                  value: 'Desbloqueado',
                                },
                              ]}
                              value={values.SIT || 'TODOS'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                          </Col>


                          <Col>

                            <TextInput
                              disabled={carregando || listaCarregando}
                              type={'text'}
                              name={'RAZAO'}
                              label={'Razão Social'}
                              placeholder={'Razão Social'}
                              min={0}
                              value={values.RAZAO || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                          </Col>


                          <Col>

                            <Form.Check
                              checked={possueBCO}
                              type="checkbox"
                              name="BCO"
                              label="Possui BCO?"
                              onChange={(e: any) => {
                                handleChange(e);
                                setPossueBCO(!possueBCO);
                                setFieldValue('BCO', !possueBCO ? 'S' : 'N');
                              }}
                              onBlur={handleBlur}
                            />

                          </Col>

                        </Row>

                        <Row>

                          <Col>

                            <TextInput
                              disabled={carregando || listaCarregando}
                              type={'text'}
                              name={'COD'}
                              label={'Código'}
                              placeholder={'Código'}
                              value={values.COD || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                          </Col>


                          <Col>
                            <TextInput
                              disabled={carregando || listaCarregando}
                              type={'text'}
                              name={'CNPJ'}
                              label={'CNPJ'}
                              placeholder={'CNPJ'}
                              max={14}
                              value={values.CNPJ || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                          </Col>

                        </Row>


                      </Row>



                      <RowButtonFiltersView>

                        {listForncedor.length > 0 && (
                          <IButton
                            disabled={!isValid || carregando || listaCarregando}
                            backgroundColor={Colors?.excel}
                            text={'Exportar'}
                            onClick={() => {
                              postExport(values);
                            }}
                          />
                        )}


                        {listForncedor.length > 0 && <SeparatorButton />}


                        <IButton
                          disabled={carregando || listaCarregando}
                          text={'Solicitar Cadastro'}
                          backgroundColor={Colors?.tertiaryDark}
                          type={'button'}
                          onClick={() => {
                            setShowModalNovoFornecedor(true);
                          }}
                        />

                        <IButton
                          disabled={carregando || listaCarregando}
                          text={'Limpar'}
                          backgroundColor={Colors?.gray}
                          type={'reset'}
                          onClick={() => {
                            resetForm();

                            // setValues(IFuncEfetivoModel);
                          }}
                        />


                        <IButton
                          disabled={!isValid || carregando || listaCarregando}
                          text={'Filtrar'}
                          backgroundColor={Colors?.primary}
                          type={'submit'}
                        />

                      </RowButtonFiltersView>

                    </FilterContainerForm>

                  )}
                </Formik>
              </SectionMargin>



              <ContainerView>

                {!carregando && !listaCarregando && showListForn && listForncedor.length === 0 && (
                  <EmptyContent
                    image={emptyObject.image}
                    title={emptyObject.title}
                    description={emptyObject.description}
                  />
                )}



                {!carregando && !listaCarregando && showListForn && listForncedor.length > 0 && (
                  <PaginationProvider
                    pagination={paginationFactory(options)}>
                    {({
                      paginationProps,
                      paginationTableProps,
                    }) => (
                      <TableCuston
                        data={listForncedor}
                        columns={columns}
                        // baseProps={propsTkProvider.baseProps}
                        paginationProps={paginationProps}
                        paginationTableProps={paginationTableProps}
                        actionPrevious={() => handlePagination(0)}
                        actionNext={() => handlePagination(1)}
                        currentPage={page}
                        totalPage={totalPage}
                        totalResults={totalResults}
                      />
                    )}
                  </PaginationProvider>
                )}


                {!carregando && !listaCarregando && showModalNovoFornecedor && (
                  <FORNECNovoFornecedorModal show={showModalNovoFornecedor} onHide={() => setShowModalNovoFornecedor(false)} />
                )}

              </ContainerView>


            </SectionMargin>

          </ContainerView>

        </ContentStyled>
      </Content>

    </Screen>

  );
};



export default FORNECEDORES_IndexScreen;
