const INewCOModel = {

  CONTA_OPE1: '',

  CONTA_OPE2: '',

  DESCR: '',

  CONTA_CON: '',

  ORDEM: '',

  CONTA_GER: '',

};



export default INewCOModel;
