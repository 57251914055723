import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  OverlayTrigger,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

// import {
//   useHistory,
// } from 'react-router-dom';

import ReactToPrint from 'react-to-print';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  AllItems,
} from '../../../../../../common/arrays';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  SeparatorButton,
  TableActionButton,
  TableActionNextCss,
  TableActionUpButton,
  TablePopover,
  TableToltip,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import PStockFuncionarioValidationSchema from '../../../../../../common/validations/Supplies/PSTOCK/pstock-funcionario.validation';

import IButton from '../../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../../components/Empty';

import {
  // IconAdd,
  // IconEdit,
  IconEyeOpen,
  IconNot,
  IconQrCode,
  IconRefresh,
  // IconTrash,
} from '../../../../../../components/Icons';

import LoadingsComponent from '../../../../../../components/Loadings';
import ConfirmationModal from '../../../../../../components/Modals/Confirmation';
import QrCodeComponent from '../../../../../../components/QrCode';
import TableCuston from '../../../../../../components/TableOld';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';

import PStockProdutoModel from '../../../../../../models/Supplies/PSTOCK/pstock-produto.model';

// import NameRoutes from '../../../../../../navigation/name';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';

import {
  validateString,
} from '../../../../../../utils/StringUtils';

import PStockDetailProductModal from '../Modals/DetailProductModal';



const Tab_ProdutosScreen: React.FC = () => {
  // const history = useHistory();
  const compRef = useRef([]);
  const buttonRef = useRef(null);


  const [loading, setLoading] = useState(true);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem produtos!',
    description: 'Não há nenhum produto aqui',
  });

  const [listCC, setListCC] = useState([]);

  const [showProductList, setShowProductList] = useState(false);
  const [listProduct, setListProduct] = useState([]);

  const [productDetails, setProductDetails] = useState(null);
  const [showModalDetails, setShowModalDetails] = useState(false);

  const [productDelete, setProductDelete] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [loadingProductList, setLoadingProductList] = useState(false);
  const [messageUpdateConfirmModal, setMessageUpdateConfirmModal] = useState(null);

  const [formValues, setFormValues] = useState(null);



  function renderListByCC(listGrpResp: any) {
    const listGrpCcMap = listGrpResp.map((item) => {
      const valueCC = item.CC !== '' || item.CC !== null || item.CC !== undefined ? item.CC : null;

      const CcMapped = {
        'key': item.CC,
        'label': item.CC,
        'value': valueCC,
      };
      return CcMapped;
    });

    setListCC([
      AllItems,
      ...listGrpCcMap,
    ]);
  };


  async function removeItem(item) {
    console.log('DELETAR', item);
  }


  async function getCcByUser() {
    try {
      setLoading(true);

      const url = '/pstock/adm/produto/consulta/os';
      const response = await ApiWS().get(url);

      renderListByCC(response.data);
    }
    catch (error) {
      console.error('getCcByUser', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setLoading(false);
    }
  };


  async function handleConsulta(values: typeof PStockProdutoModel) {
    const payload = {
      'COD': values.input_cod,
      'PRODUTO': values.input_produto,
      'GRUPO': values.input_grupo,
      'CC': values.input_cc.trim(),
      'DEPOSITO': values.input_deposito,
    };

    try {
      setLoadingProductList(true);
      setShowProductList(false);

      const url = '/pstock/adm/produto/consulta';
      const response = await ApiWS().post(url, payload);

      setListProduct(response.data);
    }
    catch (error) {
      console.error('handleConsulta', JSON.stringify(error.response, null, 2));
      setListProduct([]);

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setLoadingProductList(false);
      setShowProductList(true);
    }
  };


  async function postSincronizar(valueCC) {
    const payload = {
      'CC': valueCC.trim(),
    };

    try {
      setLoadingProductList(true);

      const url = '/pstock/adm/produto/sincronizar';
      const response = await ApiWS().post(url, payload);

      if (response.status === 200) {
        setShowUpdateConfirmModal(true);
        setMessageUpdateConfirmModal('Atualização de produtos concluída');
      }
      else {
        toast.error('Erro ao atualizar produtos! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
    catch (error) {
      console.error('postSincronizar', JSON.stringify(error.response, null, 2));
      setShowUpdateConfirmModal(true);
      setMessageUpdateConfirmModal('Houve um erro ao atualizar produtos, por favor entre em contato com o suporte.');
    }
    finally {
      setLoadingProductList(false);
      if (formValues) {
        handleConsulta(formValues);
      }
    }
  }



  useEffect(() => {
    getCcByUser();
  }, []);



  const columns = [
    {
      dataField: 'CC',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'COD',
      text: 'Código',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'TAG',
      text: 'Tag',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return validateString(cell.trim()) ? cell.trim() : '--';
      },
    },
    {
      dataField: 'PRODUTO',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'GRUPO',
      text: 'Grupo',
      sort: true,
    },
    {
      dataField: 'ESTOQUE',
      text: 'Estoque',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'UN',
      text: 'UN',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'DEPOSITO',
      text: 'Depósito',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'BLOQ',
      text: 'Bloqueio',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell, row) => {
        // Botão Visualizar
        return row.BLOQ === 'S' ?
          <IconNot
            color={Colors?.quaternary}
            size={22}
          />
          : null;
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Visualizar
        return (
          <OverlayTrigger
            key={'left'}
            placement={'bottom-end'}
            trigger={['hover', 'hover']}
            overlay={
              <TablePopover>
                Visualizar
              </TablePopover>
            }>
            <TableActionButton
              backgroundColor={Colors?.primary}
              onClick={() => {
                setProductDetails(row);
                setShowModalDetails(true);
              }}>
              <IconEyeOpen
                color={Colors?.black}
                size={16}
              />
            </TableActionButton>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row, rowIndex) => {
        // Botão QR Code
        return (
          <>
            <ReactToPrint
              content={() => (compRef as any).current[rowIndex]}
              trigger={() => (
                <TableActionUpButton>
                  <OverlayTrigger
                    key={'left'}
                    placement={'left'}
                    trigger={['hover', 'hover']}
                    overlay={
                      <TableToltip>
                        QR Code
                      </TableToltip>
                    }>
                    <TableActionButton
                      backgroundColor={Colors?.primary}>
                      <IconQrCode
                        color={Colors?.black}
                        size={16}
                      />
                    </TableActionButton>
                  </OverlayTrigger>
                </TableActionUpButton>
              )}
            />

            <QrCodeComponent
              ref={(element) => (compRef.current[rowIndex] = element)}
              type={'PRODUTO'}
              list={[{
                COD: row.COD,
                PRODUTO: row.PRODUTO,
                QRCODE: row.QRCODE,
              }]}
            />
          </>
        );
      },
    },
    // {
    //   dataField: '',
    //   text: '',
    //   headerStyle: () => {
    //     return TableActionNextCss;
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Editar
    //     return (
    //       <OverlayTrigger
    //         key={'left'}
    //         placement={'bottom-end'}
    //         trigger={['hover', 'hover']}
    //         overlay={
    //           <TablePopover>
    //             Editar
    //           </TablePopover>
    //         }>
    //         <TableActionButton
    //           backgroundColor={Colors?.primary}
    //           onClick={() => {
    //             // TODO
    //             console.log(row);
    //           }}>
    //           <IconEdit
    //             color={Colors?.black}
    //             size={16}
    //           />
    //         </TableActionButton>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
    // {
    //   dataField: '',
    //   text: '',
    //   headerStyle: () => {
    //     return TableActionNextCss;
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Deletar
    //     return (
    //       <OverlayTrigger
    //         key={'left'}
    //         placement={'bottom-end'}
    //         trigger={['hover', 'hover']}
    //         overlay={
    //           <TablePopover>
    //             Deletar
    //           </TablePopover>
    //         }>
    //         <TableActionButton
    //           backgroundColor={Colors?.primary}
    //           onClick={() => {
    //             setProductDelete(row);
    //             setShowModalDelete(true);
    //           }}>
    //           <IconTrash
    //             color={Colors?.black}
    //             size={16}
    //           />
    //         </TableActionButton>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
  ];


  const options = {
    custom: true,
    totalSize: listProduct.length,
  };



  return (

    <ContainerView>

      {loading && (
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.tertiary}
        />
      )}



      {!loading && (
        <ContainerView>

          <SectionMargin>
            <TitleGroup>
              {'Consultar Produtos'.toUpperCase()}
            </TitleGroup>


            <SectionMargin>
              <Formik
                enableReinitialize
                validateOnMount
                validationSchema={PStockFuncionarioValidationSchema}
                initialValues={PStockProdutoModel}
                onSubmit={(values) => {
                  setFormValues(values);
                  handleConsulta(values);
                }}>
                {({
                  values,
                  resetForm,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setValues,
                }) => (

                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <Row>
                      <Col>
                        <TextInput
                          disabled={loadingProductList}
                          type={'text'}
                          name={'input_cod'}
                          label={'Cód.'}
                          placeholder={'Cód.'}
                          value={values.input_cod || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <TextInput
                          disabled={loadingProductList}
                          type={'text'}
                          name={'input_produto'}
                          label={'Produto'}
                          placeholder={'Produto'}
                          value={values.input_produto || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <TextInput
                          disabled={loadingProductList}
                          type={'text'}
                          name={'input_grupo'}
                          label={'Grupo'}
                          placeholder={'Grupo'}
                          value={values.input_grupo || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <DropdownInput
                          disabled={loadingProductList}
                          name={'input_cc'}
                          label={'CC'}
                          defaultValue={'Todos'.toLocaleUpperCase()}
                          dropArray={listCC}
                          value={values.input_cc || 'Todos'.toLocaleUpperCase()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <TextInput
                          disabled={loadingProductList}
                          type={'text'}
                          name={'input_deposito'}
                          label={'Depósito'}
                          placeholder={'Depósito'}
                          value={values.input_deposito || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>



                    <RowButtonFiltersView>
                      {!loadingProductList && showProductList && listProduct.length > 0 && (
                        <>
                          <ReactToPrint
                            content={() => (buttonRef as any).current}
                            trigger={() => (
                              <IButton
                                disabled={loadingProductList}
                                backgroundColor={Colors?.tertiary}
                                text={'Todos os QR da busca'}
                                iconLeft={
                                  <IconQrCode
                                    color={'#FFFFFF'}
                                    size={20}
                                  />
                                }
                              />
                            )}
                          />

                          <QrCodeComponent
                            ref={buttonRef}
                            type={'PRODUTO'}
                            list={listProduct}
                          />
                        </>
                      )}
                      <SeparatorButton />


                      <IButton
                        disabled={loadingProductList}
                        backgroundColor={Colors?.secondary}
                        text={'Sincronizar'}
                        iconLeft={
                          <IconRefresh
                            color={'#FFFFFF'}
                            size={20}
                          />
                        }
                        onClick={() => {
                          postSincronizar(values.input_cc);
                        }}
                      />
                      <SeparatorButton />


                      {/* <IButton
                        disabled={loadingProductList}
                        backgroundColor={Colors?.excel}
                        text={'Adicionar'}
                        iconLeft={
                          <IconAdd
                            color={'#FFFFFF'}
                            size={24}
                          />
                        }
                        onClick={() => {
                          history.push(`/${NameRoutes.NovoProdutoScreen}`);
                        }}
                      />
                      <SeparatorButton /> */}


                      <IButton
                        disabled={loadingProductList}
                        text={'Limpar'}
                        backgroundColor={Colors?.gray}
                        type={'reset'}
                        onClick={() => {
                          resetForm();

                          setValues(PStockProdutoModel);
                        }}
                      />


                      <IButton
                        disabled={loadingProductList}
                        text={'Consultar'}
                        backgroundColor={Colors?.primary}
                        type={'submit'}
                      />
                    </RowButtonFiltersView>

                  </FilterContainerForm>

                )}
              </Formik>
            </SectionMargin>



            <ContainerView>

              {loadingProductList && (
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              )}


              {!loadingProductList && showProductList && listProduct.length === 0 && (
                <EmptyContent
                  image={emptyObject.image}
                  title={emptyObject.title}
                  description={emptyObject.description}
                />
              )}



              {!loadingProductList && showProductList && listProduct.length > 0 && (
                <PaginationProvider
                  pagination={paginationFactory(options)}>
                  {({
                    paginationProps,
                    paginationTableProps,
                  }) => (
                    <TableCuston
                      noQuantity={listProduct.length < 10}
                      data={listProduct}
                      columns={columns}
                      paginationProps={paginationProps}
                      paginationTableProps={paginationTableProps}
                    />
                  )}
                </PaginationProvider>
              )}

            </ContainerView>


          </SectionMargin>



          {productDetails && (
            <PStockDetailProductModal
              show={showModalDetails}
              data={productDetails}
              onHide={() => {
                setShowModalDetails(false);
                setTimeout(() => {
                  setProductDetails(null);
                }, 300);
              }}
            />
          )}



          {productDelete && (
            <ConfirmationModal
              show={showModalDelete}
              head={'Deletar Produto'}
              orient={'Tem certeza que deseja deletar o produto ' + productDelete.Nome + '?'}
              positive={() => {
                removeItem(productDelete);
              }}
              negative={() => {
                setShowModalDelete(false);
                setTimeout(() => {
                  setProductDelete(null);
                }, 300);
              }}
            />
          )}

        </ContainerView>
      )}



      {messageUpdateConfirmModal && (
        <ConfirmationModal
          show={showUpdateConfirmModal}
          head={'PStock - Produtos!'}
          orient={messageUpdateConfirmModal}
          negative={() => {
            setShowUpdateConfirmModal(false);
            setTimeout(() => {
              setMessageUpdateConfirmModal(null);
            }, 300);
          }}
        />
      )}

    </ContainerView>

  );
};



export default Tab_ProdutosScreen;
