import { Formik } from 'formik';
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Modal, Row,
} from 'react-bootstrap';

import {
  Colors,
} from '../../../../../../common/constants';

import {
  FilterContainerForm, TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';
import { IToast } from '../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../../components/Modals/styled';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../services/api.service';
import INewCOModel from './NewCO.model';
import NewCOValidationSchema from './NewCO.validation';



interface IProps {
  show: boolean;
  onHide?: any;
}


const PEXNewCOModal: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const [listDropCO, setListDropCO] = useState([]);
  const [listDropCC, setListDropCC] = useState([]);
  const [listDropCG, setListDropCG] = useState([]);

  function postDropCO() {
    setLoading(true);

    const url = '/pex/co/consulta/co';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((conta, index) => {
            setListDropCO((list) => [...list, {
              key: index,
              label: conta.TELA,
              value: conta.CONTA,
            }],
            );
          });
        };
      })

      .catch((error) => {
        console.error('postDropCO', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Operacionais. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Operacionais. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  function postDropCC() {
    setLoading(true);

    const url = '/pex/co/consulta/cc';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((conta, index) => {
            setListDropCC((list) => [...list, {
              key: index,
              label: conta.TELA,
              value: conta.CONTA,
            }],
            );
          });
        };
      })

      .catch((error) => {
        console.error('postDropCC', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Contabeis. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Contabeis. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        postDropCO();
      });
  };

  function postDropCG() {
    setLoading(true);

    const url = '/pex/co/consulta/cg';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((conta, index) => {
            setListDropCG((list) => [...list, {
              key: index,
              label: conta.TELA,
              value: conta.CONTA,
            }],
            );
          });
        };
      })
      .catch((error) => {
        console.error('postDropCG', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Contabeis. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Contabeis. Contate o suporte! ${error.response.status}`,
          });
        }
      })
      .finally(() => {
        postDropCC();
      });
  };

  function postSaveNew(values) {
    setLoading(true);

    const url = '/pex/co/salvar';
    const payload = values;

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: resp.data,
          });

          setTimeout(() => {
            props.onHide();
          }, 300);
        }
        else {
          IToast({
            type: 'warning',
            message: resp.data,
          });
        }
      })

      .catch((error) => {
        console.error('postSaveNew', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao salvar. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao salvar. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    postDropCG();
  }, []);

  return (

    <Modal
      {...props}
      show={props.show}
      size={'xl'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Nova Conta Operacional'.toUpperCase()}
        </TitleGroup>


        {loading && (
          <LoadingsComponent
            type={'SCREEN'}
            color={Colors?.tertiary}
          />
        )}


        {!loading && (
          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={NewCOValidationSchema}
            initialValues={INewCOModel}
            onSubmit={(values) => {
              postSaveNew(values);
            }}>
            {({
              values,
              resetForm,
              isValid,
              handleSubmit,
              handleChange,
              handleBlur,
              setValues,
            }) => (<>

              <ModalBodyStyled>

                <FilterContainerForm auto onSubmit={handleSubmit}>

                  <Row>

                    <Col xs={5}>
                      <DropdownInput
                        disabled={loading || values.CONTA_OPE2.length > 0}
                        name={'CONTA_OPE1'}
                        label={'Conta Operacional'}
                        placeholder={'Selecione'}
                        dropArray={listDropCO}
                        value={values.CONTA_OPE1}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </Col>


                    <Col xs={2}>
                      <TextInput
                        disabled={loading || values.CONTA_OPE1.length > 0}
                        type={'text'}
                        name={'CONTA_OPE2'}
                        label={'Código'}
                        placeholder={'Código da Nova Conta Oper.'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.CONTA_OPE2} />
                    </Col>


                    <Col xs={5}>
                      <TextInput
                        disabled={loading || values.CONTA_OPE1.length > 0}
                        type={'text'}
                        name={'DESCR'}
                        label={'Descrição'}
                        placeholder={'Descrição'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.DESCR} />
                    </Col>

                  </Row>


                  <Row>

                    <Col xs={5}>
                      <DropdownInput
                        disabled={loading}
                        name={'CONTA_CON'}
                        label={'Conta Contábil'}
                        placeholder={'Selecione'}
                        dropArray={listDropCC}
                        value={values.CONTA_CON}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </Col>


                    <Col xs={2}>
                      <TextInput
                        disabled={loading || values.CONTA_OPE1.length > 0}
                        type={'number'}
                        name={'ORDEM'}
                        label={'Ordem'}
                        placeholder={'Ordem'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ORDEM} />
                    </Col>


                    <Col xs={5}>
                      <DropdownInput
                        disabled={loading}
                        name={'CG'}
                        label={'Conta Gerencial'}
                        placeholder={'Todos'.toLocaleUpperCase()}
                        defaultValue={'Todos'.toLocaleUpperCase()}
                        dropArray={listDropCG}
                        value={values.CONTA_GER || 'Todos'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>

                  </Row>

                </FilterContainerForm>

              </ModalBodyStyled>


              <ModalFooterStyled>
                <IButton
                  backgroundColor={Colors?.gray}
                  text={'Fechar'}
                  onClick={props.onHide}
                />

                <IButton
                  type={'submit'}
                  backgroundColor={Colors?.primary}
                  text={'Salvar'}
                  onClick={() => postSaveNew(values)}
                />
              </ModalFooterStyled>


            </>)}

          </Formik>
        )}


      </ModalContainerStyled>

    </Modal>

  );
};



export default PEXNewCOModal;

