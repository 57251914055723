import React,
{
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Redirect,
} from 'react-router';

import {
  Formik,
} from 'formik';

import * as Yup from 'yup';

import {
  Colors,
} from '../../common/constants';

import {
  Overlay,
  Screen,
} from '../../common/styles/styled.layout';

import LoginValidationSchema from '../../common/validations/Login';

import { IToast } from '../../components/IToast/Toast';
import LoadingsComponent from '../../components/Loadings';
import TextInput from '../../components/TextFields/Input';
import ApiWS from '../../services/api.service';

import {
  RootState,
} from '../../store';

import {
  USER_FETCH_INFO,
  USER_RESET,
  USER_TOKEN,
} from '../../store/reducers/user.store';

import { intranet_config } from '../../utils/Config';
import {
  LoginBody,
  LoginContainer,
  LoginFormView,
  LoginView,
  LogoImgContent,
  LogoImg,
  LoginTextsContent,
  WelcomeTitle,
  WelcomeText,
  LoginInputsContent,
  ButtonLogin,
} from './styled';



const NotLicenseScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);


  async function VerifyKey(values: any) {
    setLoading(true);


    const url = '/licenca/chave';
    const payload = { ...values, IDENTIFICADOR: localStorage.getItem('@intranet/identificador') };


    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: 'Licença ativada com sucesso!',
            autoClose: false,
          });

          window.location.reload();
        }
        else {
          IToast({
            type: 'warning',
            message: 'Não foi possível identificar a chave informada! Verifique a chave e tente novamente.',
            autoClose: false,
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Erro ao verificar chave licença! Atualize a página, caso o problema persista contate o suporte.',
          autoClose: false,
        });


        console.error('Licence Not Present', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (

    <Screen>

      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          CHAVE: '',
          CODIGO: '',
        }}
        validationSchema={
          Yup.object().shape({
            CHAVE: Yup.string()
              .required('A chave é obrigatória!'),
            CODIGO: Yup.string()
              .required('O código é obrigatório!'),
          })
        }
        onSubmit={(values) => {
          VerifyKey(values);
        }}>
        {({
          handleChange,
          // handleBlur,
          handleSubmit,
          values,
          // errors,
        }) => (

          <LoginBody>
            <Overlay />



            <LoginContainer>
              <LoginFormView onSubmit={handleSubmit}>

                <LogoImgContent>
                  <LogoImg
                    alt={`${intranet_config?.Registration.name} Logo`}
                    src={intranet_config?.Images.logo}
                  />
                </LogoImgContent>


                {loading && (
                  <LoginView>
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  </LoginView>
                )}


                {!loading && (
                  <LoginView>

                    <LoginTextsContent>
                      <WelcomeTitle>
                        Bem vindo!
                      </WelcomeTitle>

                      <WelcomeText>
                        {`Verificamos que sua licença pode ter expirado em ${localStorage.getItem('@intranet/validade')}.`}
                        <br />
                        Para continuar acessando este produto da Planeja TI é necessário inserir a Chave de Licença e o Código abaixo.
                        <br />
                        Entre em contato com o suporte e insira a chave e o código informados nos campos abaixo.
                      </WelcomeText>
                    </LoginTextsContent>


                    <LoginInputsContent>

                      <TextInput
                        showPass
                        type={'password'}
                        name={'CHAVE'}
                        label={'Chave'}
                        placeholder={'Chave de Licença'}
                        onChange={handleChange}
                        value={values.CHAVE}
                      />


                      <TextInput
                        showPass
                        type={'password'}
                        name={'CODIGO'}
                        label={'Código'}
                        placeholder={'Código'}
                        onChange={handleChange}
                        value={values.CODIGO}
                      />

                    </LoginInputsContent>


                    <ButtonLogin
                      size={'lg'}
                      type={'submit'}>
                      Verificar
                    </ButtonLogin>

                  </LoginView>
                )}

              </LoginFormView>
            </LoginContainer>

          </LoginBody>

        )}
      </Formik>

    </Screen >

  );
};



export default NotLicenseScreen;
