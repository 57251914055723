import React,
{
  useState,
  useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
import RelatorioPOCModal from '../_Modals/RelatorioPOCModal';
import { Colors, Images } from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../common/excel';
import { ContainerView, Content, ContentStyled, FilterContainerForm, RowButtonFiltersView, Screen, SectionMargin, TitleGroup } from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconExcel, IconPrinter } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import NameRoutes from '../../../../../navigation/name';
import { POC_NavRoutes } from '../../../../../navigation/navs/SGI';
import ApiWS from '../../../../../services/api.service';
import POCCartaoModel from './POCCartao.model';
import POCCartaoValidationSchema from './POCCartao.validation';

const POCConsultaScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [permission] = useState(true);
  const [error] = useState(false);

  const [listBusinessDrop, setListBusinessDrop] = useState([]);
  const [listReport, setListReport] = useState([]);
  const [showModalReport, setShowModalReport] = useState(false);

  const [listPocs, setListPocs] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem Cartões do POC!',
    description: 'Não há nenhum cartão aqui',
  });


  function postCCDrop() {
    setLoading(true);
    setShowList(false);

    const url = '/poc/cartao/consulta/cc';

    ApiWS()
      .post(url)
      .then((res) => {
        res.data.forEach((business, index) => {
          setListBusinessDrop((listBusiness) => [
            ...listBusiness,
            {
              key: index,
              label: business.OS,
              value: business.OS,
            },
          ]);
        });
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  function postFetchCard(values: any) {
    setLoading(true);
    setShowList(false);

    const url = '/poc/cartao/consulta';

    ApiWS()
      .post(url, [values])
      .then((res) => {
        setListPocs(res.data);
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  useEffect(() => {
    // if (postPermission()) {
    if (true) {
      postCCDrop();
    }
  }, []);


  const options = {
    custom: true,
    totalSize: listPocs.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'Nro. POC',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'CC',
      text: 'Centro Custo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: 'AREA',
      text: 'Area',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'OBSERVADOR',
      text: 'Observador',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'MATRICULA',
      text: 'Matricula',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => cell === null ? '' : cell,
    },
    {
      dataField: 'EMISSOR',
      text: 'Cadastrado por',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'DTCADASTRO',
      text: 'Data',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: '',
      text: 'Imprimir',
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.PRINTER}
            icon={
              <IconPrinter
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              window.open(`/${NameRoutes.POC_Consulta_Impressao}/${row.ID}`, '_blank').focus();
            }}
          />
        );
      },
      csvExport: false,
    },
    {
      dataField: '',
      text: 'Relatório',
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.EXPORT}
            icon={
              <IconExcel
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              setLoading(true);

              ApiWS()
                .post('/poc/relatorio/consulta/', [{ NUM: row.ID }])
                .then((resp) => {
                  setListReport(resp.data);
                  setShowModalReport(true);
                })
                .catch((error) => {
                  console.error('Erro ao exportar Relatório:::', error);
                  IToast({
                    type: 'error',
                    message: 'Erro ao exportar relatório. Tente novamente, caso o erro persista, contate o suporte!',
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          />
        );
      },
      csvExport: false,
    },

  ];


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />

        <NavComp
          navKey={2}
          navArray={POC_NavRoutes}
          title={'POC'}
        />
      </NavHeader>

      <Content>
        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>

              <TitleGroup>
                {'Consultar Cartão POC'.toUpperCase()}
              </TitleGroup>

              {loading && (<LoadingsComponent />)}


              {permission && !error && (
                <Formik
                  enableReinitialize
                  validateOnMount
                  validationSchema={POCCartaoValidationSchema}
                  initialValues={POCCartaoModel}
                  onSubmit={(values) => {
                    postFetchCard(values);
                  }}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                  }) => (

                    <FilterContainerForm auto onSubmit={handleSubmit}>

                      <Row>
                        <Col>
                          <DropdownInput
                            disabled={loading}
                            name={'CC'}
                            label={'Centro de Custo'}
                            placeholder={'Selecione ou deixe em branco para todos.'}
                            dropArray={listBusinessDrop}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.CC}
                          />
                        </Col>
                      </Row>


                      <Row>


                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'text'}
                            name={'AREA'}
                            label={'Área'}
                            placeholder={'Área'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AREA.toLocaleUpperCase()}
                          />
                        </Col>

                      </Row>


                      <Row>

                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'text'}
                            name={'OBSERVADOR'}
                            label={'Observador'}
                            placeholder={'Observador'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.OBSERVADOR.toLocaleUpperCase()}
                          />
                        </Col>


                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'text'}
                            name={'MATRICULA'}
                            label={'Mátricula'}
                            placeholder={'Matrícula'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.MATRICULA}
                          />
                        </Col>

                      </Row>


                      <Row>

                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'date'}
                            name={'DTINI'}
                            label={'Data Início'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DTINI}
                          />
                        </Col>


                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'date'}
                            name={'DTFIM'}
                            label={'Data Fim'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DTFIM}
                          />
                        </Col>

                      </Row>

                      <Row>

                        <RowButtonFiltersView>

                          <IButton
                            disabled={listPocs.length === 0}
                            backgroundColor={Colors?.excel}
                            text={'Exportar'}
                            onClick={() => {
                              // propsTkProvider.csvProps.onExport();
                            }}
                          />


                          <IButton
                            text={'Limpar'}
                            backgroundColor={Colors?.gray}
                            type={'reset'}
                          />


                          <IButton
                            disabled={loading}
                            text={'Filtrar'}
                            backgroundColor={Colors?.primary}
                            type={'submit'}
                          />

                        </RowButtonFiltersView>

                      </Row>

                    </FilterContainerForm>

                  )}
                </Formik>
              )}


              {!permission && !error && (
                <EmptyContent
                  image={Images.error401}
                  title={'Sem permissão!'}
                  description={'Você não tem permissão para acessar essa tela.'}
                />
              )}


              {error && (
                <EmptyContent
                  image={Images.other500}
                  title={'Erro!'}
                  description={'Ocorreu um erro! Tente novamente, caso o problema persista, contate o suporte.'}
                />
              )}


              {!loading && showList && listPocs.length === 0 && (
                <EmptyContent
                  image={emptyObject.image}
                  title={emptyObject.title}
                  description={emptyObject.description}
                />
              )}



              {!loading && showList && listPocs.length > 0 && (

                <PaginationProvider
                  pagination={paginationFactory(options)}>
                  {({
                    paginationProps,
                    paginationTableProps,
                  }) => (
                    <TableCuston
                      noQuantity={listPocs.length < 10}
                      data={listPocs}
                      columns={columns}
                      // baseProps={propsTkProvider.baseProps}
                      paginationProps={paginationProps}
                      paginationTableProps={paginationTableProps}
                    />
                  )}
                </PaginationProvider>

              )}

            </SectionMargin>

          </ContainerView>

        </ContentStyled>
      </Content>


      {showModalReport && listReport && listReport.length > 0 && (
        <RelatorioPOCModal
          onHide={() => {
            setShowModalReport(false);
            setTimeout(() => {
              setListReport([]);
            }, 300);
          }}
          show={showModalReport}
          values={listReport}
        />
      )}

    </Screen>

  );
};

export default POCConsultaScreen;
