import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import {
  useSelector,
} from 'react-redux';
import { RDRecomendacao } from '../../../../../../common/arrays/RDAtendimento';

import {
  FilterContainerForm,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../services/api.service';

import { RootState } from '../../../../../../store';
import { currentDateToInput } from '../../../../../../utils/DateUtils';



const RDSectionRequisicao = ({ handleChange, handleBlur, values, setFieldValue }) => {
  const user = useSelector((state: RootState) => state.user.data);


  const [funcaoModalShow, setFuncaoModalShow] = React.useState(false);
  const [escolaridadeModalShow, setEscolaridadeModalShow] = React.useState(false);

  const [listOs, setListOs] = useState([]);


  const [descricaoOs, setDescricaoOs] = useState('');

  const [funcao, setFuncao] = useState({ codigo: '', descricao: '' });
  const [cargo, setCargo] = useState({ codigo: '', descricao: '' });
  const [escolaridade, setEscolaridade] = useState({ codigo: '', descricao: '' });



  function returnObjDropDownOs(listOs: any) {
    const newList = listOs.map((itemOs: any) => {
      const valueOs = itemOs.cco_ds_centro_custo !== '' || itemOs.cco_ds_centro_custo !== null || itemOs.cco_ds_centro_custo !== undefined ? itemOs.cco_ds_centro_custo : null;

      const groupOsMapped = {
        'key': itemOs.cco_vl_centro_custo,
        'label': itemOs.cco_vl_centro_custo.replaceAll(' ', ''),
        'value': valueOs.replaceAll(' ', ''),
      };
      return groupOsMapped;
    });

    setListOs([
      // emptyList,
      ...newList,
    ]);
  };


  const findOS = async (consulta: any) => {
    try {
      const url = '/rp/nova_rp/consulta/os/';
      const response = await ApiWS().post(url, consulta);

      returnObjDropDownOs(response.data.sort());
    }
    catch (error) {
      console.log('findOS', JSON.stringify(error.response, null, 2));
    }
  };



  useEffect(() => {
    findOS({ 'consulta': user.id });
    setFieldValue('solicitante', user.name);
    setFieldValue('data_abertura', currentDateToInput());
    setFieldValue('data_prev_atend', currentDateToInput(3));
  }, []);


  const fields = (values) => {
    let listField = [];

    listField = [
      { type: 'text', name: 'matricula', label: 'Matrícula', placeholder: 'Matrícula', xs: 3, value: values.matricula },
      { type: 'text', name: 'colaborador', label: 'Colaborador', placeholder: 'Colaborador', xs: 6, value: values.colaborador },
      { type: 'text', name: 'cargo', label: 'Cargo', placeholder: 'Cargo', xs: 3, value: values.cargo },

      { type: 'date', name: 'admissao', label: 'Data Admissão', placeholder: 'Data Admissão', xs: 3, value: values.admissao },
      { type: 'date', name: 'data_demissao', label: 'Data Demissão', placeholder: 'Data Demissão', xs: 3, value: values.data_demissao },
      { type: 'text', name: 'cc', label: 'Operação', placeholder: 'Operação', xs: 3, value: values.cc },
      { type: 'text', name: 'contrato', label: 'Contrato', placeholder: 'Contrato', xs: 3, value: values.contrato },

      { type: 'drop', name: 'recomendacao', label: 'Recomendação Líder Direto', xs: 6, options: RDRecomendacao, value: values.recomendacao },
    ];

    return listField;
  };

  return (

    <FilterContainerForm>
      <TitleGroup>
        {'Requisição de Demissão'.toUpperCase()}
      </TitleGroup>

      <Row>
        {fields(values).map((field) => (

          <Col xs={field?.xs}>

            {field.type === 'text' && (
              <TextInput
                editable={false}
                type={'text'}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                value={field.value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}


            {field.type === 'date' && (
              <TextInput
                editable={false}
                type={'date'}
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                value={field.value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}


            {field.type === 'drop' && (
              <DropdownInput
                name={field.name}
                label={field.label}
                placeholder={'Selecione ...'}
                dropArray={field.options}
                value={field.value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}

          </Col>
        ))}
      </Row>

    </FilterContainerForm>

  );
};



export default RDSectionRequisicao;
