import React,
{
  useState,
} from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
import DepartamentoCUDGIModal from '../../../_Modals/001DepartamentoCUDGIModal';
import { Colors, Images, Metrics } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import GIDepartamentoModel from './GI-Tab_Departamento.model';
import GIDepartamentoValidationSchema from './GI-Tab_Departamento.validation';


const Tab_DepartamentoScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listDepartment, setListDepartment] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem departamentos!',
    description: 'Não há nenhum departamento aqui',
  });

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesEdit, setValuesEdit] = useState({});
  const [valuesRemove, setValuesRemove] = useState({});

  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);



  function postFetchDepartment(values, page_iteration: any) {
    setLoading(true);
    setShowList(false);
    setListDepartment([]);
    setValuesPayload(values);

    const url = 'gi/departamento/consultar';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];



    ApiWS()
      .post(url, payload)
      .then((res) => {
        setListDepartment(res.data[0]);
        setTotalResults(res.data[1][0].TOTAL);
        setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        setEmptyObject({
          image: Images.other500,
          title: 'Erro',
          description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchDepartment', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetchDepartment(valuesPayload, page_var);
    setPage(page_var);
  };

  const options = {
    custom: true,
    totalSize: listDepartment.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: '',
      text: 'Detal./Editar',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) =>
        <ButtonTableAction
          tooltip={`${TextTooltip.DETAILS}/${TextTooltip.EDIT}`}
          onClick={() => {
            setShowModalEdit(true);
            setValuesEdit({ ID: row.ID, DESCR: row.DESCR });
          }}
          icon={
            <IconEdit color={Colors?.black} size={16} />
          }
        />,
    },

    {
      dataField: '',
      text: 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.REMOVE}
            onClick={() => {
              setShowModalRemove(true);
              setValuesRemove({ ID: row.ID, DESCR: row.DESCR });
            }}
            icon={
              <IconTrash color={Colors?.black} size={16} />
            }
          />
        );
      },
    },
  ];


  return (
    <ContentStyled fluid>

      <>
        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={GIDepartamentoValidationSchema}
          initialValues={GIDepartamentoModel}
          onSubmit={(values) => {
            postFetchDepartment(values, 1);
          }}>
          {({
            handleSubmit,
            handleBlur,
            handleChange,
          }) => (

            <>

              {loading && (
                <LoadingsComponent />
              )}



              <FilterContainerForm auto onSubmit={handleSubmit}>

                <TextInput
                  type={'text'}
                  name={'DESCR'}
                  label={'Departamento'}
                  placeholder={'Deixe em branco para todos.'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />


                <RowButtonFiltersView>

                  <IButton
                    disabled={loading}
                    type={'button'}
                    backgroundColor={Colors?.grayDark}
                    text={'Novo'}
                    onClick={() => {
                      setShowModalNew(true);
                    }}
                  />


                  <IButton
                    disabled={loading}
                    type={'submit'}
                    backgroundColor={Colors?.primary}
                    text={'Filtrar'}
                  />

                </RowButtonFiltersView>

              </FilterContainerForm>



            </>

          )}

        </Formik>



        {!loading && showList && listDepartment.length === 0 && (
          <EmptyContent
            image={emptyObject.image}
            title={emptyObject.title}
            description={emptyObject.description}
          />
        )}



        {!loading && showList && listDepartment.length > 0 && (

          <PaginationProvider
            pagination={paginationFactory(options)}>
            {({
              paginationProps,
              paginationTableProps,
            }) => (
              <TableCuston
                noQuantity={totalPage === 1}
                data={listDepartment}
                columns={columns}
                // baseProps={propsTkProvider.baseProps}
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
                actionPrevious={() => handlePagination(0)}
                actionNext={() => handlePagination(1)}
                currentPage={page}
                totalPage={totalPage}
                totalResults={totalResults}
              />
            )}
          </PaginationProvider>

        )}

      </>

      {showModalNew && (
        <DepartamentoCUDGIModal
          show={showModalNew}
          onHide={() => setShowModalNew(false)}
          title={'Novo Departamento'}
          type={'NEW'}
          fetchNow={() => postFetchDepartment({ DESCR: '' }, 1)}
        />
      )}


      {showModalEdit && (
        <DepartamentoCUDGIModal
          show={showModalEdit}
          onHide={() => setShowModalEdit(false)}
          title={'Editar Departamento'}
          type={'EDIT'}
          valuesEdit={valuesEdit}
          fetchNow={() => postFetchDepartment({ DESCR: '' }, 1)}
        />
      )}


      {showModalRemove && (
        <DepartamentoCUDGIModal
          show={showModalRemove}
          onHide={() => setShowModalRemove(false)}
          title={'Remover Departamento'}
          type={'REMOVE'}
          valuesRemove={valuesRemove}
          fetchNow={() => postFetchDepartment({ DESCR: '' }, 1)}
        />
      )}

    </ContentStyled>
  );
};



export default Tab_DepartamentoScreen;
