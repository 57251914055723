import React, {
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Formik,
} from 'formik';


import {
  Colors,
  Images,
} from '../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
} from '../../../../../common/styles/styled.layout';


import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';


import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';

import IImportacaoModel from './Importacao.model';


import { PEXMonths } from '../../../../../common/arrays/PEXImportacao';
import { IToast } from '../../../../../components/IToast/Toast';
import { PEX_NavRoutes } from '../../../../../navigation/navs/Corporate';
import { currentCompleteYear } from '../../../../../utils/DateUtils';
import PEXImportarCRModal from '../Modals/ImportarCRModal';
import PEXImportacaoValidationSchema from './Importacao.validation';



const Importacao_IndexScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(0);
  const [err, setErr] = useState(false);

  const [listDropCR, setListDropCR] = useState([]);

  const errObject = {
    image: Images.other500,
    title: 'Erro!',
    description: 'Atualize a página ou contate o suporte!',
  };

  const emptyObject = {
    image: Images.empty,
    title: 'Nenhuma consulta aqui!',
    description: 'Não há nenhuma consulta aqui',
  };

  const unauthorizedObject = {
    image: Images.error401,
    title: 'Sem autorização de acesso!',
    description: 'Não há nada aqui',
  };

  const [showModalImport, setShowModalImport] = useState(false);
  const [valuesImport, setValuesImport] = useState(null);

  const PEXDropTiposN1 = [
    { key: 0, label: 'Orçado', value: 'OR' },
    { key: 1, label: 'Orex', value: 'OE' },
    { key: 2, label: 'Previsto Mensal', value: 'PR' },
  ];

  const PEXDropTiposN2 = [
    { key: 0, label: 'Orçado', value: 'OR' },
    { key: 1, label: 'Orex', value: 'OE' },
    { key: 2, label: 'Previsto Mensal', value: 'PR' },
    { key: 3, label: 'Realizado Manual', value: 'RE' },
  ];

  const [valuesRetorno, setValuesRetorno] = useState([]);
  const [dataForm, setDataForm] = useState(null);


  function postDropCC() {
    setLoading(true);

    const url = '/pex/importar/cc';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((conta, index) => {
            setListDropCR((list) => [...list, {
              key: index,
              label: conta.TELA,
              value: conta.CC,
            }],
            );
          });
        };
      })

      .catch((error) => {
        console.error('postDropCC', JSON.stringify(error.response, null, 2));
        setErr(true);
        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Contabeis. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Contabeis. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
      });
  };

  function postPermission() {
    setLoading(true);

    const url = '/pex/importar/permissao';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          const retorno = resp.data;
          setAuthorized(retorno);

          if (retorno >= 1) {
            postDropCC();
          }
        }
        else if (resp.status === 203) {
          const retorno = resp.data;
          setAuthorized(retorno);

          if (retorno === 0) {
            setAuthorized(0);
            setLoading(false);
          }
        };
      })
      .catch((error) => {
        console.error('postPermission', JSON.stringify(error.response, null, 2));
        setErr(true);

        setLoading(false);
        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Operacionais. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Operacionais. Contate o suporte! ${error.response.status}`,
          });
        }
      });
  };

  function apenasMesCorrentePraFrente(anoSelecionado) {
    const mesAtual = new Date().getMonth();
    const anoAtual = new Date().getFullYear();
    let newPEXMonths = [];

    if (String(anoSelecionado) === String(anoAtual)) {
      PEXMonths.forEach((mes) => {
        if (mes.key >= mesAtual) {
          newPEXMonths.push(mes);
        }
      });
    }

    if (String(anoSelecionado) > String(anoAtual)) {
      newPEXMonths = PEXMonths;
    }
    return (newPEXMonths);
  };


  function postExport(values: any) {
    setLoading(true);

    const url = '/efetivo/home/exporta';



    const payload = {
      'OS': values.input_os || values.select_os,
      'TIPO': values.input_tipo,
      'FUNCAO': values.input_funcao,
      'MATRICULA': values.input_matricula,
      'NOME': values.input_funcionario,
      'SITUAC': values.input_situacao,
      'FERIAS': values.input_ferias,
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));
        setErr(true);
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function destacaLinha(row) {
    if (String(row.CONTA).split('.').length === 1) {
      return { width: '10%', fontWeight: 'bold', borderBottom: 'solid 1px gray' };
    }
    else {
      return { width: '10%' };
    };
  }

  function postEnviarImport() {
    setLoading(true);

    const url = '/pex/importar/salvar';

    ApiWS()
      .post(url, dataForm)
      .then((resp) => {
        if (resp.status === 200) {
          IToast({
            type: 'success',
            message: resp.data,
          });
        }
      })
      .catch((error) => {
        console.error('postEnviarImport', JSON.stringify(error.response, null, 2));
        setErr(true);

        setLoading(false);
        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao salvar importação. Tente novamente ou contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao salvar importação. Tente novamente ou contate o suporte! ${error.response.status}`,
          });
        }
      })
      .finally(() => setLoading(false));
  };



  const columns = [
    {
      dataField: 'CONTA',
      text: 'Conta Operacional',
      sort: false,
      headerStyle: (_cell, row) => {
        return { width: '10%' };
      },
      style: (_cell, row) => {
        return destacaLinha(row);
      },
      formatter: (_cell, row) => {
        if (String(row.CONTA) !== 'I' || String(row.CONTA).split('.')[0] !== 'I') {
          return (row.CONTA);
        };
      },
    },
    {
      dataField: 'DESCRICAO',
      text: 'Descr. Operacional',
      sort: false,
      headerStyle: () => {
        return { width: '25%' };
      },
      style: (_cell, row) => {
        return destacaLinha(row);
      },
      formatter: (_cell, row) => {
        if (String(row.CONTA) !== 'I' || String(row.CONTA).split('.')[0] !== 'I') {
          if (String(row.CONTA).split('.').length === 1) {
            return (`*    ${_cell || '-'}    *`);
          }
          else {
            return (_cell || '-');
          };
        }
      },
    },
    {
      dataField: 'VALOR',
      text: 'Conta Contábil',
      sort: false,
      headerStyle: () => {
        return { width: '10%' };
      },
      style: (_cell, row) => {
        return destacaLinha(row);
      },
    },
  ];


  const options = {
    custom: true,
    sizePerPage: valuesRetorno.length,
  };


  useEffect(() => {
    postPermission();
  }, []);



  return (

    <Screen>
      {/* {JSON.stringify(listData)} */}
      <NavHeader>

        <NavUser backHome />


        <NavComp
          navKey={2}
          navArray={PEX_NavRoutes}
          title={'PEX'}
        />

      </NavHeader>


      <Content>

        <ContentStyled fluid>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && err && (
            <EmptyContent
              image={errObject.image}
              title={errObject.title}
              description={errObject.description}
            />
          )}


          {!loading && !err && authorized === 0 && (
            <EmptyContent
              image={unauthorizedObject.image}
              title={unauthorizedObject.title}
              description={unauthorizedObject.description}
            />
          )}


          {!loading && authorized !== 0 && (

            <ContainerView>

              <SectionMargin>

                <SectionMargin>

                  <Formik
                    enableReinitialize
                    validateOnMount
                    validationSchema={PEXImportacaoValidationSchema}
                    initialValues={IImportacaoModel}
                    onSubmit={(values) => {
                      setShowModalImport(true);
                    }}>
                    {({
                      values,
                      resetForm,
                      isValid,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setValues,
                    }) => (

                      <FilterContainerForm auto onSubmit={handleSubmit}>

                        <Row>

                          <Col>
                            <DropdownInput
                              disabled={loading}
                              name={'TIPO'}
                              label={'Tipo'}
                              placeholder={'Selecione...'}
                              dropArray={authorized === 2 ? PEXDropTiposN2 : PEXDropTiposN1}
                              value={values.TIPO}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <TextInput
                              disabled={loading || values.TIPO === 'OR' || values.TIPO === 'RE' || values.TIPO === 'OE'}
                              type={'number'}
                              name={'ANO'}
                              label={'Ano'}
                              placeholder={'Ano'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.ANO} />
                          </Col>


                          <Col>
                            <DropdownInput
                              disabled={loading || values.TIPO === 'OR' || values.TIPO === 'RE' || values.TIPO === 'OE'}
                              name={'MES'}
                              label={'Mês'}
                              placeholder={'Selecione...'}
                              dropArray={apenasMesCorrentePraFrente(values.ANO)}
                              value={values.MES}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              disabled={loading || values.TIPO === 'RE'}
                              name={'CR'}
                              label={'CR'}
                              placeholder={'Selecione...'}
                              dropArray={listDropCR}
                              value={values.CR}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                        </Row>


                        <RowButtonFiltersView>

                          <IButton
                            disabled={loading}
                            text={'Limpar'}
                            backgroundColor={Colors?.gray}
                            type={'reset'}
                            onClick={() => {
                              resetForm();

                              setValues(IImportacaoModel);
                            }}
                          />


                          <IButton
                            disabled={loading || !isValid}
                            backgroundColor={Colors?.primary}
                            text={'Selecionar'}
                            onClick={() => {
                              setValuesImport(values);
                              setTimeout(() => {
                                setLoading(true);
                                setShowModalImport(true);
                              }, 300);
                            }}
                          />

                          <IButton
                            disabled={loading || valuesRetorno.length === 0}
                            backgroundColor={Colors?.primaryDark}
                            text={'Salvar'}
                            onClick={() => {
                              postEnviarImport();

                              setTimeout(() => {
                                window.location.reload();
                              }, 300);
                            }}
                          />

                        </RowButtonFiltersView>

                      </FilterContainerForm>

                    )}

                  </Formik>

                </SectionMargin>


                <ContainerView>


                  {!loading && valuesRetorno.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}


                  {!loading && valuesRetorno.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          noQuantity={true}
                          data={valuesRetorno}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                        />
                      )}
                    </PaginationProvider>
                  )}

                </ContainerView>


              </SectionMargin>

            </ContainerView>

          )}

        </ContentStyled>

      </Content>

      {showModalImport && valuesImport && (
        <PEXImportarCRModal
          show={showModalImport}
          onHide={() => {
            setShowModalImport(false);
            setLoading(false);
          }}
          values={valuesImport}
          retorno={(values) => setValuesRetorno(values)}
          dataForm={(values) => setDataForm(values)}
        />
      )}

    </Screen>

  );
};



export default Importacao_IndexScreen;
