import React,
{
  useEffect,
  useState,
} from 'react';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Colors,
  Images,
} from '../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  TableCheckBoxNextCss,
} from '../../../../../common/styles/styled.layout';


import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';


import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';


import { IToast } from '../../../../../components/IToast/Toast';
import { PEX_NavRoutes } from '../../../../../navigation/navs/Corporate';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import { IconApproval, IconList, IconTrash } from '../../../../../components/Icons';
import ButtonTableCheck from '../../../../../components/Buttons/ButtonTableCheck';
import PEXDetalhesModal from '../Modals/DetalhesModal';
import PEXHistAprovadoresModal from '../Modals/HistAprovadoresModal';



const Aprovacao_IndexScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(true);


  function postFethPEX(page_iteration: number) {
    setLoading(true);

    const url = '/pex/aprovacao/consulta';

    const payload = {
      'TAM': 10,
      'PAG': page_iteration,
    };

    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setListData(res.data[0]);
          setTotalResults(res.data[1][0].TOTAL);
          setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
        }
      })
      .catch((err) => {
        console.error('postFethPEX:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhuma consulta aqui!',
    description: 'Não há nenhuma consulta aqui',
  });

  const [listData, setListData] = useState([]);

  // const [listData, setListData] = useState([
  //   {
  //     NUM: '0001',
  //     CR: '1.0',
  //     TIPO: 'TESTE',
  //     REV: '00000',
  //     USUARIO: 'Teste',
  //     ANO: '2024',
  //     MES: 'Junho',
  //     DTCADASTRO: '10/06/2024',
  //     HORACADASTRO: '15:30',
  //   },
  //   {
  //     NUM: '0002',
  //     CR: '1.0',
  //     TIPO: 'TESTE',
  //     REV: '00000',
  //     USUARIO: 'Teste',
  //     ANO: '2024',
  //     MES: 'Janeiro',
  //     DTCADASTRO: '08/01/2024',
  //     HORACADASTRO: '07:30',
  //   },
  //   {
  //     NUM: '0003',
  //     CR: '1.0',
  //     TIPO: 'TESTE',
  //     REV: '00000',
  //     USUARIO: 'Teste',
  //     ANO: '2024',
  //     MES: 'Abril',
  //     DTCADASTRO: '17/04/2024',
  //     HORACADASTRO: '10:48',
  //   },
  // ]);

  const unauthorizedObject = {
    image: Images.error401,
    title: 'Sem autorização de acesso!',
    description: 'Não há nada aqui',
  };

  const [showListData, setShowListData] = useState(true);
  const [listaCarregando, setListaCarregando] = useState(false);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  const [showModalDetails, setShowModalDetails] = useState(false);
  const [valuesDetails, setValuesDetails] = useState(null);

  const [showModalApproval, setShowModalApproval] = useState(false);
  const [valuesApproval, setValuesApproval] = useState(null);


  const [listToApprove, setListToApprove] = useState([]);


  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFethPEX(page_var);
    setPage(page_var);
  };


  function postExport(values: any) {
    setLoading(true);

    const url = '/efetivo/home/exporta';



    const payload = {
      'OS': values.input_os || values.select_os,
      'TIPO': values.input_tipo,
      'FUNCAO': values.input_funcao,
      'MATRICULA': values.input_matricula,
      'NOME': values.input_funcionario,
      'SITUAC': values.input_situacao,
      'FERIAS': values.input_ferias,
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function postApproval() {
    setLoading(true);

    const url = '/pex/aprovacao/aprovar';

    const payload = listToApprove;

    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: 'Aprovação realizada com sucesso.',
          });

          postFethPEX(page);
        }
      })
      .catch((err) => {
        console.error('postApproval:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postRepproval() {
    setLoading(true);

    const url = '/pex/aprovacao/reprovar';

    const payload = listToApprove;

    ApiWS()
      .post(url, payload)
      .then((res) => {
        if (res.status === 200) {
          IToast({
            type: 'success',
            message: 'Reprovação realizada com sucesso.',
          });

          postFethPEX(page);
        }
      })
      .catch((err) => {
        console.error('postRepproval:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const addPCBMCart = (pex: any) => {
    setListToApprove((listParaAprovar) => [...listParaAprovar, pex]);
  };

  const removePCBMCart = (pex: any) => {
    const index = listToApprove.findIndex((x) => x.NUM === pex.NUM);
    const newList = [...listToApprove];
    newList.splice(index, 1);
    setListToApprove(newList);
  };


  const columns = [
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableCheckBoxNextCss;
      },
      formatter: (cell, row) => {
        return (
          <ButtonTableCheck
            tooltip={`
              ${row?.NUM} - ${row?.CR} |
              ${row?.MES}/${row?.ANO}
            `}
            onChange={(chk) => {
              const pex = {
                'NUM': row.NUM,
                'CR': row.CR,
                'TIPO': row.TIPO,
                'REV': row.REV,
                'USUARIO': row.USUARIO,
                'ANO': row.ANO,
                'MES': row.MES,
                'DTCADASTRO': row.DTCADASTRO,
                'HORACADASTRO': row.HRCADASTRO,
              };
              chk.target.checked ? addPCBMCart(pex) : removePCBMCart(pex);
            }}
          />
        );
      },
    },
    {
      dataField: 'NUM',
      text: 'Num.',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'CR',
      text: 'CR',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'TIPO',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'REV',
      text: 'Rev.',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'USUARIO',
      text: 'Usuário',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'ANO',
      text: 'Ano',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'MES',
      text: 'Mês',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'DTCADASTRO',
      text: 'Dt. Cadastro',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'HORACADASTRO',
      text: 'Hr. Cadastro',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: '',
      text: 'Detalhes',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.DETAILS}
            onClick={() => {
              setValuesDetails({ 'NUM': row.NUM });
              setTimeout(() => {
                setShowModalDetails(true);
              }, 300);
            }}
            icon={
              <IconList
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Aprovadores',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: (row, _cell) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.APPROVALS}
            onClick={() => {
              setValuesApproval({ 'NUM': row?.NUM });
              setTimeout(() => {
                setShowModalApproval(true);
              }, 300);
            }}
            icon={
              <IconApproval
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listData?.length,
  };

  useEffect(() => {
    postFethPEX(1);
  }, []);


  return (

    <Screen>
      {/* {JSON.stringify(listData)} */}
      <NavHeader>

        <NavUser backHome />


        <NavComp
          navKey={3}
          navArray={PEX_NavRoutes}
          title={'PEX'}
        />

      </NavHeader>


      <Content>

        <ContentStyled fluid>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && !authorized && (
            <EmptyContent
              image={unauthorizedObject.image}
              title={unauthorizedObject.title}
              description={unauthorizedObject.description}
            />
          )}


          {!loading && authorized && (

            <ContainerView>

              <SectionMargin>

                <SectionMargin>

                  <RowButtonFiltersView>


                    <IButton
                      disabled={loading || listaCarregando}
                      type={'button'}
                      text={'Aprovar'}
                      backgroundColor={Colors?.buttonOk}
                      onClick={() => {
                        postApproval();
                      }}
                    />



                    <IButton
                      disabled={loading || listaCarregando}
                      backgroundColor={Colors?.buttonCancel}
                      text={'Reprovar'}
                      onClick={() => {
                        postRepproval();
                      }}
                    />

                  </RowButtonFiltersView>

                </SectionMargin>


                <ContainerView>

                  {listaCarregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  {!loading && !listaCarregando && showListData && listData?.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}


                  {!loading && !listaCarregando && showListData && listData?.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          noQuantity={totalPage === 1}
                          data={listData}
                          columns={columns}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                          actionPrevious={() => handlePagination(0)}
                          actionNext={() => handlePagination(1)}
                          currentPage={page}
                          totalPage={totalPage}
                          totalResults={totalResults}
                        />
                      )}
                    </PaginationProvider>
                  )}

                </ContainerView>


              </SectionMargin>

            </ContainerView>

          )}

        </ContentStyled>

        {showModalDetails && valuesDetails && (
          <PEXDetalhesModal
            show={true}
            onHide={() => setShowModalDetails(false)}
            values={valuesDetails}
          />
        )}

        {showModalApproval && valuesApproval && (
          <PEXHistAprovadoresModal
            show={true}
            onHide={() => setShowModalApproval(false)}
            values={valuesApproval}
          />
        )}

      </Content>

    </Screen>

  );
};



export default Aprovacao_IndexScreen;
