import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
} from '../../common/constants';

import {
  Screen,
} from '../../common/styles/styled.layout';

import IButton from '../../components/Buttons/IButton';

import {
  IconCardId,
  IconHome, IconSystem,
} from '../../components/Icons';

import {
  ErrorScreenContainer,
  ErrorContent,
  ErrorTitle,
  ErrorDescriptionText,
  GoHomeButtonContent,
} from './styled';
import App from '../../App';


const LogoutScreen: React.FC = () => {
  const history = useHistory();



  return (

    <Screen
      backgroundColor={Colors?.primary}>

      <ErrorScreenContainer>
        <ErrorContent>

          <ErrorDescriptionText>
            {'Sessão encerrada'.toUpperCase()}
          </ErrorDescriptionText>

          <GoHomeButtonContent>
            <IButton
              backgroundColor={Colors?.primary}
              text={'Acessar'}
              iconLeft={
                <IconCardId
                  color={'#FFFFFF'}
                  size={24}
                />
              }
              onClick={() => {
                history.replace('/');
                sessionStorage.setItem('logado', '0');
                localStorage.clear();
                window.location.reload();
              }}
            />
          </GoHomeButtonContent>

        </ErrorContent>
      </ErrorScreenContainer>

    </Screen>

  );
};



export default LogoutScreen;
