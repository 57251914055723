import React,
{
  useState,
  useEffect,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
import AreaCUDModal from '../../../_Modals/003AreaCUDModal';
import { Colors, Images } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconEdit, IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import DropdownInput from '../../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import POCAreaModel from './POC-Tab_Area.model';
import POCAreaValidationSchema from './POC-Tab_Area.validation';


const Tab_CadastroAreaScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listCCDrop, setListCCDrop] = useState([]);


  const [listPocs, setListPocs] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem Áreas!',
    description: 'Não há nenhuma área aqui',
  });



  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesEdit, setValuesEdit] = useState({});
  const [valuesRemove, setValuesRemove] = useState({});


  function postCCDrop() {
    setLoading(true);
    setShowList(false);

    const url = '/poc/area/consulta/cc';

    ApiWS()
      .post(url)
      .then((res) => {
        res.data.forEach((cc, index) => {
          setListCCDrop((listCC) => [
            ...listCC,
            {
              key: index,
              label: cc.OS,
              value: cc.OS,
            },
          ]);
        });
      })
      .catch((err) => {
        console.error('postCCDrop:::', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  function postFetchArea(values: any) {
    setLoading(true);
    setShowList(false);

    const url = '/poc/area/consulta';

    ApiWS()
      .post(url, [values])
      .then((res) => {
        setListPocs(res.data);
      })
      .catch((err) => {
        console.error('postFetchArea:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  useEffect(() => {
    postCCDrop();
  }, [listPocs]);


  const options = {
    custom: true,
    totalSize: listPocs.length,
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'CC',
      text: 'Centro Custo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: 'DESCR',
      text: 'Área',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: '',
      text: 'Editar',
      csvExport: false,
      formatter: (cell, row, index): JSX.Element => {
        return (
          <ButtonTableAction
            key={index}
            tooltip={TextTooltip.EDIT}
            icon={
              <IconEdit
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              setValuesEdit(row);
              setTimeout(() => {
                setShowModalEdit(true);
              }, 300);
            }}
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Remover',
      csvExport: false,
      formatter: (cell, row, index): JSX.Element => {
        return (
          <ButtonTableAction
            key={index}
            tooltip={TextTooltip.REMOVE}
            icon={
              <IconTrash
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              setValuesRemove(row);
              setTimeout(() => {
                setShowModalRemove(true);
              }, 300);
            }}
          />
        );
      },
    },
  ];


  return (
    <ContentStyled fluid>

      <>
        {loading && (<LoadingsComponent />)}

        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={POCAreaValidationSchema}
          initialValues={POCAreaModel}
          onSubmit={(values) => {
            postFetchArea(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (

            <FilterContainerForm auto onSubmit={handleSubmit}>

              <Row>

                <Col>
                  <DropdownInput
                    disabled={loading}
                    name={'CC'}
                    label={'Centro de Custo'}
                    placeholder={'Selecione ou deixe em branco para todos.'}
                    dropArray={listCCDrop}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.CC}
                  />
                </Col>

              </Row>


              <Row>

                <Col>
                  <TextInput
                    disabled={loading}
                    type={'text'}
                    name={'DESCR'}
                    label={'Área'}
                    placeholder={'Área'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.DESCR.toLocaleUpperCase()}
                  />
                </Col>

              </Row>


              <Row>

                <RowButtonFiltersView>

                  <IButton
                    disabled={listPocs.length === 0}
                    backgroundColor={Colors?.excel}
                    text={'Exportar'}
                    onClick={() => {
                      // propsTkProvider.csvProps.onExport();
                    }}
                  />


                  <IButton
                    text={'Limpar'}
                    backgroundColor={Colors?.gray}
                    type={'reset'}
                  />


                  <IButton
                    backgroundColor={Colors?.grayDark}
                    text={'Novo'}
                    onClick={() => {
                      setShowModalNew(true);
                    }}
                  />


                  <IButton
                    disabled={loading}
                    text={'Filtrar'}
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                  />

                </RowButtonFiltersView>

              </Row>

            </FilterContainerForm>

          )}
        </Formik>



        {!loading && showList && listPocs.length === 0 && (
          <EmptyContent
            image={emptyObject.image}
            title={emptyObject.title}
            description={emptyObject.description}
          />
        )}



        {!loading && showList && listPocs.length > 0 && (

          <PaginationProvider
            pagination={paginationFactory(options)}>
            {({
              paginationProps,
              paginationTableProps,
            }) => (
              <TableCuston
                noQuantity={listPocs.length < 10}
                data={listPocs}
                columns={columns}
                // baseProps={propsTkProvider.baseProps}
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
              />
            )}
          </PaginationProvider>

        )}

      </>



      {showModalNew && (
        <AreaCUDModal
          show={showModalNew}
          onHide={() => setShowModalNew(false)}
          title={'Nova Área'}
          type={'NEW'}
          fetchNow={() => postFetchArea(POCAreaModel)}
        />
      )}


      {showModalEdit && (
        <AreaCUDModal
          show={showModalEdit}
          onHide={() => setShowModalEdit(false)}
          title={'Editar Área'}
          type={'EDIT'}
          valuesEdit={valuesEdit}
          fetchNow={() => postFetchArea(POCAreaModel)}
        />
      )}


      {showModalRemove && (
        <AreaCUDModal
          show={showModalRemove}
          onHide={() => setShowModalRemove(false)}
          title={'Remover Área'}
          type={'REMOVE'}
          valuesRemove={valuesRemove}
          fetchNow={() => postFetchArea(POCAreaModel)}
        />
      )}

    </ContentStyled>
  );
};



export default Tab_CadastroAreaScreen;
