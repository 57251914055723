import styled from 'styled-components';
import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Collapse,
  Modal,
} from 'react-bootstrap';



import { collapseToast } from 'react-toastify';
import Color from 'color';
import { tr } from 'date-fns/locale';
import { Backgrounds } from '../../../../../common/constants/Images';
import IButton from '../../../../../components/Buttons/IButton';

import{
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import { IDadosBloqueio } from '../SME_Aprovacao/index';


// Ajuste o caminho conforme necessário


interface IProps {
  show: boolean;
  data: any;
  onHide: () => void;
  dadosBloqueio: IDadosBloqueio[];
}


const TableStyled = styled.table`
  width: 100%;
  border-Collapse: collapse;
  margin-top: 10px;
  
  th, td {
    border: 1px solid #ffffff;
    padding: 8px;
    text-align: left;
  }
  th{
    background-color: rgb(0, 99, 198);
    color: rgb(255, 255, 255);
  }

  .blocked {
    background-color: #cccaca;
    color: #080808;
  }
`;

const BloqueioModal: React.FC<IProps> = ({ show, data, onHide, dadosBloqueio }) => {
  console.log('como que chega aq:', dadosBloqueio);
  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Body>
        <h3>Solicitação Bloqueada</h3>
          <TableStyled>
            <thead style={{ backgroundColor: '#0d6efd' }}>
              <tr>
                <th>Nº:</th>
                <th>SME:</th>
                <th>Conta:</th>
                <th>Previsto:</th>
                <th>Realizado:</th>
                <th>Solicitado:</th>
                <th>Saldo Atual:</th>
                <th>Novo Saldo:</th>
                <th>Consumo Atual:</th>
                <th>Novo Consumo:</th>
                <th>Situação</th>
              </tr>
            </thead>
            <tbody>
              {dadosBloqueio && dadosBloqueio.length > 0 ? (
                dadosBloqueio.map((item, index) => (
                  <tr key={item.NUM ? item.NUM.toString() : index} className={item.SIT.toUpperCase() === 'BLOQUEADO' ? 'blocked' : ''}>
                    <td>{index + 1}</td>
                    <td>{item.NUM}</td>
                    <td>{item.CONTA}</td>
                    <td>{item.PREVISTO}</td>
                    <td>{item.REALIZADO}</td>
                    <td>{item.SOLICITADO}</td>
                    <td>{item.SALDO_ATUAL}</td>
                    <td>{item.NOVO_SALDO}</td>
                    <td>{item.CONSUMO_ATUAL}</td>
                    <td>{item.NOVO_CONSUMO}</td>
                    <td>
                      <strong>{item.SIT}</strong>
                    </td>
                  </tr>
                ))                  
              ) : (
                <tr>
                  <td colSpan={11}>Nenhuma SME encontrada.</td>
                </tr>
            )}
            </tbody>
        </TableStyled>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onHide}>Fechar</button>
      </Modal.Footer>
    </Modal>
  );
};

export default BloqueioModal;
