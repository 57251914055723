import React,
{
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

// import {
//   useHistory,
// } from 'react-router-dom';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  SeparatorButton,
  TableActionNextCss,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import PStockFornecedorValidationSchema from '../../../../../../common/validations/Supplies/PSTOCK/pstock-fornecedor.validation';

import ButtonTableAction from '../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../../components/Empty';

import {
  // IconAdd,
  // IconEdit,
  IconEyeOpen,
  IconNot,
  IconRefresh,
  // IconTrash,
} from '../../../../../../components/Icons';

import LoadingsComponent from '../../../../../../components/Loadings';
import ConfirmationModal from '../../../../../../components/Modals/Confirmation';
import TableCuston from '../../../../../../components/TableOld';
import TextInput from '../../../../../../components/TextFields/Input';

import PStockFornecedorModel from '../../../../../../models/Supplies/PSTOCK/pstock-fornecedor.model';

// import NameRoutes from '../../../../../../navigation/name';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';

import {
  convertCNPJ,
} from '../../../../../../utils/ListUtils';

import PStockDetailSupplierModal from '../Modals/DetailSupplierModal';



const Tab_FornecedoresScreen: React.FC = () => {
  // const history = useHistory();


  const [showSupplierList, setShowSupplierList] = useState(false);
  const [listSupplier, setListSupplier] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem fornecedores!',
    description: 'Não há nenhum fornecedor aqui',
  });

  const [supplierDetails, setSupplierDetails] = useState(null);
  const [showModalDetails, setShowModalDetails] = useState(false);

  const [supplierDelete, setSupplierDelete] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [loadingSupplierList, setLoadingSupplierList] = useState(false);
  const [messageUpdateConfirmModal, setMessageUpdateConfirmModal] = useState(null);

  const [formValues, setFormValues] = useState(null);



  async function handleConsulta(values: typeof PStockFornecedorModel) {
    const payload = {
      'COD': values.input_cod,
      'RAZAO': values.input_razao,
      'NREDUZ': values.input_nreduz,
      'CNPJ': values.input_cnpj,
      'EST': values.input_est,
    };

    try {
      setLoadingSupplierList(true);
      setShowSupplierList(false);

      const url = '/pstock/adm/fornecedor/consulta';
      const response = await ApiWS().post(url, payload);

      setListSupplier(response.data);
    }
    catch (error) {
      console.error('handleConsulta', JSON.stringify(error.response, null, 2));
      setListSupplier([]);

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setLoadingSupplierList(false);
      setShowSupplierList(true);
    }
  };


  async function removeItem(item) {
    console.log('DELETAR', item);
  };


  async function postSincronizar() {
    const payload = {
      'CC': 'Todos'.toUpperCase(),
    };

    try {
      setLoadingSupplierList(true);

      const url = '/pstock/adm/fornecedor/sincronizar';
      const response = await ApiWS().post(url, payload);

      if (response.status === 200) {
        setShowUpdateConfirmModal(true);
        setMessageUpdateConfirmModal('Atualização de fornecedores concluída');
      }
      else {
        toast.error('Erro ao atualizar fornecedores! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
    catch (error) {
      console.error('postSincronizar', JSON.stringify(error.response, null, 2));
      setShowUpdateConfirmModal(true);
      setMessageUpdateConfirmModal('Houve um erro ao atualizar fornecedores, por favor entre em contato com o suporte.');
    }
    finally {
      setLoadingSupplierList(false);
      if (formValues) {
        handleConsulta(formValues);
      }
    }
  }



  const columns = [
    {
      dataField: 'COD',
      text: 'Cód.',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'FANTASIA',
      text: 'Nome Fantasia',
      sort: true,
    },
    {
      dataField: 'RAZAO',
      text: 'Razão Social',
      sort: true,
    },
    {
      dataField: 'CNPJ',
      text: 'CNPJ',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (cell) => {
        return convertCNPJ(cell) ? convertCNPJ(cell) : '--';
      },
    },
    {
      dataField: 'EST',
      text: 'Estado',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'BLOQ',
      text: 'Bloqueio',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell, row) => {
        // Botão Visualizar
        return row.BLOQ === 'S' ?
          <IconNot
            color={Colors?.quaternary}
            size={22}
          />
          : null;
      },
    },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Visualizar
        return (
          <ButtonTableAction
            key={'left'}
            placement={'left'}
            trigger={['hover', 'hover']}
            tooltip={'Visualizar'}
            backgroundColor={Colors?.primary}
            onClick={() => {
              setSupplierDetails(row);
              setShowModalDetails(true);
            }}
            icon={
              <IconEyeOpen
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    // {
    //   dataField: '',
    //   text: '',
    //   headerStyle: () => {
    //     return TableActionNextCss;
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Editar
    //     return (
    //       <OverlayTrigger
    //         key={'left'}
    //         placement={'left'}
    //         trigger={['hover', 'hover']}
    //         overlay={
    //           <TableToltip>
    //             Editar
    //           </TableToltip>
    //         }>
    //         <TableActionButton
    //           backgroundColor={Colors?.primary}
    //           onClick={() => {
    //             // TODO
    //             console.log(row);
    //           }}>
    //           <IconEdit
    //             color={Colors?.black}
    //             size={16}
    //           />
    //         </TableActionButton>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
    // {
    //   dataField: '',
    //   text: '',
    //   headerStyle: () => {
    //     return TableActionNextCss;
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Deletar
    //     return (
    //       <OverlayTrigger
    //         key={'left'}
    //         placement={'left'}
    //         trigger={['hover', 'hover']}
    //         overlay={
    //           <TableToltip>
    //             Deletar
    //           </TableToltip>
    //         }>
    //         <TableActionButton
    //           backgroundColor={Colors?.primary}
    //           onClick={() => {
    //             setSupplierDelete(row);
    //             setShowModalDelete(true);
    //           }}>
    //           <IconTrash
    //             color={Colors?.black}
    //             size={16}
    //           />
    //         </TableActionButton>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
  ];


  const options = {
    custom: true,
    totalSize: listSupplier.length,
  };



  return (

    <ContainerView>

      <SectionMargin>
        <TitleGroup>
          {'Consultar Fornecedores'.toUpperCase()}
        </TitleGroup>


        <SectionMargin>
          <Formik
            enableReinitialize
            validateOnMount
            validationSchema={PStockFornecedorValidationSchema}
            initialValues={PStockFornecedorModel}
            onSubmit={(values) => {
              setFormValues(values);
              handleConsulta(values);
            }}>
            {({
              values,
              resetForm,
              handleSubmit,
              handleChange,
              handleBlur,
              setValues,
            }) => (

              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>
                  <Col>
                    <TextInput
                      disabled={loadingSupplierList}
                      type={'text'}
                      name={'input_cod'}
                      label={'Cód.'}
                      placeholder={'Cód.'}
                      value={values.input_cod || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingSupplierList}
                      type={'text'}
                      name={'input_nreduz'}
                      label={'Nome Fantasia'}
                      placeholder={'Nome Fantasia'}
                      value={values.input_nreduz || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingSupplierList}
                      type={'text'}
                      name={'input_razao'}
                      label={'Razão Social'}
                      placeholder={'Razão Social'}
                      value={values.input_razao || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingSupplierList}
                      type={'text'}
                      name={'input_cnpj'}
                      label={'CNPJ'}
                      placeholder={'CNPJ'}
                      value={values.input_cnpj || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col>
                    <TextInput
                      disabled={loadingSupplierList}
                      type={'text'}
                      name={'input_est'}
                      label={'Estado'}
                      placeholder={'Estado'}
                      value={values.input_est || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>



                <RowButtonFiltersView>
                  <IButton
                    disabled={loadingSupplierList}
                    backgroundColor={Colors?.secondary}
                    text={'Sincronizar'}
                    iconLeft={
                      <IconRefresh
                        color={'#FFFFFF'}
                        size={20}
                      />
                    }
                    onClick={() => {
                      postSincronizar();
                    }}
                  />
                  <SeparatorButton />


                  {/* <ButtonMKS
                    disabled={loadingSupplierList}
                    backgroundColor={Colors?.excel}
                    text={'Adicionar'}
                    iconLeft={
                      <IconAdd
                        color={'#FFFFFF'}
                        size={24}
                      />
                    }
                    onClick={() => {
                      history.push(`/${NameRoutes.NovoFornecedorScreen}`);
                    }}
                  />
                  <SeparatorButton /> */}


                  <IButton
                    disabled={loadingSupplierList}
                    text={'Limpar'}
                    backgroundColor={Colors?.gray}
                    type={'reset'}
                    onClick={() => {
                      resetForm();

                      setValues(PStockFornecedorModel);
                    }}
                  />


                  <IButton
                    disabled={loadingSupplierList}
                    text={'Consultar'}
                    backgroundColor={Colors?.primary}
                    type={'submit'}
                  />
                </RowButtonFiltersView>

              </FilterContainerForm>

            )}
          </Formik>
        </SectionMargin>



        <ContainerView>

          {loadingSupplierList && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loadingSupplierList && showSupplierList && listSupplier.length === 0 && (
            <EmptyContent
              image={emptyObject.image}
              title={emptyObject.title}
              description={emptyObject.description}
            />
          )}



          {!loadingSupplierList && showSupplierList && listSupplier.length > 0 && (
            <PaginationProvider
              pagination={paginationFactory(options)}>
              {({
                paginationProps,
                paginationTableProps,
              }) => (
                <TableCuston
                  noQuantity={listSupplier.length < 10}
                  data={listSupplier}
                  columns={columns}
                  paginationProps={paginationProps}
                  paginationTableProps={paginationTableProps}
                />
              )}
            </PaginationProvider>
          )}

        </ContainerView>


      </SectionMargin>



      {supplierDetails && (
        <PStockDetailSupplierModal
          show={showModalDetails}
          data={supplierDetails}
          onHide={() => {
            setShowModalDetails(false);
            setTimeout(() => {
              setSupplierDetails(null);
            }, 300);
          }}
        />
      )}



      {supplierDelete && (
        <ConfirmationModal
          show={showModalDelete}
          head={'Deletar Fornecedor'}
          orient={'Tem certeza que deseja deletar o fornecedor ' + supplierDelete.Nome + '?'}
          positive={() => {
            removeItem(supplierDelete);
          }}
          negative={() => {
            setShowModalDelete(false);
            setTimeout(() => {
              setSupplierDelete(null);
            }, 300);
          }}
        />
      )}



      {messageUpdateConfirmModal && (
        <ConfirmationModal
          show={showUpdateConfirmModal}
          head={'PStock - Fornecedores!'}
          orient={messageUpdateConfirmModal}
          negative={() => {
            setShowUpdateConfirmModal(false);
            setTimeout(() => {
              setMessageUpdateConfirmModal(null);
            }, 300);
          }}
        />
      )}

    </ContainerView>

  );
};



export default Tab_FornecedoresScreen;
