import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Formik,
} from 'formik';

import {
  AllItems,
} from '../../../../common/arrays';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../common/constants';

import ExcelConfigs from '../../../../common/excel';
import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  Screen,
  SectionMargin,
  SeparatorButton,
  TitleGroup,
} from '../../../../common/styles/styled.layout';


import IButton from '../../../../components/Buttons/IButton';
import EmptyContent from '../../../../components/Empty';
import NavComp from '../../../../components/Headers/NavComp';
import NavHeader from '../../../../components/Headers/NavHeader';
import NavUser from '../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../components/Loadings';
import TableCuston from '../../../../components/Table';
import DropdownInput from '../../../../components/TextFields/Dropdown';
import TextInput from '../../../../components/TextFields/Input';


import SelectMulti from '../../../../components/TextFields/SelectMulti';
import ApiWS, { defaultBaseURL } from '../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../utils/ErrorUtils';
import ICencCentroCustoModel from './CENC.model';

import {
  RowButtonCentroCustoView,
} from './CENC.styled';
import ICencCentroCustoValidationSchema from './CENC.validation';
import { IToast } from '../../../../components/IToast/Toast';



const CENC_IndexScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [inputUnNeg, setInputUnNeg] = useState([]);
  const [listUnNeg, setListUnNeg] = useState([]);

  const [osLoading, setOsLoading] = useState(false);
  const [inputOs, setInputOs] = useState([]);
  const [listOs, setListOs] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [showLisCENC, setShowListCENC] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum item aqui!',
    description: 'Não há nada aqui',
  });

  const [listaCentroCusto, setListaCentroCusto] = useState([]);
  const [listaCarregando, setListaCarregando] = useState(false);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  async function handleCentroCustoFunction(values: typeof ICencCentroCustoModel, page_iteration: any) {
    setListaCarregando(true);
    setShowListCENC(false);
    setListaCentroCusto([]);
    setValuesPayload(values);
    setPage(1);

    const payload = {
      'SITUAC': values.select_situacao,
      'DESCR': values.input_descricao,
      'OS': values.input_os,
      'RRO': values.input_rro,
      'GESTOR': values.input_gestor,
      'UNEG': values.input_un_neg,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    };

    try {
      const url = '/controladoria/cc/consulta';
      const response = await ApiWS().post(url, payload);

      setListaCentroCusto(response.data[0]);
      setTotalResults(response.data[1][0].TOTAL);
      setTotalPage(Math.ceil(response.data[1][0].TOTAL / 10));
    }
    catch (error) {
      console.error('CentroCustoFunction', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
      setListaCarregando(false);
      setShowListCENC(true);
    }
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    handleCentroCustoFunction(valuesPayload, page_var);
    setPage(page_var);
  };


  function renderListOs(listOsResp: any) {
    const listOsMap = listOsResp.map((unit) => {
      const valueOs = unit.OS !== '' || unit.OS !== null || unit.OS !== undefined ? unit.OS : null;

      const OsMapped = {
        'key': unit.OS,
        'label': unit.OS,
        'value': `${valueOs}`,
      };
      return OsMapped;
    });

    setListOs([
      ...listOsMap,
    ]);
  };



  async function findOs(request: any) {
    const payload = {
      'NEGOC': String(request),
    };

    try {
      setOsLoading(true);

      const url = '/controladoria/cc/os';
      const response = await ApiWS().post(url, payload);

      renderListOs(response.data);
    }
    catch (error) {
      console.error('findOs', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setOsLoading(false);
    }
  };



  function renderListUnNeg(listOsResp: any) {
    const listOsMap = listOsResp.map((unit) => {
      const valueOs = unit.VALOR !== '' || unit.VALOR !== null || unit.VALOR !== undefined ? unit.VALOR : null;

      const OsMapped = {
        'key': unit.VALOR,
        'label': unit.NEGOC,
        'value': valueOs,
      };
      return OsMapped;
    });

    setListUnNeg([
      ...listOsMap,
    ]);
  };



  async function findUnNeg() {
    try {
      const url = '/controladoria/cc/negoc';
      const response = await ApiWS().post(url);

      return response.data;
    }
    catch (error) {
      console.error('findUnNeg', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
  };



  async function functionUnNegAndPermissionCG() {
    try {
      setCarregando(true);

      const [, unNegReturn] = await Promise.all([true, findUnNeg()]);

      renderListUnNeg(unNegReturn);
    }
    catch (error) {
      console.error('findUnNeg', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setCarregando(false);
    }
  };


  function postExport(values: any) {
    setCarregando(true);

    const url = '/controladoria/cc/exporta';

    const payload = {
      'SITUAC': values.select_situacao,
      'DESCR': values.input_descricao,
      'OS': values.input_os,
      'RRO': values.input_rro,
      'GESTOR': values.input_gestor,
      'UNEG': values.input_un_neg,
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setCarregando(false);
      });
  };


  useEffect(() => {
    functionUnNegAndPermissionCG();
  }, []);


  useEffect(() => {
    if (inputUnNeg.length === 0) {
      findOs(inputUnNeg);
    }
  }, [inputUnNeg]);


  useEffect(() => {
    setCarregando(true);
    setTimeout(() => {
      setCarregando(false);
    }, 300);
  }, []);


  const columns = [
    {
      dataField: 'CTT_CUSTO',
      text: 'Centro de custo',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      dataField: 'CTT_DESC01',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'NEGOCIO',
      text: 'Und. Neg.',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'CTT_ROP',
      text: 'RRO',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'CTT_RCR',
      text: 'Gestor',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'CTT_DTEXIS',
      text: 'Data Inicio',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'CTT_DTEXSF',
      text: 'Data Fim',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'CTT_BLOQ',
      text: 'Situação',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listaCentroCusto.length,
  };



  return (


    <Screen>

      <NavHeader>
        <NavUser backHome />


        <NavComp
          title={'Centro de Custos'}
        />
      </NavHeader>



      <Content>
        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>
              <TitleGroup>
                {'Centro de Custo'.toUpperCase()}
              </TitleGroup>


              {carregando || listaCarregando && (
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              )}

              <SectionMargin>
                <Formik
                  enableReinitialize
                  validateOnMount
                  validationSchema={ICencCentroCustoValidationSchema}
                  initialValues={ICencCentroCustoModel}
                  onSubmit={(values) => {
                    handleCentroCustoFunction(values, 1);
                  }}>
                  {({
                    values,
                    resetForm,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setValues,
                    setFieldValue,
                  }) => (

                    <FilterContainerForm auto onSubmit={handleSubmit}>

                      <Row>
                        <Col>
                          <DropdownInput
                            disabled={carregando || listaCarregando}
                            name={'select_situacao'}
                            label={'Situação'}
                            defaultValue={'Todos'.toLocaleUpperCase()}
                            dropArray={[
                              AllItems,
                              {
                                key: '1',
                                label: 'Ativa',
                                value: 'Ativa',
                              },
                              {
                                key: '2',
                                label: 'Encerrada',
                                value: 'Encerrada',
                              },
                            ]}
                            value={values.select_situacao || 'Todos'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />


                          <TextInput
                            disabled={carregando || listaCarregando}
                            type={'text'}
                            name={'input_descricao'}
                            label={'Descrição'}
                            placeholder={'Descrição'}
                            value={values.input_descricao || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>



                        <Col>
                          <SelectMulti
                            disabled={carregando || listaCarregando}
                            name={'input_un_neg'}
                            label={'Un. Negoc.'}
                            dropArray={listUnNeg}
                            placeholder={'Selecione um ou mais'}
                            value={inputUnNeg || []}
                            onNextChange={(optionValue) => {
                              setInputUnNeg(optionValue);

                              if (optionValue.length <= 0) {
                                setInputOs([]);
                                setFieldValue('input_os', []);
                              }
                            }}
                            onBlur={handleBlur}
                          />


                          <TextInput
                            disabled={carregando || listaCarregando}
                            type={'text'}
                            name={'input_rro'}
                            label={'RRO'}
                            placeholder={'RRO'}
                            value={values.input_rro || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>



                        <Col>
                          <SelectMulti
                            disabled={((values.input_un_neg.length <= 0 || listOs.length <= 0) && osLoading) || carregando || listaCarregando}
                            name={'input_os'}
                            label={'CC'}
                            dropArray={listOs}
                            placeholder={'Selecione um ou mais'}
                            value={inputOs}
                            onNextChange={(optionValue) => {
                              setInputOs(optionValue);
                            }}
                            onBlur={handleBlur}
                          />



                          <TextInput
                            disabled={carregando || listaCarregando}
                            type={'text'}
                            name={'input_gestor'}
                            label={'Gestor'}
                            placeholder={'Gestor'}
                            value={values.input_gestor || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </Row>



                      <RowButtonCentroCustoView>
                        {listaCentroCusto.length > 0 && (
                          <IButton
                            disabled={carregando || listaCarregando}
                            backgroundColor={Colors?.excel}
                            text={'Exportar'}
                            onClick={() => {
                              postExport(values);
                            }}
                          />
                        )}


                        {listaCentroCusto.length > 0 && <SeparatorButton />}


                        <IButton
                          disabled={carregando || listaCarregando}
                          text={'Limpar'}
                          backgroundColor={Colors?.gray}
                          type={'reset'}
                          onClick={() => {
                            resetForm();

                            setValues(ICencCentroCustoModel);
                          }}
                        />


                        <IButton
                          disabled={carregando || listaCarregando}
                          text={'Filtrar'}
                          backgroundColor={Colors?.primary}
                          type={'submit'}
                          onClick={() => {
                            console.log(values);
                          }}
                        />
                      </RowButtonCentroCustoView>

                    </FilterContainerForm>

                  )}
                </Formik>
              </SectionMargin>


              <ContainerView>

                {!carregando && !listaCarregando && showLisCENC && listaCentroCusto.length === 0 && (
                  <EmptyContent
                    image={emptyObject.image}
                    title={emptyObject.title}
                    description={emptyObject.description}
                  />
                )}



                {!carregando && !listaCarregando && showLisCENC && listaCentroCusto.length > 0 && (
                  <PaginationProvider
                    pagination={paginationFactory(options)}>
                    {({
                      paginationProps,
                      paginationTableProps,
                    }) => (
                      <TableCuston
                        noQuantity={totalPage === 1}
                        data={listaCentroCusto}
                        columns={columns}
                        // baseProps={propsTkProvider.baseProps}
                        paginationProps={paginationProps}
                        paginationTableProps={paginationTableProps}
                        actionPrevious={() => handlePagination(0)}
                        actionNext={() => handlePagination(1)}
                        currentPage={page}
                        totalPage={totalPage}
                        totalResults={totalResults}
                      />
                    )}
                  </PaginationProvider>
                )}

              </ContainerView>


            </SectionMargin>

          </ContainerView>

        </ContentStyled>
      </Content>

    </Screen>

  );
};



export default CENC_IndexScreen;
