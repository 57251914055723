import React,
{
  useState,
  useEffect,
} from 'react';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
import RPSituacao from '../../../../../common/arrays/RPAtendimento';
import { Colors, Images, Metrics } from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../common/excel';
import { ContainerView, Content, ContentStyled, FilterContainerForm, RowButtonFiltersView, Screen, SectionMargin, TableRotateNextCss, TableToltip, TitleGroup } from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconApproval, IconHistory, IconPrinter } from '../../../../../components/Icons';
import { IToast } from '../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';
import NameRoutes from '../../../../../navigation/name';
import { RP_NavRoutes } from '../../../../../navigation/navs/HumanResources';
import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';
import RPHistoricoModal from '../Modals/HistoricoRPModal';
import RpAtendimentoModel from './RP-atendimento.model';
import RPHistoricoAprovacaoModal from '../Modals/HistoricoAprovacoesRPModal';

const RP_AtendimentoScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [modalHistorico, setModalHistorico] = useState(false);
  const [valueHistorico, setValueHistorico] = useState({});


  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [permission, setPermission] = useState(false);
  const [error] = useState(false);


  const [listOSDrop, setListOSDrop] = useState([]);

  const [listRP, setListRP] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem RP\'s aqui!',
    description: 'Não há nenhuma RP para você',
  });


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);

  const [modalHistoricoAprovacao, setModalHistoricoAprovacao] = useState(false);
  const [valueHistoricoAprovacao, setValueHistoricoAprovacao] = useState({});


  function postOSDrop() {
    setLoading(true);
    setShowList(false);

    const url = '/rp/atendimento/consulta/os';

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((os, index) => {
            setListOSDrop((listOS) => [
              ...listOS,
              {
                key: index,
                label: os.OS,
                value: os.OS,
              },
            ]);
          });

          setPermission(true);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });


        setPermission(false);
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  function postFetch(values: any, page_iteration: any) {
    setLoading(true);
    setShowList(false);
    setValuesPayload(values);
    setPage(1);

    const url = '/rp/atendimento/consulta/rp';

    values = {
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_requestHuman,
    };

    ApiWS()
      .post(url, [values])
      .then((res) => {
        setListRP(res.data[0]);
        setTotalResults(res.data[1][0].TOTAL);
        setTotalPage(Math.ceil(res.data[1][0].TOTAL / 10));
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postFetch(valuesPayload, page_var);
    setPage(page_var);
  };

  function postExport(values: any) {
    setLoading(true);

    const url = '/rp/atendimento/consulta/exporta';

    ApiWS()
      .post(url, [values])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };

  function postPrinter(values: any) {
    setLoading(true);

    const url = `/rp/impressao/${values.QS_VAGA}`;

    ApiWS()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postPrinter:::', JSON.stringify(err));

        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    postOSDrop();
  }, []);


  const options = {
    custom: true,
    totalSize: listRP.length,
  };

  const columns = [
    {
      dataField: 'QS_CC',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      formatter: (cell, row) => {
        return (
          <OverlayTrigger
            key={'right'}
            placement={'right'}
            trigger={['hover', 'hover']}
            overlay={
              <TableToltip>
                {row.CCDESCR}
              </TableToltip>
            }>
            <span>{row.QS_CC}</span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'QS_VAGA',
      text: 'RP',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: 'QS_TPRP',
      text: 'Tipo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: 'SOLICITANTE',
      text: 'Solicitante',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'QS_DESCRIC',
      text: 'Função',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'QS_DESCARG',
      text: 'Cargo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => cell === null ? '' : cell,
    },
    {
      dataField: 'QS_NRVAGA',
      text: 'Vagas',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'QS_VAGAFEC',
      text: 'Vagas Fechadas',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'SALDO',
      text: 'Saldo',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_DTABERT1',
      text: 'Dt. Emiss.',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_DTNECES1',
      text: 'Dt. Neces.',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_DTPREVI1',
      text: 'Dt. Prev.',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'QS_STATUS',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: '',
      text: 'Hist.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.HISTORIC}
            onClick={() => {
              setModalHistorico(true);
              setValueHistorico(row);
            }}
            icon={
              <IconHistory
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Aprov.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (_cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.APPROVALS}
            onClick={() => {
              setModalHistoricoAprovacao(true);
              setValueHistoricoAprovacao({ CC: row.QS_CC, NUM: row.QS_VAGA });
            }}
            icon={
              <IconApproval
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Impr.',
      headerClasses: 'text-wrap-normal',
      headerStyle: () => {
        return TableRotateNextCss;
      },
      formatter: (_cell, row): JSX.Element => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.PRINTER}
            onClick={() => {
              postPrinter(row);
            }}
            icon={
              <IconPrinter
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },

  ];


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />

        <NavComp
          navKey={4}
          navArray={RP_NavRoutes}
          title={'RP'}
        />
      </NavHeader>

      <Content>
        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>

              <TitleGroup>
                {'Consultar RP'.toUpperCase()}
              </TitleGroup>


              {loading && (<LoadingsComponent />)}


              {!loading && permission && !error && (
                <Formik
                  enableReinitialize
                  validateOnMount
                  // validationSchema={RPp}
                  initialValues={RpAtendimentoModel}
                  onSubmit={(values) => {
                    postFetch(values, 1);
                  }}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                  }) => (

                    <FilterContainerForm auto onSubmit={handleSubmit}>

                      <Row>

                        <Col>
                          <DropdownInput
                            disabled={loading}
                            name={'OS'}
                            label={'CC'}
                            placeholder={'Todos'}
                            dropArray={listOSDrop}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.OS}
                          />
                        </Col>


                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'text'}
                            name={'NUM'}
                            label={'RP'}
                            placeholder={'RP'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.NUM}
                          />
                        </Col>

                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'text'}
                            name={'FUNCAO'}
                            label={'Função'}
                            placeholder={'FUNCAO'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.FUNCAO}
                          />
                        </Col>

                      </Row>


                      <Row>

                        <Col>
                          <DropdownInput
                            disabled={loading}
                            name={'SITUAC'}
                            label={'Situação'}
                            dropArray={RPSituacao}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.SITUAC}
                          />
                        </Col>


                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'date'}
                            name={'DTABERTINI'}
                            label={'Data de Abert. Inicial'}
                            placeholder={'Dt. Abert. Ini.'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DTABERTINI}
                          />
                        </Col>


                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'date'}
                            name={'DTABERTFIM'}
                            label={'Data de Abert. Final'}
                            placeholder={'Dt. Abert. Final'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DTABERTFIM}
                          />
                        </Col>

                      </Row>


                      <Row>

                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'date'}
                            name={'DTNECESINI'}
                            label={'Data de Neces. Inicial'}
                            placeholder={'Dt. Neces. Ini.'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DTNECESINI}
                          />
                        </Col>


                        <Col>
                          <TextInput
                            disabled={loading}
                            type={'date'}
                            name={'DTNECESFIM'}
                            label={'Data de Neces. Final'}
                            placeholder={'Dt. Neces. Final'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DTNECESFIM}
                          />
                        </Col>

                        <Col></Col>

                      </Row>

                      <RowButtonFiltersView>

                        <IButton
                          disabled={loading || listRP.length === 0}
                          backgroundColor={Colors?.excel}
                          text={'Exportar'}
                          onClick={() => {
                            postExport(values);
                          }}
                        />


                        <IButton
                          text={'Limpar'}
                          backgroundColor={Colors?.gray}
                          type={'reset'}
                        />


                        <IButton
                          disabled={loading || listOSDrop.length <= 0}
                          text={'Filtrar'}
                          backgroundColor={Colors?.primary}
                          type={'submit'}
                        />

                      </RowButtonFiltersView>

                    </FilterContainerForm>

                  )}
                </Formik>
              )}


              {!loading && !permission && !error && (
                <EmptyContent
                  image={Images.error401}
                  title={'Sem permissão!'}
                  description={'Você não tem permissão para acessar essa tela.'}
                />
              )}


              {!loading && error && (
                <EmptyContent
                  image={Images.other500}
                  title={'Erro!'}
                  description={'Ocorreu um erro! Tente novamente, caso o problema persista, contate o suporte.'}
                />
              )}


              {!loading && showList && permission && listRP.length === 0 && (
                <EmptyContent
                  image={emptyObject.image}
                  title={emptyObject.title}
                  description={emptyObject.description}
                />
              )}


              {!loading && showList && permission && listRP.length > 0 && (

                <PaginationProvider
                  pagination={paginationFactory(options)}>
                  {({
                    paginationProps,
                    paginationTableProps,
                  }) => (
                    <TableCuston
                      noQuantity={totalPage === 1}
                      data={listRP}
                      columns={columns}
                      // baseProps={propsTkProvider.baseProps}
                      paginationProps={paginationProps}
                      paginationTableProps={paginationTableProps}
                      actionPrevious={() => handlePagination(0)}
                      actionNext={() => handlePagination(1)}
                      currentPage={page}
                      totalPage={totalPage}
                      totalResults={totalResults}
                    />
                  )}
                </PaginationProvider>

              )}

            </SectionMargin>

          </ContainerView>

        </ContentStyled>

      </Content>


      {modalHistorico && (
        <RPHistoricoModal
          show={modalHistorico}
          todo={valueHistorico}
          onHide={() => {
            setModalHistorico(false);
            setTimeout(() => {
              setValueHistorico(null);
            }, 300);
          }}
        />
      )}


      {modalHistoricoAprovacao && (
        <RPHistoricoAprovacaoModal
          show={modalHistoricoAprovacao}
          value={valueHistoricoAprovacao}
          onHide={() => {
            setModalHistoricoAprovacao(false);
            setTimeout(() => {
              setValueHistoricoAprovacao(null);
            }, 300);
          }}
        />
      )}

    </Screen>

  );
};

export default RP_AtendimentoScreen;
