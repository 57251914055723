import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Stack,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Colors, Images, Metrics } from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../common/excel';
import { ContainerView, Content, ContentStyled, FilterContainerForm, RowButtonFiltersView, Screen, SectionMargin, TitleGroup } from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconCheck, IconEdit, IconNot, IconTrash } from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';

import { PIC_NavRoutes } from '../../../../../navigation/navs/SGI';
import ApiWS from '../../../../../services/api.service';
import { returnErrorObject } from '../../../../../utils/ErrorUtils';
import IPicCadastroAreaModel from './PIC-cadastro-area.model';
import { PICCadastroAreaValidationSchema, PICCadastroNovaAreaValidationSchema } from './PIC-cadastro-area.validation';

/**
 * PIC_CadastroAreaScreen
 * @type React.FC
 * @component
*/
const PIC_CadastroAreaScreen: React.FC = () => {
  const [listLoading, setListLoading] = useState(false);

  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
    orient2: '',
  });

  const [isNewRegister, setIsNewRegister] = useState(false);
  const [titleGroup, setTitleGroup] = useState('Áreas');

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem Área!',
    description: 'Não há nenhum registro aqui',
  });


  const [listOs, setListOs] = useState([]);
  const [listAreas, setListAreas] = useState([]);
  const [showList, setShowList] = useState(false);

  const [editRegister, setEditRegister] = useState(null);

  const [columnsState, setColumnsState] = useState(null);

  /**
 * Essa função serve para mudar de tela para o usuário perceber
 * alternança entre Novo e Consultar o registro.
 *
 * @function setListLoading(true) Exibe o ícone de carregamento
 * @function setTimeout() Aguarda um tempo
 * @function setListLoading(false) Retira o ícone de carregando
 * @function setShowList(false) Retira a lista de consulta que estiver na tela
 */
  const transitionNewOrFilter = () => {
    setListLoading(true);
    setTimeout(() => {
      setListLoading(false);
      setShowList(false);
    });
  };


  const postOsFetch = async () => {
    try {
      setListLoading(true);
      const url = '/pic/area/consulta/os/';
      const resp = await ApiWS().get(url);

      resp.data.map((resp: any, index: any) => (
        setListOs((listOs) => [...listOs, { key: index, label: resp.OS.trim(), value: resp.OS.trim() }])
      ));
    }
    catch (error) {
      console.error('fetchAll', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
    }
  };


  const postAreaFetch = async (values: any) => {
    try {
      setListLoading(true);
      const url = `/pic/area/consulta/area/${values.CC}`;
      const resp = await ApiWS().get(url);

      setListAreas(resp.data);
    }
    catch (error) {
      console.error('fetchAll', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
      setShowList(true);
    }
  };


  const postAreaNew = async (values: any) => {
    setListLoading(true);

    const url = '/pic/area/insere';

    await ApiWS()
      .post(url, values)

      .then((resp) => {
        resp.status === 200 &&
          toast.success('Registro salvo com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

        setIsNewRegister(false);

        return true;
      })

      .catch((err) => {
        toast.error('Ocorreu um erro ao criar registro! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);

        return false;
      })

      .finally(() => {
        postAreaFetch(values[0]);
        setListLoading(false);
      });

    console.log(values);
  };


  const postAreaEdit = async (values: any) => {
    setListLoading(true);

    const url = '/pic/area/altera';

    await ApiWS()
      .post(url, values)

      .then((resp) => {
        resp.status === 200 &&
          toast.success('Registro editado com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
      })

      .catch((err) => {
        toast.error('Ocorreu um erro ao editar registro! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      })

      .finally(() => {
        setListLoading(false);
        setShowList(true);
      });
  };


  const postAreaRemove = async (values: any) => {
    console.log(values);

    setListLoading(true);

    const url = '/pic/area/exclui';

    await ApiWS()
      .post(url, values)

      .then((resp) => {
        resp.status === 200 && resp.data !== 0 &&
          toast.success('Registro removido com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

        resp.status === 200 && resp.data === 0 &&
          toast.warning('Área não pode ser removida, pois já existem cartões atrelados.', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoCloseDouble,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

        transitionNewOrFilter();
      })

      .catch((err) => {
        toast.error('Ocorreu um erro ao remover registro! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      })

      .finally(() => {
        postAreaFetch({ CC: values[0].CC });
      });
  };


  const editInputTransform = async () => {
    setListLoading(true);
    await setColumnsState(columns);
    setListLoading(false);
  };


  const columns = [
    {
      dataField: 'ID',
      text: 'Area',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'DESCR',
      text: 'Descricao',
      sort: true,
      headerStyle: () => {
        return { width: '50%' };
      },
      csvFormatter: (cell): string => cell,
      formatter: (_cell, row, index): JSX.Element => {
        return row.ID === editRegister
          ? (
            <input
              key={index}
              type='text'
              defaultValue={row.DESCR}
              onChange={(e) => {
                row.DESCR = e.target.value;
              }}
              style={{
                border: '1px solid #ced4da',
                borderRadius: 5,
                height: 25,
                width: '100%',
                paddingLeft: 5,
              }}
            />
          )
          : (row.DESCR);
      },
    },
    {
      dataField: 'CC',
      text: 'Centro de Custo',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: '',
      text: 'Editar',
      csvExport: false,
      formatter: (_cell, row, index): JSX.Element => {
        return (
          row.ID === editRegister
            ? (
              <Stack direction={'horizontal'}>
                <ButtonTableAction
                  key={index}
                  tooltip={TextTooltip.CANCEL}
                  icon={
                    <IconNot
                      color={Colors?.black}
                      size={16}
                    />
                  }
                  onClick={() => {
                    setEditRegister(null);
                  }}
                />

                <ButtonTableAction
                  key={index}
                  tooltip={TextTooltip.SAVE}
                  icon={
                    <IconCheck
                      color={Colors?.black}
                      size={16}
                    />
                  }
                  onClick={async () => {
                    postAreaEdit(
                      new Array({
                        ...row,
                      }),
                    );
                    setEditRegister(null);
                  }}
                />
              </Stack>
            )


            : (
              <ButtonTableAction
                key={index}
                tooltip={TextTooltip.EDIT}
                icon={
                  <IconEdit
                    color={Colors?.black}
                    size={16}
                  />
                }
                onClick={() => {
                  setEditRegister(row.ID);
                }}
              />
            )
        );
      },
    },
    {
      dataField: '',
      text: 'Remover',
      csvExport: false,
      formatter: (_cell, row, index): JSX.Element => {
        return (
          <ButtonTableAction
            key={index}
            tooltip={TextTooltip.REMOVE}
            icon={
              <IconTrash
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              postAreaRemove(
                new Array({
                  ...row,
                }),
              );
            }}
          />
        );
      },
    },

  ];

  const options = {
    custom: true,
    totalSize: listAreas.length,
  };



  useEffect(() => {
    setColumnsState(columns);
    postOsFetch();
  }, []);


  useEffect(() => {
    editInputTransform();
  }, [editRegister]);

  return (

    <Screen>
      <NavHeader>
        <NavUser backHome />

        <NavComp
          navKey={1}
          itemKey={13}
          navArray={PIC_NavRoutes}
          title={'PIC'}
          subtitle={'Programa de Inspeção Comportamental'}
        />
      </NavHeader>

      <Content>
        <ContentStyled fluid>

          <ContainerView>

            <SectionMargin>
              <TitleGroup>
                {titleGroup.toUpperCase()}
              </TitleGroup>


              {listLoading &&
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              }


              <SectionMargin>
                <Formik
                  enableReinitialize
                  validateOnMount
                  validationSchema={
                    isNewRegister
                      ? PICCadastroNovaAreaValidationSchema
                      : PICCadastroAreaValidationSchema
                  }
                  initialValues={IPicCadastroAreaModel}
                  onSubmit={(values, { resetForm }) => {
                    !isNewRegister
                      ? postAreaFetch(values)

                      : postAreaNew(
                        new Array({ ...values }),
                      ) && resetForm();

                    setTimeout(() => {
                      setTitleGroup('Áreas');
                    }, 1001);
                  }}
                >
                  {({
                    isValid,
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => (

                    <FilterContainerForm auto onSubmit={handleSubmit}>

                      <Row>
                        <Col xs={8}>
                          <DropdownInput
                            disabled={listLoading}
                            name={'CC'}
                            label={'Centro de Custo'}
                            placeholder={'Selecione o Centro de Custo'}
                            dropArray={[...listOs]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.CC} />
                        </Col>
                      </Row>


                      {isNewRegister &&
                        <Row>
                          <Col xs={8}>
                            <TextInput
                              disabled={listLoading}
                              type={'text'}
                              name={'DESCR'}
                              label={'Área'}
                              placeholder={'Área'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.DESCR} />
                          </Col>
                        </Row>
                      }


                      <Row>

                        <RowButtonFiltersView>

                          {!isNewRegister && (
                            <IButton
                              disabled={listAreas.length === 0}
                              backgroundColor={Colors?.excel}
                              text={'Exportar'}
                              onClick={() => {
                                // propsTkProvider.csvProps.onExport();
                              }}
                            />
                          )}


                          {!isNewRegister && <>
                            <IButton
                              disabled={listLoading}
                              text={'Novo'}
                              backgroundColor={Colors?.gray}
                              onClick={() => {
                                setTimeout(() => {
                                  setFieldValue('area', '');
                                  setIsNewRegister(true);
                                  setShowList(false);
                                  setTitleGroup('Nova Area');
                                }, 1001);
                              }}
                            />


                            <IButton
                              disabled={listLoading}
                              text={'Filtrar'}
                              backgroundColor={Colors?.primary}
                              type={'submit'}
                              onClick={() => {
                                if (!isValid) {
                                  setMessageValue({
                                    head: 'Erro!',
                                    orient: 'Existem campos obrigatórios que não foram preenchidos.',
                                    orient2: 'Por favor revise o formulário!',
                                  });
                                  setMessageModal(true);
                                }
                              }}
                            />

                          </>}


                          {isNewRegister && <>
                            <IButton
                              text={'Cancelar'}
                              backgroundColor={Colors?.gray}
                              onClick={() => {
                                setTitleGroup('Areas');
                                setIsNewRegister(false);

                                setTimeout(() => {
                                  setFieldValue('area', '');
                                  setShowList(false);
                                }, 1001);
                              }}
                            />


                            <IButton
                              text={'Limpar'}
                              backgroundColor={Colors?.grayDark}
                              type={'reset'}
                            />


                            <IButton
                              disabled={listLoading}
                              text={'Salvar'}
                              backgroundColor={Colors?.primary}
                              type={'submit'}
                            />

                          </>}

                        </RowButtonFiltersView>

                      </Row>

                    </FilterContainerForm>

                  )}
                </Formik>
              </SectionMargin>



              <ContainerView>

                {listLoading && (
                  <LoadingsComponent
                    type={'SCREEN'}
                    color={Colors?.tertiary}
                  />
                )}

                {!listLoading && showList && listAreas.length === 0 && (
                  <EmptyContent
                    image={emptyObject.image}
                    title={emptyObject.title}
                    description={emptyObject.description}
                  />
                )}

                {!listLoading && showList && listAreas && listAreas.length > 0 && (
                  <PaginationProvider
                    pagination={paginationFactory(options)}>
                    {({
                      paginationProps,
                      paginationTableProps,
                    }) => (
                      <TableCuston
                        noQuantity={listAreas.length < 10}
                        data={listAreas}
                        columns={columnsState}
                        // baseProps={propsTkProvider.baseProps}
                        paginationProps={paginationProps}
                        paginationTableProps={paginationTableProps}
                      />
                    )}
                  </PaginationProvider>
                )}

              </ContainerView>



            </SectionMargin>

          </ContainerView>

        </ContentStyled>
      </Content>

      {
        messageModal && (
          <ConfirmationModal
            show={messageModal}
            head={messageValue.head}
            orient={messageValue.orient}
            orient2={messageValue.orient2}
            negative={() => {
              setMessageModal(false);
            }}
          />
        )
      }

    </Screen>

  );
};



export default PIC_CadastroAreaScreen;
