import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  OverlayTrigger,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

// import {
//   useHistory,
// } from 'react-router-dom';

import ReactToPrint from 'react-to-print';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
} from 'formik';

import {
  AllItems,
} from '../../../../../../common/arrays';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../../common/constants';

import {
  ContainerView,
  FilterContainerForm,
  RowButtonFiltersView,
  SectionMargin,
  SeparatorButton,
  // SeparatorButton,
  TableActionButton,
  TableActionNextCss,
  TableActionUpButton,
  TableToltip,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import PStockFuncionarioValidationSchema from '../../../../../../common/validations/Supplies/PSTOCK/pstock-funcionario.validation';

import IButton from '../../../../../../components/Buttons/IButton';

import EmptyContent from '../../../../../../components/Empty';

import {
  // IconAdd,
  // IconEdit,
  IconEyeOpen,
  // IconFingerprint,
  IconNot,
  IconQrCode,
  IconRefresh,
  // IconTrash,
} from '../../../../../../components/Icons';

import LoadingsComponent from '../../../../../../components/Loadings';
import ConfirmationModal from '../../../../../../components/Modals/Confirmation';
import QrCodeComponent from '../../../../../../components/QrCode';
import TableCuston from '../../../../../../components/TableOld';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../../components/TextFields/Input';

import PStockFuncionarioModel from '../../../../../../models/Supplies/PSTOCK/pstock-funcionario.model';

// import NameRoutes from '../../../../../../navigation/name';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';

import {
  convertCPF,
} from '../../../../../../utils/ListUtils';

import PStockEmployeeDetailModal from '../Modals/DetailEmployeeModal';



const Tab_FuncionariosScreen: React.FC = () => {
  // const history = useHistory();
  const compRef = useRef([]);
  const buttonRef = useRef(null);


  const [loading, setLoading] = useState(true);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem funcionários!',
    description: 'Não há nenhum funcionário aqui',
  });

  const [listCC, setListCC] = useState([]);

  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [listEmployee, setListEmployee] = useState([]);

  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [showModalDetails, setShowModalDetails] = useState(false);

  const [employeeDelete, setEmployeeDelete] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [loadingEmployeeList, setLoadingEmployeeList] = useState(false);
  const [messageUpdateConfirmModal, setMessageUpdateConfirmModal] = useState(null);

  const [formValues, setFormValues] = useState(null);



  function renderListByCC(listGrpResp: any) {
    const listGrpCcMap = listGrpResp.map((item) => {
      const valueCC = item.CC !== '' || item.CC !== null || item.CC !== undefined ? item.CC : null;

      const CcMapped = {
        'key': item.CC,
        'label': item.CC,
        'value': valueCC,
      };
      return CcMapped;
    });

    setListCC([
      AllItems,
      ...listGrpCcMap,
    ]);
  };


  async function removeItem(item) {
    console.log('DELETAR', item);
  }


  async function getCcByUser() {
    try {
      setLoading(true);

      const url = '/pstock/adm/funcionario/consulta/os';
      const response = await ApiWS().get(url);

      renderListByCC(response.data);
    }
    catch (error) {
      console.error('getCcByUser', JSON.stringify(error.response, null, 2));
      if (error.response) {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setLoading(false);
    }
  };


  async function handleConsulta(values: typeof PStockFuncionarioModel) {
    const payload = {
      'MATRICULA': values.input_matricula,
      'NOME': values.input_nome,
      'CPF': values.input_cpf,
      'CC': values.input_cc.trim(),
    };

    try {
      setLoadingEmployeeList(true);
      setShowEmployeeList(false);

      const url = '/pstock/adm/funcionario/consulta';
      const response = await ApiWS().post(url, payload);

      setListEmployee(response.data);
    }
    catch (error) {
      console.error('handleConsulta', JSON.stringify(error.response, null, 2));
      setListEmployee([]);

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setLoadingEmployeeList(false);
      setShowEmployeeList(true);
    }
  };


  async function postSincronizar(valueCC) {
    const payload = {
      'CC': valueCC.trim(),
    };

    try {
      setLoadingEmployeeList(true);

      const url = '/pstock/adm/funcionario/sincronizar';
      const response = await ApiWS().post(url, payload);

      if (response.status === 200) {
        setShowUpdateConfirmModal(true);
        setMessageUpdateConfirmModal('Atualização de funcionários concluída');
      }
      else {
        toast.error('Erro ao atualizar funcionários! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
    catch (error) {
      console.error('postSincronizar', JSON.stringify(error.response, null, 2));
      setShowUpdateConfirmModal(true);
      setMessageUpdateConfirmModal('Houve um erro ao atualizar funcionários, por favor entre em contato com o suporte.');
    }
    finally {
      setLoadingEmployeeList(false);
      if (formValues) {
        handleConsulta(formValues);
      }
    }
  }



  useEffect(() => {
    getCcByUser();
  }, []);



  const columns = [
    {
      dataField: 'CC',
      text: 'CC',
      sort: true,
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: 'MATRICULA',
      text: 'Matrícula',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
    },
    {
      dataField: 'NOME',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'CPF',
      text: 'CPF',
      sort: true,
      formatter: (cell) => {
        return convertCPF(cell);
      },
    },
    {
      dataField: 'FUNCAO',
      text: 'Função',
      sort: true,
    },
    {
      dataField: 'BLOQ',
      text: 'Bloqueio',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      formatter: (_cell, row) => {
        // Botão Visualizar
        return row.BLOQ === 'S' ?
          <IconNot
            color={Colors?.quaternary}
            size={22}
          />
          : null;
      },
    },
    // {
    //   dataField: 'DIGITAL',
    //   text: 'Biomentria',
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: '5%' };
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Visualizar
    //     return row.DIGITAL === 'S' ?
    //       <IconFingerprint
    //         color={Colors?.quaternary}
    //         size={22}
    //       />
    //       : <IconFingerprint
    //         color={Colors?.gray}
    //         size={22}
    //       />;
    //   },
    // },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell, row) => {
        // Botão Visualizar
        return (
          <OverlayTrigger
            key={'left'}
            placement={'left'}
            trigger={['hover', 'hover']}
            overlay={
              <TableToltip>
                Visualizar
              </TableToltip>
            }>
            <TableActionButton
              backgroundColor={Colors?.primary}
              onClick={() => {
                setEmployeeDetails(row);
                setShowModalDetails(true);
              }}>
              <IconEyeOpen
                color={Colors?.black}
                size={16}
              />
            </TableActionButton>
          </OverlayTrigger>
        );
      },
    },
    // {
    //   dataField: '',
    //   text: '',
    //   headerStyle: () => {
    //     return TableActionNextCss;
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Biometria
    //     return (
    //       <OverlayTrigger
    //         key={'left'}
    //         placement={'left'}
    //         trigger={['hover', 'hover']}
    //         overlay={
    //           <TableToltip>
    //             Biometria
    //           </TableToltip>
    //         }>
    //         <TableActionButton
    //           backgroundColor={Colors?.primary}
    //           onClick={() => {
    //             // TODO
    //             console.log(row);
    //           }}>
    //           <IconFingerprint
    //             color={Colors?.black}
    //             size={16}
    //           />
    //         </TableActionButton>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
    {
      dataField: '',
      text: '',
      headerStyle: () => {
        return TableActionNextCss;
      },
      formatter: (_cell: any, row: any, rowIndex: any) => {
        // Botão QR Code
        return (
          <>
            <ReactToPrint
              content={() => (compRef as any).current[rowIndex]}
              trigger={() => (
                <TableActionUpButton>
                  <OverlayTrigger
                    key={'left'}
                    placement={'left'}
                    trigger={['hover', 'hover']}
                    overlay={
                      <TableToltip>
                        QR Code
                      </TableToltip>
                    }>
                    <TableActionButton
                      backgroundColor={Colors?.primary}>
                      <IconQrCode
                        color={Colors?.black}
                        size={16}
                      />
                    </TableActionButton>
                  </OverlayTrigger>
                </TableActionUpButton>
              )}
            />

            <QrCodeComponent
              ref={(element) => (compRef.current[rowIndex] = element)}
              type={'FUNCIONARIO'}
              list={[{
                MATRICULA: row.MATRICULA,
                NOME: row.NOME,
                QRCODE: row.QRCODE,
              }]}
            />
          </>
        );
      },
    },
    // {
    //   dataField: '',
    //   text: '',
    //   headerStyle: () => {
    //     return TableActionNextCss;
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Editar
    //     return (
    //       <OverlayTrigger
    //         key={'left'}
    //         placement={'left'}
    //         trigger={['hover', 'hover']}
    //         overlay={
    //           <TableToltip>
    //             Editar
    //           </TableToltip>
    //         }>
    //         <TableActionButton
    //           backgroundColor={Colors?.primary}
    //           onClick={() => {
    //             // TODO
    //             console.log(row);
    //           }}>
    //           <IconEdit
    //             color={Colors?.black}
    //             size={16}
    //           />
    //         </TableActionButton>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
    // {
    //   dataField: '',
    //   text: '',
    //   headerStyle: () => {
    //     return TableActionNextCss;
    //   },
    //   formatter: (_cell, row) => {
    //     // Botão Deletar
    //     return (
    //       <OverlayTrigger
    //         key={'left'}
    //         placement={'left'}
    //         trigger={['hover', 'hover']}
    //         overlay={
    //           <TableToltip>
    //             Deletar
    //           </TableToltip>
    //         }>
    //         <TableActionButton
    //           backgroundColor={Colors?.primary}
    //           onClick={() => {
    //             setEmployeeDelete(row);
    //             setShowModalDelete(true);
    //           }}>
    //           <IconTrash
    //             color={Colors?.black}
    //             size={16}
    //           />
    //         </TableActionButton>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
  ];


  const options = {
    custom: true,
    totalSize: listEmployee.length,
  };



  return (

    <ContainerView>

      {loading && (
        <LoadingsComponent
          type={'SCREEN'}
          color={Colors?.tertiary}
        />
      )}



      {!loading && (
        <ContainerView>

          <SectionMargin>
            <TitleGroup>
              {'Consultar Funcionários'.toUpperCase()}
            </TitleGroup>


            <SectionMargin>
              <Formik
                enableReinitialize
                validateOnMount
                initialValues={PStockFuncionarioModel}
                validationSchema={PStockFuncionarioValidationSchema}
                onSubmit={(values) => {
                  setFormValues(values);
                  handleConsulta(values);
                }}>
                {({
                  values,
                  resetForm,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setValues,
                }) => (

                  <FilterContainerForm auto onSubmit={handleSubmit}>

                    <Row>
                      <Col>
                        <TextInput
                          disabled={loadingEmployeeList}
                          type={'text'}
                          name={'input_matricula'}
                          label={'Matrícula'}
                          placeholder={'Matrícula'}
                          value={values.input_matricula || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <TextInput
                          disabled={loadingEmployeeList}
                          type={'text'}
                          name={'input_nome'}
                          label={'Nome'}
                          placeholder={'Nome'}
                          value={values.input_nome || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <TextInput
                          disabled={loadingEmployeeList}
                          type={'text'}
                          name={'input_cpf'}
                          label={'CPF'}
                          placeholder={'CPF'}
                          value={values.input_cpf || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>


                      <Col>
                        <DropdownInput
                          disabled={loadingEmployeeList}
                          name={'input_cc'}
                          label={'CC'}
                          defaultValue={'Todos'.toLocaleUpperCase()}
                          dropArray={listCC}
                          value={values.input_cc || 'Todos'.toLocaleUpperCase()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>



                    <RowButtonFiltersView>
                      {!loadingEmployeeList && showEmployeeList && listEmployee.length > 0 && (
                        <>
                          <ReactToPrint
                            content={() => (buttonRef as any).current}
                            trigger={() => (
                              <IButton
                                disabled={loadingEmployeeList}
                                backgroundColor={Colors?.tertiary}
                                text={'Todos os QR da busca'}
                                iconLeft={
                                  <IconQrCode
                                    color={'#FFFFFF'}
                                    size={20}
                                  />
                                }
                              />
                            )}
                          />

                          <QrCodeComponent
                            ref={buttonRef}
                            type={'FUNCIONARIO'}
                            list={listEmployee}
                          />
                        </>
                      )}
                      <SeparatorButton />


                      <IButton
                        disabled={loadingEmployeeList}
                        backgroundColor={Colors?.secondary}
                        text={'Sincronizar'}
                        iconLeft={
                          <IconRefresh
                            color={'#FFFFFF'}
                            size={20}
                          />
                        }
                        onClick={() => {
                          postSincronizar(values.input_cc);
                        }}
                      />
                      <SeparatorButton />


                      {/* <ButtonMKS
                    disabled={loadingEmployeeList}
                    backgroundColor={Colors?.excel}
                    text={'Adicionar'}
                    iconLeft={
                      <IconAdd
                        color={'#FFFFFF'}
                        size={24}
                      />
                    }
                    onClick={() => {
                      history.push(`/${NameRoutes.NovoFuncionarioScreen}`);
                    }}
                  />
                  <SeparatorButton /> */}


                      <IButton
                        disabled={loadingEmployeeList}
                        text={'Limpar'}
                        backgroundColor={Colors?.gray}
                        type={'reset'}
                        onClick={() => {
                          resetForm();

                          setValues(PStockFuncionarioModel);
                        }}
                      />


                      <IButton
                        disabled={loadingEmployeeList}
                        text={'Consultar'}
                        backgroundColor={Colors?.primary}
                        type={'submit'}
                      />
                    </RowButtonFiltersView>

                  </FilterContainerForm>

                )}
              </Formik>
            </SectionMargin>



            <ContainerView>

              {loadingEmployeeList && (
                <LoadingsComponent
                  type={'SCREEN'}
                  color={Colors?.tertiary}
                />
              )}


              {!loadingEmployeeList && showEmployeeList && listEmployee.length === 0 && (
                <EmptyContent
                  image={emptyObject.image}
                  title={emptyObject.title}
                  description={emptyObject.description}
                />
              )}



              {!loadingEmployeeList && showEmployeeList && listEmployee.length > 0 && (
                <PaginationProvider
                  pagination={paginationFactory(options)}>
                  {({
                    paginationProps,
                    paginationTableProps,
                  }) => (
                    <TableCuston
                      noQuantity={listEmployee.length < 10}
                      data={listEmployee}
                      columns={columns}
                      paginationProps={paginationProps}
                      paginationTableProps={paginationTableProps}
                    />
                  )}
                </PaginationProvider>
              )}

            </ContainerView>


          </SectionMargin>



          {employeeDetails && (
            <PStockEmployeeDetailModal
              show={showModalDetails}
              data={employeeDetails}
              onHide={() => {
                setShowModalDetails(false);
                setTimeout(() => {
                  setEmployeeDetails(null);
                }, 300);
              }}
            />
          )}



          {employeeDelete && (
            <ConfirmationModal
              show={showModalDelete}
              head={'Deletar Funcionário'}
              orient={'Tem certeza que deseja deletar o perfil de ' + employeeDelete.Nome + '?'}
              positive={() => {
                removeItem(employeeDelete);
              }}
              negative={() => {
                setShowModalDelete(false);
                setTimeout(() => {
                  setEmployeeDelete(null);
                }, 300);
              }}
            />
          )}

        </ContainerView>
      )}



      {messageUpdateConfirmModal && (
        <ConfirmationModal
          show={showUpdateConfirmModal}
          head={'PStock - Funcionários!'}
          orient={messageUpdateConfirmModal}
          negative={() => {
            setShowUpdateConfirmModal(false);
            setTimeout(() => {
              setMessageUpdateConfirmModal(null);
            }, 300);
          }}
        />
      )}

    </ContainerView>

  );
};



export default Tab_FuncionariosScreen;
