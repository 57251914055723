import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Stack,
} from 'react-bootstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Colors, Images, Metrics } from '../../../../../common/constants';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../common/excel';
import { ContainerView, Content, ContentStyled, FilterContainerForm, RowButtonFiltersView, Screen, SectionMargin, TitleGroup } from '../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import { IconCheck, IconEdit, IconNot, IconTrash } from '../../../../../components/Icons';
import LoadingsComponent from '../../../../../components/Loadings';
import ConfirmationModal from '../../../../../components/Modals/Confirmation';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';

import { PIC_NavRoutes } from '../../../../../navigation/navs/SGI';
import ApiWS from '../../../../../services/api.service';
import { returnErrorObject } from '../../../../../utils/ErrorUtils';
import IPicCadastroEquipeModel from './PIC-cadastro-equipe.model';
import { PICCadastroEquipeValidationSchema, PICCadastroNovaEquipeValidationSchema } from './PIC-cadastro-equipe.validation';



const PIC_CadastroEquipeScreen: React.FC = () => {
  const [listLoading, setListLoading] = useState(false);
  const [carregandoForm, setCarregandoForm] = useState(false);

  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState({
    head: '',
    orient: '',
    orient2: '',
  });

  const [isNewRegister, setIsNewRegister] = useState(false);
  const [titleGroup, setTitleGroup] = useState('Equipes');

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem Equipe!',
    description: 'Não há nenhum registro aqui',
  });


  const [listOs, setListOs] = useState([]);
  const [listTeams, setListTeams] = useState([]);
  const [showList, setShowList] = useState(false);

  const [editRegister, setEditRegister] = useState(null);

  const [columnsState, setColumnsState] = useState(null);


  // --------------------- AREA FUNCTIONS FETCH DATA ----------------------

  async function postOsFetch() {
    setListLoading(true);
    try {
      const url = '/pic/equipe/consulta/os/';
      const resp = await ApiWS().get(url);

      resp.data.map((resp: any, index: any) => (
        setListOs((listOs) => [...listOs, { key: index, label: resp.OS.trim(), value: resp.OS.trim() }])
      ));
    }
    catch (error) {
      console.error('getOsFetch', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
    }
  }


  async function postTeamFetch(values: any) {
    try {
      setListLoading(true);
      const url = `/pic/equipe/consulta/equipe/${values.CC}`;
      const resp = await ApiWS().get(url);

      setListTeams(resp.data);
    }
    catch (error) {
      console.error('fetchAll', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        setEmptyObject({
          image: Images.other500,
          title: 'Erro!',
          description: 'Sem conexão com o servidor',
        });
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
      setShowList(true);
    }
  }


  async function postTeamNew(values: any) {
    setListLoading(true);

    const url = '/pic/equipe/insere';

    await ApiWS()
      .post(url, values)

      .then((resp) => {
        resp.status === 200 &&
          toast.success('Registro salvo com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

        setIsNewRegister(false);
        setTitleGroup('Equipes'.toUpperCase());

        return true;
      })

      .catch((err) => {
        toast.error('Ocorreu um erro ao criar registro! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);

        return false;
      })

      .finally(() => {
        postTeamFetch(values[0]);
      });
  }


  async function postTeamEdit(values: any) {
    setListLoading(true);

    const url = '/pic/equipe/altera';

    await ApiWS()
      .post(url, values)

      .then((resp) => {
        resp.status === 200 &&
          toast.success('Registro editado com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
      })

      .catch((err) => {
        toast.error('Ocorreu um erro ao editar registro! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      })

      .finally(() => {
        setListLoading(false);
        setShowList(true);
      });
  }


  async function postTeamRemove(values: any) {
    setListLoading(true);
    setShowList(false);


    const url = '/pic/equipe/exclui';

    await ApiWS()
      .post(url, values)

      .then((resp) => {
        resp.status === 200 && resp.data !== 0 &&
          toast.success('Registro removido com sucesso!', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });


        resp.status === 200 && resp.data === 0 &&
          toast.warning('Equipe não pode ser removida, pois já existem cartões atrelados.', {
            position: 'bottom-center',
            autoClose: Metrics.messageAutoCloseDouble,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
      })

      .catch((err) => {
        toast.error('Ocorreu um erro ao remover registro! Contate o suporte.', {
          position: 'bottom-center',
          autoClose: Metrics.messageAutoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        console.error(err);
      })

      .finally(() => {
        console.log({ CC: values[0].CC });

        postTeamFetch({ CC: values[0].CC });
      });
  }


  async function editInputTransform() {
    setListLoading(true);
    await setColumnsState(columns);
    setListLoading(false);
  }


  // --------------------- AREA FUNCTIONS RENDER BUTTONS ----------------------

  function renderSwitchBtnEdit(cell: any, row: any, index: number) {
    return (
      row.ID === editRegister
        ? (
          <Stack direction={'horizontal'}>

            <ButtonTableAction
              key={index}
              tooltip={TextTooltip.CANCEL}
              icon={
                <IconNot
                  color={Colors?.black}
                  size={16}
                />
              }
              onClick={() => {
                setEditRegister(null);
              }}
            />


            <ButtonTableAction
              key={index}
              tooltip={TextTooltip.SAVE}
              icon={
                <IconCheck
                  color={Colors?.black}
                  size={16}
                />
              }
              onClick={() => {
                setListLoading(true);
                postTeamEdit(
                  new Array({
                    ...row,
                    DESCR: row.DESCR,
                    LIDER: row.LIDER,
                  }),
                );
                setEditRegister(null);
                setListLoading(false);
              }}
            />

          </Stack>
        )


        : (
          <ButtonTableAction
            key={index}
            tooltip={TextTooltip.EDIT}
            icon={
              <IconEdit
                color={Colors?.black}
                size={16}
              />
            }
            onClick={() => {
              setEditRegister(row.ID);
            }}
          />
        )
    );
  };


  function renderBtnRemove(cell: any, row: any, index: number) {
    return (
      <ButtonTableAction
        key={index}
        tooltip={TextTooltip.REMOVE}
        icon={
          <IconTrash
            color={Colors?.black}
            size={16}
          />
        }
        onClick={() => {
          postTeamRemove(
            new Array({
              ...row,
            }),
          );
        }}
      />
    );
  };


  // --------------------- AREA TABLE ----------------------

  function renderComponentLoading() {
    return (
      <LoadingsComponent
        type={'SCREEN'}
        color={Colors?.tertiary}
      />
    );
  };


  function renderEmptyList() {
    return (
      <EmptyContent
        image={emptyObject.image}
        title={emptyObject.title}
        description={emptyObject.description}
      />
    );
  };


  function renderDataTable(propsTkProvider: any) {
    return (
      <PaginationProvider
        pagination={paginationFactory(options)}>
        {({
          paginationProps,
          paginationTableProps,
        }) => (
          <TableCuston
            noQuantity={listTeams.length < 10}
            data={listTeams}
            columns={columnsState}
            baseProps={propsTkProvider.baseProps}
            paginationProps={paginationProps}
            paginationTableProps={paginationTableProps}
          />
        )}
      </PaginationProvider>
    );
  };


  function renderInputLiderToEdit(cell: any, row: any, index: number) {
    return (
      row.ID === editRegister
        ? (
          <input
            key={index}
            type='text'
            defaultValue={row.LIDER}
            onChange={(e) => {
              row.LIDER = e.target.value;
            }}
            style={{
              border: '1px solid #ced4da',
              borderRadius: 5,
              height: 25,
              width: '100%',
              paddingLeft: 5,
            }}
          />
        )
        : (row.LIDER)
    );
  };


  function renderInputDescrToEdit(cell: any, row: any, _index: number) {
    return (
      row.ID === editRegister
        ? (
          <input
            key={1}
            type='text'
            defaultValue={row.DESCR}
            onChange={(e) => {
              row.DESCR = e.target.value;
            }}
            style={{
              border: '1px solid #ced4da',
              borderRadius: 5,
              height: 25,
              width: '100%',
              paddingLeft: 5,
            }}
          />
        )
        : (row.DESCR)
    );
  };

  const columns = [
    {
      dataField: 'ID',
      text: 'Equipe',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'LIDER',
      text: 'Lider',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => cell,
      formatter: (cell, row, index): JSX.Element => {
        return renderInputLiderToEdit(cell, row, index);
      },
    },
    {
      dataField: 'DESCR',
      text: 'Descricao',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => cell,
      formatter: (cell, row, index): JSX.Element => {
        return renderInputDescrToEdit(cell, row, index);
      },
    },
    {
      dataField: 'CC',
      text: 'Centro de Custo',
      sort: true,
      headerStyle: () => {
        return { width: '20%' };
      },
      csvFormatter: (cell): string => `'${cell}`,
    },
    {
      dataField: '',
      text: 'Editar',
      csvExport: false,
      formatter: (cell, row, index): JSX.Element => {
        return renderSwitchBtnEdit(cell, row, index);
      },
    },
    {
      dataField: '',
      text: 'Remover',
      csvExport: false,
      formatter: (cell, row, index): JSX.Element => {
        return renderBtnRemove(cell, row, index);
      },
    },

  ];

  const options = {
    custom: true,
    totalSize: listTeams.length,
  };


  useEffect(() => {
    setColumnsState(columns);
    postOsFetch();
  }, []);


  useEffect(() => {
    editInputTransform();
  }, [editRegister]);



  return (

    <Screen>
      <NavHeader>
        <NavUser backHome />

        <NavComp
          navKey={1}
          itemKey={12}
          navArray={PIC_NavRoutes}
          title={'PIC'}
          subtitle={'Programa de Inspeção Comportamental'}
        />
      </NavHeader>

      <Content>
        <ContentStyled fluid>

          <ContainerView>

            {!carregandoForm && (

              <SectionMargin>
                <TitleGroup>
                  {titleGroup.toUpperCase()}
                </TitleGroup>


                {listLoading &&
                  renderComponentLoading()}


                <SectionMargin>
                  <Formik
                    enableReinitialize
                    validateOnMount
                    validationSchema={
                      isNewRegister
                        ? PICCadastroNovaEquipeValidationSchema
                        : PICCadastroEquipeValidationSchema
                    }
                    initialValues={IPicCadastroEquipeModel}
                    onSubmit={(values, { resetForm }) => {
                      !isNewRegister
                        ? postTeamFetch(values)

                        : postTeamNew(
                          new Array({ ...values }),
                        ) && resetForm();
                    }}
                  >
                    {({
                      isValid,
                      values,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    }) => (

                      <FilterContainerForm auto onSubmit={handleSubmit}>
                        {/* { JSON.stringify(values) } */}

                        <Row>
                          <Col xs={12}>
                            <DropdownInput
                              disabled={listLoading}
                              name={'CC'}
                              label={'Centro de Custo'}
                              placeholder={'Selecione o Centro de Custo'}
                              dropArray={[...listOs]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.CC}
                            />
                          </Col>
                        </Row>


                        {isNewRegister &&
                          <Row>
                            <Col xs={6}>
                              <TextInput
                                disabled={listLoading}
                                type={'text'}
                                name={'DESCR'}
                                label={'Equipe'}
                                placeholder={'Equipe'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.DESCR}
                              />
                            </Col>

                            <Col xs={6}>
                              <TextInput
                                disabled={listLoading}
                                type={'text'}
                                name={'LIDER'}
                                label={'Líder'}
                                placeholder={'Lider'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.LIDER}
                              />
                            </Col>
                          </Row>

                        }

                        <Row>

                          <RowButtonFiltersView>

                            {!isNewRegister && (
                              <IButton
                                disabled={listTeams.length === 0}
                                backgroundColor={Colors?.excel}
                                text={'Exportar'}
                                onClick={() => {
                                  // propsTkProvider.csvProps.onExport();
                                }}
                              />
                            )}

                            {!isNewRegister && <>
                              <IButton
                                disabled={listLoading}
                                text={'Novo'}
                                backgroundColor={Colors?.gray}
                                onClick={() => {
                                  setCarregandoForm(true);
                                  setTimeout(() => {
                                    setFieldValue('CC', values.CC);
                                    setFieldValue('DESCR', values.DESCR);

                                    setIsNewRegister(true);
                                    setShowList(false);
                                    setTitleGroup('Nova Equipe');
                                    setCarregandoForm(false);
                                  });
                                }}
                              />


                              <IButton
                                disabled={listLoading}
                                text={'Filtrar'}
                                backgroundColor={Colors?.primary}
                                type={'submit'}
                                onClick={() => {
                                  if (!isValid) {
                                    setMessageValue({
                                      head: 'Erro!',
                                      orient: 'Existem campos obrigatórios que não foram preenchidos.',
                                      orient2: 'Por favor revise o formulário!',
                                    });
                                    setMessageModal(true);
                                  }
                                }}
                              />

                            </>}


                            {isNewRegister && <>
                              <IButton
                                text={'Cancelar'}
                                backgroundColor={Colors?.gray}
                                onClick={() => {
                                  setTitleGroup('Equipes');
                                  setIsNewRegister(false);
                                  setCarregandoForm(true);

                                  setTimeout(() => {
                                    setFieldValue('CC', values.CC);
                                    setFieldValue('DESCR', values.DESCR);
                                    setShowList(false);
                                    setCarregandoForm(false);
                                  }, 1001);
                                }}
                              />


                              <IButton
                                text={'Limpar'}
                                backgroundColor={Colors?.grayDark}
                                type={'reset'}
                              />


                              <IButton
                                disabled={listLoading}
                                text={'Salvar'}
                                backgroundColor={Colors?.primary}
                                type={'submit'}
                              />

                            </>}

                          </RowButtonFiltersView>

                        </Row>

                      </FilterContainerForm>

                    )}
                  </Formik>
                </SectionMargin>



                <ContainerView>

                  {!listLoading && showList && listTeams.length === 0 &&
                    renderEmptyList()}

                  {!listLoading && showList && listTeams && listTeams.length > 0 &&
                    renderDataTable(null)}
                  {/* renderDataTable(propsTkProvider)} */}

                </ContainerView>



              </SectionMargin>

            )}

            {carregandoForm &&
              renderComponentLoading()}

          </ContainerView>

        </ContentStyled>
      </Content>


      {
        messageModal && (
          <ConfirmationModal
            show={messageModal}
            head={messageValue.head}
            orient={messageValue.orient}
            orient2={messageValue.orient2}
            negative={() => {
              setMessageModal(false);
            }}
          />
        )
      }

    </Screen>

  );
};



export default PIC_CadastroEquipeScreen;
