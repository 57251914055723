/* eslint-disable react/display-name */
import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  format,
} from 'date-fns';

import QrCode from 'qrcode';

import PackageInfo from '../../../package.json';

import {
  Images,
} from '../../common/constants';

import {
  RootState,
} from '../../store';

import { intranet_config } from '../../utils/Config';

import {
  QrPageFragment,
  QrPageRefContainer,
  QrPageItemPrintContainer,
  QrPageHeader,
  QrPageLogoHeaderView,
  QrPageLogoImage,
  QrPageLogoTitleView,
  QrPageLogoTitle,

  QrAuthorContainer,
  QrAuthorTitle,
  QrAuthorName,

  QrPageItemContainer,
  QrSubpageContent,
  QrViewContent,
  QrTextView,
  QrIdText,
  QrNameText,
  QrImageView,
  QrCodeImage,
  QrPageFooter,
  QrPagerFooterLeft,
  QrPagerFooterCenter,
  QrPagerFooterRight,
  QrPagerFooterTitle,
  QrPagerFooterText,
} from './styled';



export interface IProps {
  type: 'FUNCIONARIO' | 'PRODUTO' | 'DEPOSITO' | 'MALOTE-FUNCIONARIO' | 'MALOTE-PRODUTO' | 'MALOTE-DEPOSITO';
  list?: Array<any>;
}



const QrCodeComponent = React.forwardRef((props: IProps, ref: any) => {
  const user = useSelector((state: RootState) => state.user.data);


  const [listSrc, setListSrc] = useState([]);


  function renderUserQr(idText: string, nameText: string, qrCodeString: string) {
    return (

      <QrViewContent key={`${idText}-${nameText}`}>

        <QrTextView>
          <QrIdText>
            {idText || ' '}
          </QrIdText>

          <QrNameText>
            {nameText || ' '}
          </QrNameText>
        </QrTextView>


        <QrImageView>
          <QrCodeImage
            alt={idText}
            src={qrCodeString}
          />
        </QrImageView>
      </QrViewContent>

    );
  }


  async function renderTypeItemQr() {
    const listaQR = [];

    if (props.list) {
      for (let i = 0; i < props.list.length; i++) {
        const elem = props.list[i];

        switch (props.type) {
          case 'FUNCIONARIO':
            listaQR.push({
              id: String(elem.MATRICULA).trim(),
              name: String(elem.NOME).trim(),
              qrCode: await QrCode.toDataURL(String(elem.QRCODE).trim()),
            });
            break;

          case 'PRODUTO':
            listaQR.push({
              id: String(elem.COD).trim(),
              name: String(elem.PRODUTO).trim(),
              qrCode: await QrCode.toDataURL(String(elem.QRCODE).trim()),
            });
            break;

          case 'DEPOSITO':
            listaQR.push({
              id: String(elem.CODIGO).trim(),
              name: String(elem.DEPOSITO).trim(),
              qrCode: await QrCode.toDataURL(String(elem.QRCODE).trim()),
            });
            break;

          case 'MALOTE-PRODUTO':
          case 'MALOTE-FUNCIONARIO':
          case 'MALOTE-DEPOSITO':
            // console.log('MALOTE');
            listaQR.push({
              id: String(elem.ID).trim(),
              name: String(elem.NOME).trim(),
              qrCode: await QrCode.toDataURL(String(elem.QRCODE).trim()),
            });
            break;

          default:
            break;
        }
      }

      setListSrc(listaQR);
    }
    else {
      listaQR.push({
        id: 'NULL',
        name: 'NULL',
        qrCode: Images.backgroundAvatar,
      });

      setListSrc(listaQR);
    }
  }


  function renderTypeQr() {
    switch (props.type) {
      case 'FUNCIONARIO':
      case 'MALOTE-FUNCIONARIO':
        return 'Funcionários';

      case 'PRODUTO':
      case 'MALOTE-PRODUTO':
        return 'Produtos';

      case 'DEPOSITO':
      case 'MALOTE-DEPOSITO':
        return 'Depósitos';

      default:
        return 'Tipo';
    }
  }



  useEffect(() => {
    renderTypeItemQr();
  }, []);


  useEffect(() => {
    renderTypeItemQr();
  }, [props.list]);



  function renderPageQr(index, sublist) {
    return (

      <QrPageItemPrintContainer>
        <QrPageHeader>
          <QrPageLogoHeaderView>
            <QrPageLogoImage
              // alt={'Logo'}
              // src={Images.logoFullApp}
              alt={`${intranet_config?.Registration?.name} Logo`}
              src={intranet_config?.Images?.logo}
            />
          </QrPageLogoHeaderView>


          <QrPageLogoTitleView>
            <QrPageLogoTitle>
              PSTOCK - Impressão de Qr Code
              {'\n'}
              {renderTypeQr()}
            </QrPageLogoTitle>


            <QrAuthorContainer>
              <QrAuthorTitle>
                Relatório Gerado por:
              </QrAuthorTitle>
              <QrAuthorName>
                {user.name}
              </QrAuthorName>
            </QrAuthorContainer>


            <QrAuthorContainer>
              <QrAuthorTitle>
                Total de registros:
              </QrAuthorTitle>
              <QrAuthorName>
                {props.list?.length || '-'}
              </QrAuthorName>
            </QrAuthorContainer>
          </QrPageLogoTitleView>
        </QrPageHeader>



        <QrPageItemContainer>
          <QrSubpageContent>

            {sublist.map((elem) => (
              renderUserQr(elem.id, elem.name, elem.qrCode)
            ))}

          </QrSubpageContent>
        </QrPageItemContainer>



        <QrPageFooter>
          <QrPagerFooterLeft>
            <QrPagerFooterTitle>
              Versão da aplicação:
            </QrPagerFooterTitle>
            <QrPagerFooterText>
              {PackageInfo.version}
            </QrPagerFooterText>
          </QrPagerFooterLeft>


          <QrPagerFooterCenter>
            <QrPagerFooterTitle>
              Data de impressão:
            </QrPagerFooterTitle>
            <QrPagerFooterText>
              {format(new Date(Date.now()), 'dd/MM/yyyy HH:mm')}
            </QrPagerFooterText>
          </QrPagerFooterCenter>


          <QrPagerFooterRight>
            <QrPagerFooterTitle>
              Página:
            </QrPagerFooterTitle>
            <QrPagerFooterText>
              {index}
            </QrPagerFooterText>
          </QrPagerFooterRight>
        </QrPageFooter>

      </QrPageItemPrintContainer>

    );
  }


  function mapToPages(quantity: number) {
    const pages = [];
    for (let i = 0; i < listSrc.length / quantity; i++) {
      pages.push(renderPageQr((i + 1), listSrc.slice(i * quantity, (i + 1) * quantity)));
    }
    return pages;
  }



  return listSrc.length > 0 ?
    <QrPageFragment>

      <QrPageRefContainer ref={ref}>
        {mapToPages(44)}
      </QrPageRefContainer>

    </QrPageFragment>
    : null;
});



export default QrCodeComponent;
