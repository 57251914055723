import React from 'react';

import {
  Modal,
} from 'react-bootstrap';

import {
  Form,
  Formik,
} from 'formik';

import {
  Colors,
} from '../../../../../common/constants';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';

import { IToast } from '../../../../../components/IToast/Toast';
import {
  ModalContainerStyled,
  ModalBodyStyled,
  ModalFooterStyled,
} from '../../../../../components/Modals/styled';

import TextInput from '../../../../../components/TextFields/Input';



interface IProps {
  show: boolean;
  onHide?: any;
}



const FiltroVazioModal: React.FC<IProps> = (props) => {
  return (

    <Modal
      {...props}
      show={props.show}
      size={'sm'}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Filtro Vazio'.toUpperCase()}
        </TitleGroup>

        <ModalBodyStyled>

          <h5>Por favor preencher a Data de Início ou Número do Pedido!</h5>
        </ModalBodyStyled>



        <ModalFooterStyled>

          <IButton
            backgroundColor={Colors?.buttonCancel}
            text={'Fechar'}
            type={'button'}
            onClick={props.onHide}
          />

        </ModalFooterStyled>

      </ModalContainerStyled>

    </Modal>

  );
};



export default FiltroVazioModal;
