import React,
{
  useState,
} from 'react';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Colors,
} from '../../../../../common/constants';
import ExcelConfigs from '../../../../../common/excel';

import {
  TitleGroup,
} from '../../../../../common/styles/styled.layout';

import IButton from '../../../../../components/Buttons/IButton';
import NoItemsContent from '../../../../../components/Contents/NoItems';


import LoadingsComponent from '../../../../../components/Loadings';

import {
  ModalBodyStyled,
  ModalContainerStyled,
  ModalFirstStyled,
  ModalFooterStyled,
  ModalTableContainer,
  ModalTableObsText,
} from '../../../../../components/Modals/styled';

import TableCuston from '../../../../../components/Table';


import { accentRemove } from '../../../../../utils/StringUtils';



interface IProps {
  show: boolean;
  values?: any;
  onHide?: any;
}



const RelatorioPOCModal: React.FC<IProps> = (props) => {
  const [loading] = useState(false);


  const columns = [
    {
      dataField: 'NUM',
      text: 'NUM',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'EMISSOR',
      text: 'EMISSOR',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => accentRemove(cell),
    },
    {
      dataField: 'DATA',
      text: 'Data',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '7%' };
      },
      csvFormatter: (cell): string => cell,
    }, {
      dataField: 'DTCADASTRO',
      text: 'Dt. Cadastro',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'HORACADASTRO',
      text: 'Hr, Cadastro',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'CC',
      text: 'Centro Custo',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '7%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'AREA',
      text: 'Area',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => accentRemove(cell),
    },
    {
      dataField: 'MATRICULA',
      text: 'Matricula',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'OBSERVADOR',
      text: 'Observador',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => accentRemove(cell),
    },

    {
      dataField: 'CATEGORIA',
      text: 'Categoria',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
      csvFormatter: (cell): string => accentRemove(cell).replaceAll(/'/g, ''),
    },
    {
      dataField: 'CODITEM',
      text: 'Cod.',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'ITEM',
      text: 'Item',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      csvFormatter: (cell): string => accentRemove(cell),
    },
    {
      dataField: 'LIDER',
      text: 'Lider',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => accentRemove(cell),
    },
    {
      dataField: 'DESCR_EQUIPE',
      text: 'Descricao',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => accentRemove(cell),
    },
    {
      dataField: 'ATENDE',
      text: 'Atende',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => accentRemove(cell),
    },
    {
      dataField: 'NCOLABORADORES',
      text: 'Nro. Colab.',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'NMELHORIAS',
      text: 'Nro. Melh.',
      sort: true,
      headerStyle: () => {
        return { width: '5%' };
      },
      csvFormatter: (cell): string => cell,
    },

    {
      dataField: 'SUCESSOS',
      text: 'Cases de Sucesso',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => cell !== 'undefined' ? cell : '',
    },
    {
      dataField: 'DESVIOS',
      text: 'Desvios',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '10%' };
      },
      csvFormatter: (cell): string => cell !== 'undefined' ? cell : '',
    },
    {
      dataField: 'RECONHECIMENTO',
      text: 'Reconhecimento',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => cell,
    },
    {
      dataField: 'OBS',
      text: 'Obs.',
      sort: true,
      hidden: true,
      headerStyle: () => {
        return { width: '25%' };
      },
      csvFormatter: (cell): string => accentRemove(cell),
    },



  ];

  const options = {
    custom: true,
    totalSize: props.values.length,
  };



  return (


    <ModalFirstStyled
      {...props}
      dialogClassName="modal-first-dialog modal-85"
      backdropClassName="modal-first-backdrop"
      show={props.show}
      centered>

      <ModalContainerStyled>

        <TitleGroup>
          {'Relatório de POC'.toUpperCase()}
        </TitleGroup>


        <ModalBodyStyled>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}



          {!loading && (!props.values || props.values === null || props.values.length === 0) && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                CC: {props.values[0].CC} | Area: {props.values[0].AREA} | NRO.: {props.values[0].NUM}
              </ModalTableObsText>

              <NoItemsContent />

            </ModalTableContainer>
          )}



          {!loading && props.values && props.values.length > 0 && (
            <ModalTableContainer
              noMargin>

              <ModalTableObsText>
                CC: {props.values[0].CC} | Area: {props.values[0].AREA} | NRO.: {props.values[0].NUM}
              </ModalTableObsText>


              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={props.values.length < 10}
                    data={props.values}
                    columns={columns}
                    // baseProps={propsTkProvider.baseProps}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>
            </ModalTableContainer>
          )}

        </ModalBodyStyled>



        <ModalFooterStyled>

          <IButton
            backgroundColor={Colors?.primary}
            text={'Fechar'}
            onClick={props.onHide}
          />


          <IButton
            disabled={props.values.length === 0}
            backgroundColor={Colors?.excel}
            text={'Exportar'}
            onClick={() => {
              // propsTkProvider.csvProps.onExport();
            }}
          />

        </ModalFooterStyled>

      </ModalContainerStyled>

    </ModalFirstStyled>

  );
};



export default RelatorioPOCModal;
