import React, { useState } from 'react';


import {
  Container,
} from 'react-bootstrap';

import {
  useSelector,
} from 'react-redux';

import { useParams } from 'react-router-dom';

import { TextTooltip } from '../../common/constants/TextTooltips';

import {
  Content,
  ContentStyled,
  TextBold,
} from '../../common/styles/styled.layout';
import ButtonTableAction from '../../components/Buttons/ButtonTableAction';

import NavComp from '../../components/Headers/NavComp';
import NavHeader from '../../components/Headers/NavHeader';
import NavUser from '../../components/Headers/NavUser';
import { IconCancel, IconEditPhoto, IconSend } from '../../components/Icons';
import IPhoto from '../../components/IPhoto';
import { IToast } from '../../components/IToast/Toast';
import LoadingsComponent from '../../components/Loadings';
import Screen from '../../components/Screen';
import { PAV_NavRoutes } from '../../navigation/navs/HumanResources';
import ApiWS from '../../services/api.service';

import {
  RootState,
} from '../../store';

import {
  HeaderUserContainer,
  UserInfoHeaderView,
  HeaderUserName,
  UserInfoDomainContent,
  HeaderDomainText,
  HeaderInfoText,
  AreaEditPhoto,
  InputFilePhoto,
  LabelPhoto,
  LabelPhotoDiv,
} from './styled';



const USER_HomeScreen: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.data);

  const { param } = useParams() as any;

  const [loading, setLoading] = useState(false);
  const [dataForm_state, setDataForm_state] = useState(null);



  function postUploadPhoto() {
    // setLoading(true);

    const lerArquivo = new FileReader();

    lerArquivo.onload = function (arquivoCarregando) {
      const imagemBase64 = arquivoCarregando.target.result;

      const url = '/perfil/foto/upload';
      ApiWS()
        .post(url, [{ FOTO: imagemBase64 }])
        .then((res) => {
          if (res.status === 200) {
            IToast({
              type: 'success',
              message: res.data,
            });

            setTimeout(() => {
              window.location.reload();
            }, 600);
          }
        })
        .catch((err) => {
          console.error('postUploadPhoto:::', JSON.stringify(err));

          IToast({
            type: 'warning',
            message: 'Ocorreu um erro! Tente novamente, caso o erro persista, contate o suporte.',
          });
        })
        .finally(() => setLoading(false));
    };

    lerArquivo.readAsDataURL(dataForm_state[0]);
  };


  return (

    <Screen>

      <NavHeader>
        <NavUser backHome />

        <NavComp
          navKey={4}
          navArray={param === 'pav' && PAV_NavRoutes}
          title={param === 'pav' && 'PAV'}
        />
      </NavHeader>

      {loading && (
        <LoadingsComponent />
      )}

      {!loading && (
        <Content>
          <ContentStyled fluid>
            <Container>

              <HeaderUserContainer>
                {/* <form> */}

                <IPhoto
                  src={dataForm_state && URL.createObjectURL(dataForm_state[0]) || user.photo}
                  alt={user.name}
                  size={'md'}
                />


                <AreaEditPhoto>

                  {!dataForm_state && (
                    <LabelPhoto htmlFor='photoSelf'>
                      <ButtonTableAction
                        key={'right'}
                        placement={'right'}
                        tooltip={TextTooltip.EDIT_PHOTO}
                        icon={<IconEditPhoto size={25} />}
                      />

                      Editar

                      <InputFilePhoto
                        id='photoSelf'
                        type="file"
                        onChange={(e: any) => {
                          setDataForm_state(e.target.files);
                        }}
                        formEncType={'multipart/form-data'}
                        accept={'image/png,image/jpeg,image/jpg'}
                      />
                    </LabelPhoto>
                  )}


                  {dataForm_state && (
                    <>
                      <LabelPhotoDiv>
                        <ButtonTableAction
                          key={'right'}
                          placement={'right'}
                          tooltip={TextTooltip.SAVE_PHOTO}
                          icon={<IconSend size={25} />}
                          onClick={() => {
                            postUploadPhoto();
                          }}
                        />

                        Salvar
                      </LabelPhotoDiv>


                      <LabelPhotoDiv margin={'auto -109px auto 50px'}>
                        <ButtonTableAction
                          key={'right'}
                          placement={'right'}
                          tooltip={TextTooltip.CANCEL}
                          icon={<IconCancel size={29} />}
                          onClick={() => {
                            setTimeout(() => {
                              setDataForm_state(null);
                            }, 300);
                          }}
                        />

                        Cancelar
                      </LabelPhotoDiv>
                    </>
                  )}


                </AreaEditPhoto>

                {/* </form> */}



                <UserInfoHeaderView>
                  <HeaderUserName>
                    {user.name}
                  </HeaderUserName>


                  <UserInfoDomainContent>
                    <HeaderDomainText>
                      <TextBold>Domínio:</TextBold>
                      &nbsp;
                      {user.domain}
                    </HeaderDomainText>

                    <HeaderDomainText>
                      <TextBold>ID:</TextBold>
                      &nbsp;
                      {user.id}
                    </HeaderDomainText>
                  </UserInfoDomainContent>


                  <UserInfoDomainContent>
                    <HeaderInfoText>
                      <TextBold>Usuário:</TextBold>
                      &nbsp;
                      {user.user}
                    </HeaderInfoText>

                    <HeaderInfoText>
                      <TextBold>Email:</TextBold>
                      &nbsp;
                      {user.email}
                    </HeaderInfoText>
                  </UserInfoDomainContent>

                </UserInfoHeaderView>

              </HeaderUserContainer>

            </Container>
          </ContentStyled>
        </Content>
      )}

    </Screen>

  );
};



export default USER_HomeScreen;
