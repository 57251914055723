import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import ScreenRoutes from './screens.routes';



function AppRoutes() {
  const loggedIn = useSelector((state: any) => state.user.loggedIn);


  return (

    <BrowserRouter>
      <Switch>

        {ScreenRoutes.map(
          ({ path, page: Page, exact, isPublic }, index) => {
            return isPublic
              ? (
                <Route
                  key={index}
                  path={path}
                  exact={exact}>

                  <Page />

                </Route>
              )
              : (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                  render={({ location }) => {
                    if (loggedIn) {
                      return <Page />;
                    }

                    // Redirect to Login Page
                    return (
                      <Redirect
                        to={{
                          pathname: '/',
                          state: { from: location },
                        }}
                      />
                    );
                  }}
                />
              );
          },
        )}

      </Switch>
    </BrowserRouter>

  );
}



export default AppRoutes;
