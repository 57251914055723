import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import {
  Formik,
} from 'formik';

import {
  AllItems,
} from '../../../../../common/arrays';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../common/constants';

import {
  ContainerView,
  Content,
  ContentStyled,
  FilterContainerForm,
  RowButtonFiltersView,
  Screen,
  SectionMargin,
  SeparatorButton,
  TitleGroup,
} from '../../../../../common/styles/styled.layout';


import IButton from '../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../components/Empty';
import NavComp from '../../../../../components/Headers/NavComp';
import NavHeader from '../../../../../components/Headers/NavHeader';
import NavUser from '../../../../../components/Headers/NavUser';
import LoadingsComponent from '../../../../../components/Loadings';
import TableCuston from '../../../../../components/Table';
import DropdownInput from '../../../../../components/TextFields/Dropdown';
import TextInput from '../../../../../components/TextFields/Input';


import ApiWS, { defaultBaseURL } from '../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../utils/ErrorUtils';
import ICOModel from './CO.model';


import {
  DividerSpan,
  DividerText,
} from './CO.styled';
import PEXValidationSchema from './CO.validation';
import { IToast } from '../../../../../components/IToast/Toast';
import { PEX_NavRoutes } from '../../../../../navigation/navs/Corporate';
import ButtonTableAction from '../../../../../components/Buttons/ButtonTableAction';
import { TextTooltip } from '../../../../../common/constants/TextTooltips';
import { IconEdit, IconTrash } from '../../../../../components/Icons';
import PEXNewCOModal from '../Modals/NewCOModal';
import PEXRemoveCOModal from '../Modals/RemoverCOModal';
import PEXeditCOModal from '../Modals/EditCOModal';



const CO_IndexScreen: React.FC = () => {
  const pasta = window.localStorage.getItem('@intranet/pasta');

  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(0);
  const [err, setErr] = useState(false);

  const [listDropCO, setListDropCO] = useState([]);
  const [listDropCC, setListDropCC] = useState([]);
  const [listDropCG, setListDropCG] = useState([]);

  const errObject = {
    image: Images.other500,
    title: 'Erro!',
    description: 'Atualize a página ou contate o suporte!',
  };

  const emptyObject = {
    image: Images.empty,
    title: 'Nenhuma consulta aqui!',
    description: 'Não há nenhuma consulta aqui',
  };

  const unauthorizedObject = {
    image: Images.error401,
    title: 'Sem autorização de acesso!',
    description: 'Não há nada aqui',
  };

  const [listData, setListData] = useState([
    // {
    //   CONTA_OPE: '1.0',
    //   DESCR_OPE: 'MÃO DE OBRA',
    //   CONTA_CON: '1',
    //   DESCR_CON: '*** ATIVO ***',
    // },
  ]);

  const [showListData, setShowListData] = useState(true);
  const [listaCarregando, setListaCarregando] = useState(false);


  const [totalResults, setTotalResults] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [valuesPayload, setValuesPayload] = useState(null);


  const [showModalNew, setShowModalNew] = useState(false);

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [valuesEdit, setValuesEdit] = useState(null);

  const [showModalConfirmRemove, setShowModalConfirmRemove] = useState(false);
  const [valuesConfirmRemove, setValuesConfirmRemove] = useState(null);

  function postDropCO() {
    setLoading(true);

    const url = '/pex/co/consulta/co';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((conta, index) => {
            setListDropCO((list) => [...list, {
              key: index,
              label: conta.TELA,
              value: conta.CONTA,
            }],
            );
          });
        };
      })
      .catch((error) => {
        console.error('postDropCO', JSON.stringify(error.response, null, 2));
        setErr(true);
        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Operacionais. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Operacionais. Contate o suporte! ${error.response.status}`,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function postDropCC() {
    setLoading(true);

    const url = '/pex/co/consulta/cc';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((conta, index) => {
            setListDropCC((list) => [...list, {
              key: index,
              label: conta.TELA,
              value: conta.CONTA,
            }],
            );
          });
        };
      })
      .catch((error) => {
        console.error('postDropCC', JSON.stringify(error.response, null, 2));
        setErr(true);
        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Contabeis. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Contabeis. Contate o suporte! ${error.response.status}`,
          });
        }
      })
      .finally(() => {
        postDropCO();
      });
  };

  function postDropCG() {
    setLoading(true);

    const url = '/pex/co/consulta/cg';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((conta, index) => {
            setListDropCG((list) => [...list, {
              key: index,
              label: conta.TELA,
              value: conta.CONTA,
            }],
            );
          });
        };
      })
      .catch((error) => {
        console.error('postDropCG', JSON.stringify(error.response, null, 2));
        setErr(true);
        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Contabeis. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Contabeis. Contate o suporte! ${error.response.status}`,
          });
        }
      })
      .finally(() => {
        postDropCC();
      });
  };

  function postPermission() {
    setLoading(true);

    const url = '/pex/co/permissao';

    ApiWS()
      .post(url)
      .then((resp) => {
        if (resp.status === 200) {
          const retorno = resp.data;
          setAuthorized(retorno);

          if (retorno >= 1) {
            postDropCG();
          }
        }
        else if (resp.status === 203) {
          const retorno = resp.data;
          setAuthorized(retorno);

          if (retorno === 0) {
            setAuthorized(0);
            setLoading(false);
          }
        };
      })
      .catch((error) => {
        console.error('postPermission', JSON.stringify(error.response, null, 2));
        setErr(true);

        setLoading(false);
        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar as Contas Operacionais. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar as  Contas Operacionais. Contate o suporte! ${error.response.status}`,
          });
        }
      });
  };


  async function postCOxCC(values, page_iteration: any) {
    setLoading(true);
    setListData([]);
    setValuesPayload(values);

    const url = '/pex/co/consulta';
    const payload = [{
      ...values,
      'PAGINA': page_iteration,
      'TAMANHO': Metrics.size_centrocusto,
    }];

    ApiWS()
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          setListData(resp.data[0]);

          setTotalResults(resp.data[1][0].TOTAL);
          setTotalPage(Math.ceil(resp.data[1][0].TOTAL / 10));
        }
      })

      .catch((error) => {
        console.error('fetchAll', JSON.stringify(error.response, null, 2));

        if (!error.response) {
          IToast({
            type: 'error',
            message: 'Erro ao consultar os lançamentos. Contate o suporte!',
          });
        }
        else {
          IToast({
            type: 'error',
            message: `Erro ao consultar os lançamentos. Contate o suporte! ${error.response.status}`,
          });
        }
      })

      .finally(() => {
        setLoading(false);
        setShowListData(true);
      });
  };


  async function handlePagination(type: number) {
    let page_var = page;


    if (type === 0 && page_var > 1) {
      page_var = page_var - 1;
    }
    if (type === 1 && page_var < totalPage) {
      page_var = page_var + 1;
    }

    postCOxCC(valuesPayload, page_var);
    setPage(page_var);
  };


  function postExport(values: any) {
    setLoading(true);

    const url = '/efetivo/home/exporta';



    const payload = {
      'OS': values.input_os || values.select_os,
      'TIPO': values.input_tipo,
      'FUNCAO': values.input_funcao,
      'MATRICULA': values.input_matricula,
      'NOME': values.input_funcionario,
      'SITUAC': values.input_situacao,
      'FERIAS': values.input_ferias,
    };

    ApiWS()
      .post(url, [payload])
      .then((res) => {
        if (res.status === 200) {
          window.open(`${defaultBaseURL}/${pasta}/temp/${res.data}`);
        }
      })
      .catch((err) => {
        console.error('postFetchCard:::', JSON.stringify(err));

        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso erro persista, contate o suporte.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const columnsN1: any = [
    {
      dataField: 'CONTA_OPE',
      text: 'Conta Operacional',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR_OPE',
      text: 'Descr. Operacional',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'CONTA_CON',
      text: 'Conta Contábil',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR_CON',
      text: 'Descr. Contábil',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'CONTA_GER',
      text: 'Conta Gerencial',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR_GER',
      text: 'Descr. Gerencial',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
  ];

  const columnsN2: any = [
    {
      dataField: 'CONTA_OPE',
      text: 'Conta Operacional',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR_OPE',
      text: 'Descr. Operacional',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'CONTA_CON',
      text: 'Conta Contábil',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR_CON',
      text: 'Descr. Contábil',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'CONTA_GER',
      text: 'Conta Gerencial',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCR_GER',
      text: 'Descr. Gerencial',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: '',
      text: 'Editar',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (_cell: any, row: any) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.EDIT}
            onClick={() => {
              setTimeout(() => {
                setValuesEdit(row);
              }, 300);
              setShowModalEdit(true);
            }}
            icon={
              <IconEdit
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
    {
      dataField: '',
      text: 'Remover',
      sort: true,
      headerStyle: () => {
        return { width: '8%' };
      },
      formatter: (_cell: any, row: any) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.REMOVE}
            onClick={() => {
              setTimeout(() => {
                setValuesConfirmRemove(row);
              }, 300);
              setShowModalConfirmRemove(true);
            }}
            icon={
              <IconTrash
                color={Colors?.black}
                size={16}
              />
            }
          />
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: listData?.length,
  };


  useEffect(() => {
    postPermission();
  }, []);


  return (

    <Screen>
      {/* {JSON.stringify(listData)} */}
      <NavHeader>

        <NavUser backHome />


        <NavComp
          navKey={1}
          navArray={PEX_NavRoutes}
          title={'PEX'}
        />

      </NavHeader>


      <Content>

        <ContentStyled fluid>

          {loading && (
            <LoadingsComponent
              type={'SCREEN'}
              color={Colors?.tertiary}
            />
          )}


          {!loading && err && (
            <EmptyContent
              image={errObject.image}
              title={errObject.title}
              description={errObject.description}
            />
          )}


          {!loading && !err && authorized === 0 && (
            <EmptyContent
              image={unauthorizedObject.image}
              title={unauthorizedObject.title}
              description={unauthorizedObject.description}
            />
          )}


          {!loading && !err && authorized !== 0 && (

            <ContainerView>

              <SectionMargin>

                <SectionMargin>

                  <Formik
                    enableReinitialize
                    validateOnMount
                    validationSchema={PEXValidationSchema}
                    initialValues={ICOModel}
                    onSubmit={(values) => {
                      postCOxCC(values, 1);
                    }}>
                    {({
                      values,
                      resetForm,
                      isValid,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setValues,
                    }) => (

                      <FilterContainerForm auto onSubmit={handleSubmit}>

                        <Row>

                          <Col>
                            <DropdownInput
                              disabled={loading || listaCarregando}
                              name={'CO'}
                              label={'Conta Operacional'}
                              placeholder={'Todos'.toLocaleUpperCase()}
                              defaultValue={'Todos'.toLocaleUpperCase()}
                              dropArray={listDropCO}
                              value={values.CO || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>


                          <Col>
                            <DropdownInput
                              disabled={loading || listaCarregando}
                              name={'CC'}
                              label={'Conta Contábil'}
                              placeholder={'Todos'.toLocaleUpperCase()}
                              defaultValue={'Todos'.toLocaleUpperCase()}
                              dropArray={listDropCC}
                              value={values.CC || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                        </Row>

                        <Row>

                          <Col xs={6}>
                            <DropdownInput
                              disabled={loading || listaCarregando}
                              name={'CG'}
                              label={'Conta Gerencial'}
                              placeholder={'Todos'.toLocaleUpperCase()}
                              defaultValue={'Todos'.toLocaleUpperCase()}
                              dropArray={listDropCG}
                              value={values.CG || 'Todos'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                        </Row>


                        <RowButtonFiltersView>

                          <IButton
                            disabled={loading || listaCarregando}
                            text={'Limpar'}
                            backgroundColor={Colors?.gray}
                            type={'reset'}
                            onClick={() => {
                              resetForm();

                              setValues(ICOModel);
                            }}
                          />


                          <IButton
                            disabled={!isValid || loading || listaCarregando}
                            text={'Filtrar'}
                            backgroundColor={Colors?.primary}
                            type={'submit'}
                          />


                          {authorized === 2 && (
                            <IButton
                              disabled={loading || listaCarregando}
                              backgroundColor={Colors?.primary}
                              text={'Novo'}
                              onClick={() => {
                                setShowModalNew(true);
                              }}
                            />
                          )}

                        </RowButtonFiltersView>

                      </FilterContainerForm>

                    )}

                  </Formik>

                </SectionMargin>


                <ContainerView>

                  {listaCarregando && (
                    <LoadingsComponent
                      type={'SCREEN'}
                      color={Colors?.tertiary}
                    />
                  )}


                  {!loading && !listaCarregando && showListData && listData?.length === 0 && (
                    <EmptyContent
                      image={emptyObject.image}
                      title={emptyObject.title}
                      description={emptyObject.description}
                    />
                  )}


                  {!loading && !listaCarregando && showListData && listData?.length > 0 && (
                    <PaginationProvider
                      pagination={paginationFactory(options)}>
                      {({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <TableCuston
                          noQuantity={totalPage === 1}
                          data={listData}
                          columns={authorized === 2 ? columnsN2 : columnsN1}
                          paginationProps={paginationProps}
                          paginationTableProps={paginationTableProps}
                          actionPrevious={() => handlePagination(0)}
                          actionNext={() => handlePagination(1)}
                          currentPage={page}
                          totalPage={totalPage}
                          totalResults={totalResults}
                        />
                      )}
                    </PaginationProvider>
                  )}

                </ContainerView>


              </SectionMargin>

            </ContainerView>

          )}

        </ContentStyled>

      </Content>

      {
        showModalNew && (
          <PEXNewCOModal
            show={showModalNew}
            onHide={() => setShowModalNew(false)}
          />
        )
      }

      {
        showModalConfirmRemove && valuesConfirmRemove && (
          <PEXRemoveCOModal
            show={showModalConfirmRemove}
            onHide={() => {
              setShowModalConfirmRemove(false);
              postCOxCC(valuesPayload, page);
            }}
            values={valuesConfirmRemove}
          />
        )
      }

      {
        showModalEdit && valuesEdit && (
          <PEXeditCOModal
            show={showModalEdit}
            onHide={() => {
              setShowModalEdit(false);
              postCOxCC(valuesPayload, page);
            }}
            values={valuesEdit}
          />
        )
      }

    </Screen >

  );
};



export default CO_IndexScreen;
