import React,
{
  useRef,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  OverlayTrigger,
  Row,
} from 'react-bootstrap';

import paginationFactory,
{
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import ReactToPrint from 'react-to-print';

import {
  toast,
} from 'react-toastify';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  Colors,
  Images,
  Metrics,
} from '../../../../../../common/constants';

import {
  ContainerView,
  ContentStyled,
  FilterContainerForm,
  SectionMargin,
  TableActionButton,
  TableActionUpButton,
  TableToltip,
  TitleGroup,
} from '../../../../../../common/styles/styled.layout';

import IButton from '../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../components/Empty';

import {
  IconReportDeposit,
} from '../../../../../../components/Icons';

import LoadingsComponent from '../../../../../../components/Loadings';
import RelatorioEstoquePrintComponent from '../../../../../../components/Reports/RelatorioEstoque';
import TableCuston from '../../../../../../components/TableOld';
import DropdownInput from '../../../../../../components/TextFields/Dropdown';

import ApiWS from '../../../../../../services/api.service';

import {
  returnErrorObject,
} from '../../../../../../utils/ErrorUtils';

import DepositPstockModel from './deposits.model';
import DepositPstockValidationSchema from './deposits.validation';

import {
  RowButtonView,
} from './styled';



const TabDepositos: React.FC = () => {
  const compRef = useRef([]);
  const formik = useRef<FormikProps<any>>(null);

  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Nenhum item aqui!',
    description: 'Não há nada aqui',
  });

  const [listDeposit, setListDeposit] = useState([]);

  const [receivedArray, setReceivedArray] = useState<Array<any>>([]);
  const [listLoading, setListLoading] = useState(false);



  function showSnackBarProps() {
    toast.error('Erro ao obter dados.', {
      position: 'bottom-center',
      autoClose: Metrics.messageAutoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }


  function renderListDeposit(listResp: any) {
    const listToMap = listResp.map((itemDeposit) => {
      const valueResp = itemDeposit.ID !== '' || itemDeposit.ID !== null || itemDeposit.ID !== undefined ? itemDeposit.ID : null;

      const listMapped = {
        'key': itemDeposit.ID,
        'label': itemDeposit.NOME,
        'value': valueResp,
      };
      return listMapped;
    });

    setListDeposit(listToMap);
    formik.current?.setFieldValue('select_deposito', listToMap[0]?.value);
  };


  async function getDepositsSelect() {
    try {
      setLoading(true);

      const url = '/pstock/coletor/lista/deposito';
      const response = await ApiWS().post(url);

      if (response) {
        renderListDeposit(response.data);
      }
      else {
        showSnackBarProps();
      }
    }
    catch (error) {
      console.error('getDepositsSelect', JSON.stringify(error.response, null, 2));

      showSnackBarProps();
    }
    finally {
      setLoading(false);
    }
  };



  async function getDepositsData(values: typeof DepositPstockModel) {
    const payload = {
      'TIPOREL': 'DEPOSITO',
      'ID': values.select_deposito,
    };


    try {
      setListLoading(true);
      setShowList(false);

      const url = '/pstock/relatorio/consulta';
      const response = await ApiWS().post(url, payload);

      if (response) {
        const arrayTreated = [
          {
            DATA: response.data[0]?.DATA || null,
            ESTOQUE: response.data[1]?.ESTOQUE || [],
          },
        ];
        console.log('arrayTreated', arrayTreated);
        setReceivedArray(arrayTreated);
      }
    }
    catch (error) {
      console.error('getDepositsData', JSON.stringify(error.response, null, 2));

      if (!error.response) {
        console.log(error);
      }
      else {
        setEmptyObject(returnErrorObject(error.response.status));
      }
    }
    finally {
      setListLoading(false);
      setShowList(true);
    }
  };



  useEffect(() => {
    getDepositsSelect();
  }, []);



  const columns = [
    {
      dataField: 'DEPOSITO',
      text: 'Depósito',
      sort: true,
      formatter: (_cell: any, row: any) => {
        return row?.DATA?.NOME || '-';
      },
    },
    {
      dataField: 'ESTOQUE',
      text: 'Relatório de estoque',
      sort: false,
      formatter: (_cell: any, row: any, rowIndex: number) => {
        return (
          <>
            <ReactToPrint
              content={() => (compRef as any).current[rowIndex]}
              trigger={() => (
                <TableActionUpButton>
                  <OverlayTrigger
                    key={'left'}
                    placement={'left'}
                    trigger={['hover', 'hover']}
                    overlay={
                      <TableToltip>
                        Estoque
                      </TableToltip>
                    }>
                    <TableActionButton
                      backgroundColor={Colors?.primary}>
                      <IconReportDeposit
                        color={Colors?.black}
                        size={16}
                      />
                    </TableActionButton>
                  </OverlayTrigger>
                </TableActionUpButton>
              )}
            />

            <RelatorioEstoquePrintComponent
              ref={(element) => (compRef.current[rowIndex] = element)}
              data={row?.DATA}
              list={row?.ESTOQUE}
            />
          </>
        );
      },
    },
  ];


  const options = {
    custom: true,
    totalSize: receivedArray.length,
  };



  return (

    <Formik
      enableReinitialize
      validateOnMount
      innerRef={formik}
      initialValues={DepositPstockModel}
      validationSchema={DepositPstockValidationSchema}
      onSubmit={(values) => {
        getDepositsData(values);
      }}>
      {({
        values,
        // resetForm,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        // setValues,
      }) => (

        <ContentStyled fluid>

          <TitleGroup>
            {'Depósitos'.toUpperCase()}
          </TitleGroup>


          <SectionMargin>

            <FilterContainerForm auto onSubmit={handleSubmit}>

              <Row>
                <Col>
                  <DropdownInput
                    disabled={loading}
                    name={'select_deposito'}
                    label={'Depósito'}
                    dropArray={listDeposit}
                    value={values.select_deposito}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>



                <Col>
                  <RowButtonView>

                    {/*
                    <IButton
                      disabled={loading || listLoading}
                      text={'Limpar'}
                      backgroundColor={Colors?.gray}
                      type={'reset'}
                      onClick={() => {
                        resetForm();

                        setValues(DepositPstockModel);
                      }}
                    />
                    */}


                    <IButton
                      disabled={!isValid || loading || listLoading}
                      text={'Filtrar'}
                      backgroundColor={Colors?.primary}
                      type={'submit'}
                    />

                  </RowButtonView>
                </Col>
              </Row>

            </FilterContainerForm>

          </SectionMargin>



          <ContainerView>

            {listLoading && (
              <LoadingsComponent
                type={'SCREEN'}
                color={Colors?.tertiary}
              />
            )}



            {!loading && !listLoading && showList && receivedArray.length === 0 && (
              <EmptyContent
                image={emptyObject.image}
                title={emptyObject.title}
                description={emptyObject.description}
              />
            )}



            {!loading && !listLoading && showList && receivedArray.length > 0 && (
              <PaginationProvider
                pagination={paginationFactory(options)}>
                {({
                  paginationProps,
                  paginationTableProps,
                }) => (
                  <TableCuston
                    noQuantity={receivedArray.length < 10}
                    data={receivedArray}
                    columns={columns}
                    paginationProps={paginationProps}
                    paginationTableProps={paginationTableProps}
                  />
                )}
              </PaginationProvider>
            )}

          </ContainerView>

        </ContentStyled>

      )}
    </Formik>

  );
};



export default TabDepositos;
