import React,
{
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
import TriaAcessoCRUDSMEModal from '../../_Modals/002TriaAcessoCRUDSMEModal';
import { Colors, Images } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import SMEAcessoModel from './SME-Tab_Acesso.model';
import SMEAcessoValidationSchema from './SME-Tab_Acesso.validation';


const Tab_TriaAcessoScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listGroup, setListGroup] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem acessos!',
    description: 'Não há nenhum acesso aqui',
  });

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesRemove, setValuesRemove] = useState({});



  function postFetchAccess(values) {
    setLoading(true);
    setShowList(false);


    const url = '/sm/manutencaotriagem/consulta/grupousuario/';
    const payload = values;


    ApiWS()
      .post(url, payload)
      .then((res) => {
        setListGroup(res.data);

        if (res.data.length === 0) {
          setEmptyObject({
            image: Images.empty,
            title: 'Sem acessos!',
            description: 'Não há nenhum acesso aqui',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        setEmptyObject({
          image: Images.other500,
          title: 'Erro',
          description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchGroup', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  const options = {
    custom: true,
    totalSize: listGroup.length,
  };

  const columns = [
    {
      dataField: 'CODIGO',
      text: 'Código',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'DESCRICAO',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'USUARIO',
      text: 'Usuário',
      sort: true,
    },

    {
      dataField: '',
      text: 'Remover',
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.REMOVE}
            onClick={() => {
              setShowModalRemove(true);
              setValuesRemove(row);
            }}
            icon={
              <IconTrash color={Colors?.black} size={16} />
            }
          />
        );
      },
    },
  ];


  return (
    <ContentStyled fluid>

      <>
        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={SMEAcessoValidationSchema}
          initialValues={SMEAcessoModel}
          onSubmit={(values) => {
            postFetchAccess(values);
          }}>
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            values,
          }) => (

            <>

              {loading && (
                <LoadingsComponent />
              )}



              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>

                  <Col xs={4}>
                    <TextInput
                      type={'text'}
                      name={'CODIGO'}
                      label={'Grupo'}
                      placeholder={'Deixe em branco para todos.'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={8}>
                    <TextInput
                      type={'text'}
                      name={'USUARIO'}
                      label={'Usuário'}
                      placeholder={'Deixe em branco para todos.'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                </Row>


                <RowButtonFiltersView>

                  <IButton
                    disabled={loading}
                    type={'button'}
                    backgroundColor={Colors?.grayDark}
                    text={'Novo'}
                    onClick={() => {
                      setShowModalNew(true);
                    }}
                  />


                  <IButton
                    disabled={loading}
                    type={'submit'}
                    backgroundColor={Colors?.primary}
                    text={'Filtrar'}
                  />

                </RowButtonFiltersView>

              </FilterContainerForm>



              {showModalNew && (
                <TriaAcessoCRUDSMEModal
                  show={showModalNew}
                  onHide={() => setShowModalNew(false)}
                  title={'Novo Acesso'}
                  type={'NEW'}
                  fetchNow={() => postFetchAccess(values)}
                />
              )}


              {showModalRemove && (
                <TriaAcessoCRUDSMEModal
                  show={showModalRemove}
                  onHide={() => setShowModalRemove(false)}
                  title={'Remover Acesso'}
                  type={'REMOVE'}
                  valuesRemove={valuesRemove}
                  fetchNow={() => postFetchAccess(values)}
                />
              )}

            </>

          )}

        </Formik>



        {!loading && showList && listGroup.length === 0 && (
          <EmptyContent
            image={emptyObject.image}
            title={emptyObject.title}
            description={emptyObject.description}
          />
        )}



        {!loading && showList && listGroup.length > 0 && (

          <PaginationProvider
            pagination={paginationFactory(options)}>
            {({
              paginationProps,
              paginationTableProps,
            }) => (
              <TableCuston
                noQuantity={listGroup.length < 10}
                data={listGroup}
                columns={columns}
                // baseProps={propsTkProvider.baseProps}
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
              />
            )}
          </PaginationProvider>

        )}

      </>

    </ContentStyled>
  );
};



export default Tab_TriaAcessoScreen;
