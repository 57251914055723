import React,
{
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
import TriaOriDesCRUDSMEModal from '../../_Modals/003TriaOriDesCRUDSMEModal';
import { Colors, Images } from '../../../../../../../common/constants';
import { TextTooltip } from '../../../../../../../common/constants/TextTooltips';
import ExcelConfigs from '../../../../../../../common/excel';
import { ContentStyled, FilterContainerForm, RowButtonFiltersView } from '../../../../../../../common/styles/styled.layout';
import ButtonTableAction from '../../../../../../../components/Buttons/ButtonTableAction';
import IButton from '../../../../../../../components/Buttons/IButton';
import EmptyContent from '../../../../../../../components/Empty';
import { IconTrash } from '../../../../../../../components/Icons';
import { IToast } from '../../../../../../../components/IToast/Toast';
import LoadingsComponent from '../../../../../../../components/Loadings';
import TableCuston from '../../../../../../../components/Table';
import TextInput from '../../../../../../../components/TextFields/Input';
import ApiWS from '../../../../../../../services/api.service';
import SMEOriDesModel from './SME-Tab_OriDes.model';
import SMEOriDesValidationSchema from './SME-Tab_OriDes.validation';


const Tab_TriaoriDesScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);

  const [listGroup, setListGroup] = useState([]);

  const [emptyObject, setEmptyObject] = useState({
    image: Images.empty,
    title: 'Sem relações!',
    description: 'Não há nenhum relações aqui',
  });

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const [valuesEdit] = useState({});
  const [valuesRemove, setValuesRemove] = useState({});



  function postFetchSrcDes(values) {
    setLoading(true);
    setShowList(false);


    const url = '/sm/manutencaotriagem/consulta/grupogrupo/';
    const payload = values;


    ApiWS()
      .post(url, payload)
      .then((res) => {
        setListGroup(res.data);

        if (res.data.length === 0) {
          setEmptyObject({
            image: Images.empty,
            title: 'Sem relações!',
            description: 'Não há nenhum relações aqui',
          });
        }
      })
      .catch((err) => {
        IToast({
          type: 'error',
          message: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        setEmptyObject({
          image: Images.other500,
          title: 'Erro',
          description: 'Ocorreu um erro! Tente novamente, caso o erro persista contate o suporte.',
        });

        console.error('postFetchGroup', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
        setShowList(true);
      });
  };


  const options = {
    custom: true,
    totalSize: listGroup.length,
  };

  const columns = [
    {
      dataField: 'ORIGEM',
      text: 'Origem',
      sort: true,
      formatter: (_cell, row) => {
        return `${row.ORIGEM} ${row.DESCRICAOORIGEM}`;
      },
    },
    {
      dataField: 'DESTINO',
      text: 'Destino',
      sort: true,
      formatter: (_cell, row) => {
        return `${row.DESTINO} ${row.DESCRICAODESTINO}`;
      },
    },

    {
      dataField: '',
      text: 'Remover',
      headerStyle: () => {
        return { width: '6%' };
      },
      formatter: (_cell, row) => {
        return (
          <ButtonTableAction
            tooltip={TextTooltip.REMOVE}
            onClick={() => {
              setShowModalRemove(true);
              setValuesRemove(row);
            }}
            icon={
              <IconTrash color={Colors?.black} size={16} />
            }
          />
        );
      },
    },
  ];


  return (
    <ContentStyled fluid>

      <>
        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={SMEOriDesValidationSchema}
          initialValues={SMEOriDesModel}
          onSubmit={(values) => {
            postFetchSrcDes(values);
          }}>
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            values,
          }) => (

            <>

              {loading && (
                <LoadingsComponent />
              )}



              <FilterContainerForm auto onSubmit={handleSubmit}>

                <Row>

                  <Col xs={6}>
                    <TextInput
                      type={'text'}
                      name={'ORIGEM'}
                      label={'Origem'}
                      placeholder={'Deixe em branco para todos.'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>


                  <Col xs={6}>
                    <TextInput
                      type={'text'}
                      name={'DESTINO'}
                      label={'Destino'}
                      placeholder={'Deixe em branco para todos.'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                </Row>


                <RowButtonFiltersView>

                  <IButton
                    disabled={loading}
                    type={'button'}
                    backgroundColor={Colors?.grayDark}
                    text={'Novo'}
                    onClick={() => {
                      setShowModalNew(true);
                    }}
                  />


                  <IButton
                    disabled={loading}
                    type={'submit'}
                    backgroundColor={Colors?.primary}
                    text={'Filtrar'}
                  />

                </RowButtonFiltersView>

              </FilterContainerForm>



              {showModalNew && (
                <TriaOriDesCRUDSMEModal
                  show={showModalNew}
                  onHide={() => setShowModalNew(false)}
                  title={'Nova Relação'}
                  type={'NEW'}
                  fetchNow={() => postFetchSrcDes(values)}
                />
              )}


              {showModalEdit && (
                <TriaOriDesCRUDSMEModal
                  show={showModalEdit}
                  onHide={() => setShowModalEdit(false)}
                  title={'Editar Relação'}
                  type={'EDIT'}
                  valuesEdit={valuesEdit}
                  fetchNow={() => postFetchSrcDes(values)}
                />
              )}


              {showModalRemove && (
                <TriaOriDesCRUDSMEModal
                  show={showModalRemove}
                  onHide={() => setShowModalRemove(false)}
                  title={'Remover Relação'}
                  type={'REMOVE'}
                  valuesRemove={valuesRemove}
                  fetchNow={() => postFetchSrcDes(values)}
                />
              )}


            </>

          )}

        </Formik>



        {!loading && showList && listGroup.length === 0 && (
          <EmptyContent
            image={emptyObject.image}
            title={emptyObject.title}
            description={emptyObject.description}
          />
        )}



        {!loading && showList && listGroup.length > 0 && (

          <PaginationProvider
            pagination={paginationFactory(options)}>
            {({
              paginationProps,
              paginationTableProps,
            }) => (
              <TableCuston
                noQuantity={listGroup.length < 10}
                data={listGroup}
                columns={columns}
                // baseProps={propsTkProvider.baseProps}
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
              />
            )}
          </PaginationProvider>

        )}

      </>

    </ContentStyled>
  );
};



export default Tab_TriaoriDesScreen;
